(function (angular) {
    angular.module("app").service("FakeLoginService", ["$rootScope", "restUrlPrivatePrefix", "$http", function ($rootScope, restUrlPrivatePrefix, $http) {
        return {
            logInAsUser: function (username) {
                return $http({
                    method: 'POST',
                    url: restUrlPrivatePrefix + 'fake-login/' + username
                });
            },
            logout: function () {
                return $http({
                    method: 'POST',
                    url: restUrlPrivatePrefix + 'fake-login/logout'
                });
            }
        };
    }]);
})(angular);