(function (angular) {
    angular.module("app").service("DateTimeService", ["$translate", "$filter", function ($translate, $filter) {
        var dayKeys = {
            0: "SUNDAY", 1: "MONDAY", 2: "TUESDAY", 3: "WEDNESDAY", 4: "THURSDAY", 5: "FRIDAY", 6: "SATURDAY"
        };

        return {
            createDateInPlainFormat: function (timestamp) {
                var currentDate = new Date();
                var date = new Date(timestamp);
                if (currentDate.getYear() === date.getYear()) {
                    return $filter('date')(timestamp, 'dd.MM, HH:mm');
                } else {
                    return $filter('date')(timestamp, 'dd.MM.yyyy');
                }
            },
            createDateInHipsterFormat: function (timestamp) {
                var currentTimestamp = Date.now();
                var timeBefore = Math.floor((currentTimestamp - timestamp) / 1000 / 60);
                if (timeBefore < 1) {
                    return $translate.instant('RIGHT_NOW');
                }
                if (timeBefore < 60) {
                    if (timeBefore === 1) {
                        return $translate.instant('ONE_MINUTE_AGO');
                    }
                    return $translate.instant('N_MINUTES_AGO', { n: timeBefore });
                }
                timeBefore = Math.floor(timeBefore / 60);
                if (timeBefore <= 12) {
                    if (timeBefore === 1) {
                        return $translate.instant('ONE_HOUR_AGO');
                    }
                    return $translate.instant('N_HOURS_AGO', { n: timeBefore });
                }
                var currentDate = new Date();
                var date = new Date(timestamp);
                if (currentDate.getYear() === date.getYear() && currentDate.getMonth() === date.getMonth() && currentDate.getDate() === date.getDate()) {
                    return $translate.instant('TODAY_AT') + ' ' + $filter('date')(timestamp, 'HH:mm');
                }
                if (timeBefore < 168 && date.getDay() !== currentDate.getDay()) {
                    return $translate.instant('ON_' + dayKeys[date.getDay()]) + ', ' + $filter('date')(timestamp, 'HH:mm');
                }
                if (currentDate.getYear() === date.getYear()) {
                    return $filter('date')(timestamp, 'dd.MM, HH:mm');
                } else {
                    return $filter('date')(timestamp, 'dd.MM.yyyy');
                }
            },
            isApril: function () {
                var today = new Date();
                var day = today.getDate();
                var month = today.getMonth() + 1;

                return day === 1 && month === 4;
            }
        };
    }]);
})(angular);