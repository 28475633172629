(function (angular) {
    angular.module('app').directive('hipsterDate', ["DateTimeService", "$interval", function (DateTimeService, $interval) {
        return {
            restrict: 'EA',
            replace: true,
            transclude: true,
            scope: { timestamp: '=hipsterDate' },
            template: '<time title="{{timestamp | date : \'dd.MM.yyyy, HH:mm\'}}">{{formattedTime}}</time>',
            link: function (scope, element, attrs) {
                function update() {
                    scope.formattedTime = DateTimeService.createDateInHipsterFormat(scope.timestamp);
                }

                update();
                var currentTimestamp = Date.now();
                var interval = 60000;
                if (currentTimestamp - scope.timestamp > 3600000) {
                    interval *= 60;
                }
                if (currentTimestamp - scope.timestamp > 3600000 * 24) {
                    return;
                }
                var timer = $interval(function () {
                    update();
                }, interval);
            }
        };
    }]);
})(angular);