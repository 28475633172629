(function (angular) {
    angular.module("app").controller("SystemActionsCtrl", ["$rootScope", "$scope", "SystemActionsService", "SecurityService", "DialogService", "AutocompleteService", function ($rootScope, $scope, SystemActionsService, SecurityService, DialogService, AutocompleteService) {
        $scope.recalculateUsers = function () {
            SystemActionsService.recalculateUsers();
        };
        $scope.recalculateExpressions = function () {
            SystemActionsService.recalculateExpressions(SecurityService.getSemester());
        };
        $scope.historizeUnhistorized = function () {
            SystemActionsService.historizeUnhistorized();
        };
        $scope.invalidateCaches = function () {
            SystemActionsService.invalidateCaches();
        };
        $scope.testPushNotification = function () {
            SystemActionsService.testPushNotification();
        };
        $scope.sendTestNotification = function () {
            var currentDate = new Date().toISOString();
            var body = {
                usernames: [SecurityService.getUsername()],
                title: "Test title",
                text: "Test Message " + currentDate
            };
            SystemActionsService.sendTestNotification(body);
        };
        $scope.hideCourse = function () {
            $scope.getCourses($scope.courseInput).then(function (res) {
                var courseCode = res.find(function (item) {
                    return item === $scope.courseInput;
                });
                if (!courseCode) {
                    DialogService.sendNegativeNotification("INPUT_INVALID_COURSE_CODE");
                } else {
                    SystemActionsService.hideCourse(courseCode, true).then(function (value) {
                        prepare();
                    });
                }
            });
        };
        $scope.unhideCourse = function (courseCode) {
            SystemActionsService.hideCourse(courseCode, false).then(function (value) {
                prepare();
            });
        };

        $scope.hiddenCourseCodes = [];
        $scope.hiddenCourses = [];
        function prepare() {
            return SystemActionsService.getHiddenCourses().then(function (response) {
                $scope.hiddenCourseCodes = [];
                $scope.hiddenCourses = response.data;
                $scope.hiddenCourses.forEach(function (t) {
                    $scope.hiddenCourseCodes.push(t.courseCode);
                });
                $scope.courseInput = '';
            });
        }
        prepare();

        $scope.getCourses = function (query) {
            return AutocompleteService.autocompleteCourses(query).then(function (res) {
                return res.data;
            });
        };
    }]);
})(angular);