(function (angular) {
    angular.module('app').directive('toggleSwitch', ["$window", "$document", function ($window, $document) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                model: '=', // boolean
                subtitle: '=',
                disabled: '=?'
            },
            templateUrl: 'classification/directive/toggleSwitch/toggleSwitch.tmpl.html',
            link: function (scope, element, attrs) {
                scope.swap = function () {
                    scope.model = !scope.model;
                };

                if (!scope.model) {
                    scope.model = false;
                }
            }
        };
    }]);
})(angular);