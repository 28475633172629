(function (angular) {
    angular.module("app").service("AddressBuild", ["SecurityService", function (SecurityService) {
        /*
        * Accepts arrays of parameters each as array [key, value]
        * Usage:
        *       url: restUrlPublicPrefix + 'courses/information' + AddressBuild
        *            .parameters([["semester",SecurityService.getSemester()],["lang", lang]])
        * */
        var parameters = function (parameters) {
            var result = '';
            var arrayLength = parameters.length;
            var count = 0;
            for (var i = 0; i < arrayLength; i++) {
                var key = parameters[i][0];
                var value = parameters[i][1];
                if (value !== null && value !== undefined) {
                    result += (count++ === 0 ? '?' : '&') + key + '=' + value;
                }
            }
            return result;
        };
        var addSemester = function (url, semesterCode) {
            if (!url) return url;
            var rawSemester = SecurityService.getRawSemester();
            if (!semesterCode && !rawSemester.isCurrent()) {
                url += "?semester=" + rawSemester.override;
            } else if (semesterCode && semesterCode !== rawSemester.current) {
                url += "?semester=" + semesterCode;
            }
            return url;
        };
        return {
            parameters: parameters,
            addSemester: addSemester
        };
    }]);

    angular.module('app').filter('addSemester', ["AddressBuild", function (AddressBuild) {
        function filter(url) {
            return AddressBuild.addSemester(url);
        }

        return filter;
    }]);
})(angular);