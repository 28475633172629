// BEWARE! that there are (still) two variables controlling menu visibility
//     - menu.visible controls visibility when the screen is wide
//     - menuVisible controls visibility when the screen is narrow
(function (angular) {
    angular.module("app").controller("HeaderCtrl", ["$rootScope", "$route", "$scope", "$location", "$timeout", "SecurityService", "MenuService", "TranslateService", "NotificationService", "$translate", "DialogService", "DateTimeService", "AutocompleteService", "WebsocketService", "KeepAliveService", "$window", "$interval", "FakeLoginService", "AddressBuild", function ($rootScope, $route, $scope, $location, $timeout, SecurityService, MenuService, TranslateService, NotificationService, $translate, DialogService, DateTimeService, AutocompleteService, WebsocketService, KeepAliveService, $window, $interval, FakeLoginService, AddressBuild) {

        $scope.menu = MenuService.getMenu();
        $scope.semesterDialogOutput = {};
        $rootScope.currentLanguage = TranslateService.getLanguage();
        $scope.shownNotifications = 5;
        $scope.bellRinging = false;
        $scope.getSettingsUrl = function () {
            return AddressBuild.addSemester('settings');
        };
        var bellRingingDuration = 4000;
        var bellRingingTimer;
        var windowHasFocus = $window.document.hasFocus();
        var changingTitleDuration = 1500;
        var changingTitleTimer;
        var titleIsChanging = false;
        var newNotificationsCounter = 0;
        var notificationTitle = '';

        $scope.NOT_CONNECTED = 0;
        $scope.CONNECTION_LOST = 1;
        $scope.CONNECTING = 2;
        $scope.CONNECTED = 3;
        $scope.connectionStatus = 0;
        $scope.$on('websocket-connected', function (event, args) {
            $scope.connectionStatus = $scope.CONNECTED;
        });
        $scope.$on('websocket-connecting', function (event, args) {
            $scope.connectionStatus = $scope.CONNECTING;
        });
        $scope.$on('websocket-disconnected', function (event, args) {
            $scope.connectionStatus = $scope.NOT_CONNECTED;
        });
        $scope.$on('websocket-lost', function (event, args) {
            $scope.connected = $scope.connectionStatus = $scope.CONNECTION_LOST;
            DialogService.sendNegativeNotification("CONNECTION_LOST", Math.pow(2, 31) - 1);
        });

        $scope.$on('session-almost-expired', function (event, args) {
            DialogService.sendInfoNotification("SESSION_EXPIRES_SOON", Math.pow(2, 31) - 1);
        }); //todo refresh with KeepAliveService.ping()
        $scope.$on('session-expired', function (event, args) {
            DialogService.sendNegativeNotification("SESSION_EXPIRED", Math.pow(2, 31) - 1);
        }); //todo button to reload page
        $scope.$on('session-refreshed', function (event, args) {}); //todo hide session notifications

        $scope.reconnect = function () {
            WebsocketService.reconnect();
        };
        $scope.disconnect = function () {
            WebsocketService.disconnect();
            $scope.connected = false;
        };

        $scope.semesterObj = SecurityService.getRawSemester();

        WebsocketService.handleScopeSubscription($scope, '/user/queue/notification', function (payload) {
            ringBell();
            notificationTitle = TranslateService.translate('NEW_NOTIFICATION');
            var notification = TranslateService.translateDynamic(payload.notificationTextDtos);
            if (notification && notification.title) notificationTitle = notification.title;
            startChangingTitle();
            $scope.$apply();
        });

        $scope.$on('user-info-loaded', handleInfoLoaded);

        function handleInfoLoaded(event, args) {
            $scope.notifications = NotificationService.getNewNotifications($scope.shownNotifications, 0, $rootScope.currentLanguage);
            MenuService.reloadMenu(SecurityService.getSemester());
        }
        handleInfoLoaded();

        var originalTitle = $window.document.title;

        $scope.getSynchronizationButtonClasses = function () {
            switch ($scope.connectionStatus) {
                case $scope.CONNECTED:
                    return "fa fa-link positive";
                case $scope.CONNECTING:
                    return "fa fa-refresh fa-spin";
                case $scope.NOT_CONNECTED:
                    return "fa fa-unlink neutral";
                case $scope.CONNECTION_LOST:
                    return "fa fa-unlink negative";
            }
        };

        $scope.synchronizationButtonClicked = function () {
            switch ($scope.connectionStatus) {
                case $scope.CONNECTED:
                    $scope.disconnect();
                    break;
                case $scope.CONNECTING:
                    break;
                case $scope.NOT_CONNECTED:
                    $scope.reconnect();
                    break;
                case $scope.CONNECTION_LOST:
                    $scope.reconnect();
                    break;
            }
        };

        $scope.getSynchronizationButtonTitle = function () {
            switch ($scope.connectionStatus) {
                case $scope.CONNECTED:
                    return "{{'CONNECTED_TOOLTIP' | translate}}";
                case $scope.CONNECTING:
                    return "{{'CONNECTING_TOOLTIP' | translate}}";
                case $scope.NOT_CONNECTED:
                    return "{{'NOT_CONNECTED_TOOLTIP' | translate}}";
                case $scope.CONNECTION_LOST:
                    return "{{'CONNECTION_LOST_TOOLTIP' | translate}}";
            }
        };

        function startChangingTitle() {
            if (!windowHasFocus) {
                newNotificationsCounter++;
                if (!titleIsChanging) {
                    titleIsChanging = true;
                    if (changingTitleTimer === undefined) {
                        changingTitleTimer = $interval(function () {
                            var hasNotificationTitle = $window.document.title === notificationTitle;
                            var countTitle = '(' + newNotificationsCounter + ') ' + originalTitle;
                            $window.document.title = hasNotificationTitle ? countTitle : notificationTitle;
                        }, changingTitleDuration);
                    }
                }
            }
        }

        function stopChangingTitle() {
            if (titleIsChanging) {
                titleIsChanging = false;
                newNotificationsCounter = 0;
                if (changingTitleTimer) {
                    $interval.cancel(changingTitleTimer);
                    changingTitleTimer = undefined;
                    $window.document.title = originalTitle;
                }
            }
        }

        angular.element($window).bind('focus', function () {
            windowHasFocus = true;
            stopChangingTitle();
        }).bind('blur', function () {
            windowHasFocus = false;
        });

        $scope.$on('$routeChangeStart', function () {
            $rootScope.forbidden = false;
        });

        $scope.$on('$routeChangeSuccess', function (event, next, prev) {
            MenuService.reloadMenuItems();
            $scope.hideNarrowMenu();
        });

        $scope.getSemesters = function (query) {
            return AutocompleteService.autocompleteSemesterCode(query).then(function (response) {
                return response.data;
            });
        };

        function ringBell() {
            if (bellRingingTimer) $timeout.cancel(bellRingingTimer);
            $scope.bellRinging = true;
            bellRingingTimer = $timeout(function () {
                $scope.bellRinging = false;
            }, bellRingingDuration);
        }

        $scope.setLang = function (languageIdentifier) {
            $rootScope.currentLanguage = languageIdentifier;
            $translate.use(languageIdentifier);
            MenuService.reloadMenuItems();
        };

        $scope.getFormattedDate = DateTimeService.createDateInHipsterFormat;

        $scope.readNotification = function (notification) {
            NotificationService.readNotification(notification);
        };

        $scope.toggleReadNotification = function (notification) {
            if (notification.read) {
                NotificationService.unreadNotification(notification);
            } else {
                $scope.readNotification(notification);
            }
        };

        $scope.fakeLoginLogout = function () {
            FakeLoginService.logout().then(function () {
                SecurityService.reloadSecurity(function () {
                    $location.path('admin/fake-login');
                    NotificationService.invalidateNotifications();
                });
            });
        };

        // this function exists because menu toggle takes a while to transition
        function broadcastWindowResizeCausedByMenu() {
            $timeout(function () {
                $rootScope.$broadcast('windowResized');
            }, 700, false);
        }
        function setWideMenuState(state) {
            $scope.menu.visible = state;
            broadcastWindowResizeCausedByMenu();
        }
        function setNarrowMenuState(state) {
            $scope.menuVisible = state;
            broadcastWindowResizeCausedByMenu();
        }
        $scope.toggleWideMenuState = function () {
            setWideMenuState(!$scope.menu.visible);
        };
        $scope.toggleNarrowMenuState = function () {
            setNarrowMenuState(!$scope.menuVisible);
        };
        $scope.hideNarrowMenu = function () {
            setNarrowMenuState(false);
        };

        $scope.width = $window.innerWidth;
        angular.element($window).bind('resize', function () {
            $scope.width = $window.innerWidth;

            if ($scope.width > 1050) {
                $scope.hideNarrowMenu();
            }
            // manual $digest required as the resize event is outside of angular
            //
            // at least one use is to hide the 'hide menu' overlay
            // after window size is stretched
            $scope.$digest();
            broadcastWindowResizeCausedByMenu();
        });

        $scope.windowScrolledDown = false;
        $scope.scrollPosition = $window.scrollY;
        angular.element($window).bind('scroll', function () {
            $scope.windowScrolledDown = $scope.scrollPosition <= $window.scrollY && $window.scrollY >= 100;
            $scope.scrollPosition = $window.scrollY;
            $scope.$apply();
        });

        $scope.getSalutation = function () {
            if (DateTimeService.isApril()) {
                if (SecurityService.getUsername() === "jirinmar") return "Spectabilis pane děkane";
                var semesterRoles = SecurityService.getUserInfo().roles[SecurityService.getSemester()];
                var teacherCourses = semesterRoles ? semesterRoles.roleCourses.teacher : undefined;
                if (teacherCourses !== undefined && teacherCourses.length > 0) return "Honorabilis";
            }
            if ($rootScope.currentLanguage === 'cs') return SecurityService.getCzechSalutation();else return SecurityService.getFirstName();
        };
    }]);
})(angular);