(function (angular) {
    angular.module("app").service("SessionTimer", ["$rootScope", "$window", "$timeout", function ($rootScope, $window, $timeout) {
        var warnSecondsAhead = 300; //todo property
        var warnTimer;
        var expirationTimer;

        var expirationTimeChangeHandler = function (newTime) {
            if (warnTimer) $timeout.cancel(warnTimer);
            if (expirationTimer) $timeout.cancel(expirationTimer);

            var expirationInterval = newTime - Date.now();
            var warnInterval = expirationInterval - warnSecondsAhead * 1000;

            warnTimer = $timeout(function () {
                $rootScope.$broadcast('session-almost-expired');
            }, warnInterval);
            expirationTimer = $timeout(function () {
                $rootScope.$broadcast('session-expired');
            }, expirationInterval);

            $rootScope.$broadcast('session-refreshed');
        };

        $window.addEventListener('storage', function (event) {
            if (event.key === 'sessionExpirationTime') expirationTimeChangeHandler(event.newValue);
        });

        return {
            updateTimeoutInterval: function (timeoutInterval) {
                var expirationTime = Date.now() + timeoutInterval * 1000;
                localStorage.sessionExpirationTime = expirationTime;
                expirationTimeChangeHandler(expirationTime);
            }
        };
    }]);
})(angular);