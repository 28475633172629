angular.module('app').service('KosGradingService', ["DialogService", "TranslateService", "SecurityService", function (DialogService, TranslateService, SecurityService) {
    var EXPORT_ASSESSMENT = 'ASSESSMENT';
    var EXPORT_MARK = 'MARK';
    var EXPORT_CLASSIFIED_ASSESSMENT = 'CLASSIFIED_ASSESSMENT';

    var validMarks = {
        'A': true,
        'B': true,
        'C': true,
        'D': true,
        'E': true,
        'F': true
    };

    /**
     * @column             identifier of column
     * @classification     student classification
     */
    function isUserAuthorOfEvaluation(classification, column) {
        var currentUsername = SecurityService.getUsername();
        var authorUsername = classification.evaluationAuthorMap[column];
        return currentUsername === authorUsername;
    }

    /**
     * @column             identifier of column which contains assessments
     * @classifications    student classifications
     * @filterByAuthor     boolean to trigger filtering by author's username
     *
     * @return             returns false if there are no data to export
     */
    function assessments(column, classifications, filterByAuthor) {
        var assessmentData = {};
        classifications.forEach(function (classification) {

            //Skips evaluations not given by the current user
            if (filterByAuthor && !isUserAuthorOfEvaluation(classification, column)) {
                return;
            }

            var personalNumber = classification.personalNumber;
            if (classification[column]) {
                assessmentData[personalNumber] = true;
            } else {
                assessmentData[personalNumber] = false;
            }
        });
        if (Object.keys(assessmentData).length === 0) {
            return false;
        }

        // jshint multistr: true
        var assessmentScript = "\
        var currentUrl = window.location.href;\n\
        if (!currentUrl.match(/writeCredit/i)) {\n\
            alert('This might not be the assessment entry page. If you are sure, remove the if containing alert in the script.');\n\
            return;\n\
        }\n\
        var CHECKBOX_INDEX = 0;\n\
        var ID_INDEX = 3;\n\
        var studentTable = document.getElementById('FilteredTable');\n\
        var tableBody = studentTable.childNodes[1]; // contains the rows with students\n\
        var tableRows = tableBody.rows;\n\
        var i = 0;\n\
        var currentRow = null;\n\
        var classList = null;\n\
        var studentId = null;\n\
        for (i = 0; i < tableRows.length; ++i) {\n\
            currentRow = tableRows[i];\n\
            classList = currentRow.classList;\n\
            if (!classList.contains('tableRow1') && !classList.contains('tableRow2')) {\n\
                continue;\n\
            }\n\
            studentId = + currentRow.children[ID_INDEX].innerText;\n\
            if (true === assessmentData[studentId]) {\n\
                currentRow.children[CHECKBOX_INDEX].firstElementChild.click();\n\
            }\n\
        }\n";

        var result = JSON.stringify(assessmentData);
        console.log("(function() {\nvar assessmentData = " + result + ';\n' + assessmentScript + "})();");

        return true;
    }

    /**
     * @column             identifier of column which contains assessments
     * @classifications    student classifications
     * @filterByAuthor     boolean to trigger filtering by author's username
     *
     * @return             returns false if there are no data to export
     */
    function marks(column, classifications, filterByAuthor) {
        var markData = {};
        classifications.forEach(function (classification) {

            //Skips evaluations not given by the current user
            if (filterByAuthor && !isUserAuthorOfEvaluation(classification, column)) {
                return;
            }

            var personalNumber = classification.personalNumber;
            if (validMarks[classification[column]]) {
                markData[personalNumber] = classification[column];
            } else {
                markData[personalNumber] = null;
            }
        });
        if (Object.keys(markData).length === 0) {
            return false;
        }

        // jshint multistr: true
        var markScript = "\
        var currentUrl = window.location.href\n\
        var summary = {\n\
            'A': 0, 'B': 0, 'C': 0, 'D': 0, 'E': 0, 'F': 0, 'X': 0\n\
        }\n\
        if (!currentUrl.match(/writeTerm/i)) {\n\
            alert('This might not be the grading by exam term page. If you are sure, remove the if containing alert in the script.');\n\
            return;\n\
        }\n\
        var CHECKBOX_INDEX = 0;\n\
        var ID_INDEX = 3;\n\
        var SELECT_INDEX = 11;\n\
        var studentTable = document.getElementById('FilteredTable');\n\
        var tableBody = studentTable.childNodes[1]; // contains the rows with students\n\
        var tableRows = tableBody.rows;\n\
        var i = 0, currentRow = null, classList = null, studentId = null, currentMark = null;\n\
        for (i = 0; i < tableRows.length; ++i) {\n\
            currentRow = tableRows[i];\n\
            classList = currentRow.classList;\n\
            if (!classList.contains('tableRow1') && !classList.contains('tableRow2')) {\n\
                continue;\n\
            }\n\
            studentId = + currentRow.children[ID_INDEX].innerText;\n\
            currentMark = markData[studentId];\n\
            if (currentMark === undefined) {\n\
                continue;\n\
            }\n\
            if (!currentMark.match(/^[A-F]$/i)) {\n\
                ++summary.X;\n\
                continue;\n\
            }\n\
            currentRow.children[SELECT_INDEX].firstElementChild.value = currentMark;\n\
            currentRow.children[CHECKBOX_INDEX].firstElementChild.click();\n\
            ++summary[currentMark];\n\
        }\n\
        console.log(summary);\n\
        summary.X && alert(\"Couldn't find a valid (A-F) mark for some students. They are unchecked in the table.\");\n";

        var result = JSON.stringify(markData);
        console.log("(function() {\nvar markData = " + result + ";\n" + markScript + "})();");

        return true;
    }

    /**
     * @column             identifier of column which contains assessments
     * @classifications    student classifications
     * @filterByAuthor     boolean to trigger filtering by author's username
     *
     * @return             returns false if there are no data to export
     */
    function classifiedAssessments(column, classifications, filterByAuthor) {
        var markData = {};
        classifications.forEach(function (classification) {

            //Skips evaluations not given by the current user
            if (filterByAuthor && !isUserAuthorOfEvaluation(classification, column)) {
                return;
            }

            var personalNumber = classification.personalNumber;
            if (validMarks[classification[column]]) {
                markData[personalNumber] = classification[column];
            } else {
                markData[personalNumber] = '';
            }
        });
        if (Object.keys(markData).length === 0) {
            return false;
        }

        // jshint multistr: true
        var classifiedAssessmentScript = "\
        var currentUrl = window.location.href\n\
        var summary = {\n\
            'A': 0, 'B': 0, 'C': 0, 'D': 0, 'E': 0, 'F': 0, 'X': 0\n\
        }\n\
        if (!currentUrl.match(/writeClasifyCredits/i)) {\n\
            alert('This might not be the classified credits page. If you are sure, remove the if containing alert in the script.');\n\
            return;\n\
        }\n\
        var CHECKBOX_INDEX = 0;\n\
        var ID_INDEX = 3;\n\
        var MARK_INDEX = 13;\n\
        var studentTable = document.getElementById('FilteredTable');\n\
        var tableBody = studentTable.childNodes[1]; // contains the rows with students\n\
        var tableRows = tableBody.rows;\n\
        var i = 0, currentRow = null, classList = null, studentId = null, currentMark = null;\n\
        for (i = 0; i < tableRows.length; ++i) {\n\
            currentRow = tableRows[i];\n\
            classList = currentRow.classList;\n\
            if (!classList.contains('tableRow1') && !classList.contains('tableRow2')) {\n\
                continue;\n\
            }\n\
            studentId = + currentRow.children[ID_INDEX].innerText;\n\
            currentMark = markData[studentId];\n\
            if (!currentMark.match(/^[A-F]$/i)) {\n\
                ++summary.X;\n\
                continue;\n\
            }\n\
            currentRow.children[MARK_INDEX].firstElementChild.value = currentMark;\n\
            currentRow.children[CHECKBOX_INDEX].firstElementChild.click();\n\
            ++summary[currentMark];\n\
        }\n\
        console.log(summary);\n\
        summary.X && alert(\"Couldn't find a valid (A-F) mark for some students. They are unchecked in the table.\");\n";

        var result = JSON.stringify(markData);
        console.log("(function() {\nvar markData = " + result + ";\n" + classifiedAssessmentScript + "})();");

        return true;
    }

    /**
     * @exportInfo          an object with keys [column, classificationType], where
     *                          column is the id of column with wanted data and
     *                          classificationType is EXPORT_*, see constants above
     * @classifications     array of classifications, see `$scope.data` in CourseClassificationCtrl.js
     *
     * prints the result to the browser console
     *
     * doesn't check input, preprocess elsewhere (e.g. in CourseClassificationCtrl.js), because
     *     it requires too much data to be passed
     */
    function generateScript(exportInfo, classifications) {
        var areThereDataToExport = false;
        switch (exportInfo.classificationType) {
            case EXPORT_ASSESSMENT:
                areThereDataToExport = assessments(exportInfo.column, classifications, exportInfo.filterByAuthor);
                break;
            case EXPORT_MARK:
                areThereDataToExport = marks(exportInfo.column, classifications, exportInfo.filterByAuthor);
                break;
            case EXPORT_CLASSIFIED_ASSESSMENT:
                areThereDataToExport = classifiedAssessments(exportInfo.column, classifications, exportInfo.filterByAuthor);
                break;
            default:
            // this shouldn't happen
        }
        if (areThereDataToExport) {
            DialogService.sendPositiveNotification(TranslateService.translate('RESULT_IS_IN_THE_CONSOLE'));
        } else {
            DialogService.sendInfoNotification(TranslateService.translate('THERE_ARE_NO_EVALUATIONS_TO_EXPORT'));
        }
    }

    return {
        generateScript: generateScript,
        EXPORT_ASSESSMENT: EXPORT_ASSESSMENT,
        EXPORT_MARK: EXPORT_MARK,
        EXPORT_CLASSIFIED_ASSESSMENT: EXPORT_CLASSIFIED_ASSESSMENT
    };
}]);