(function (angular) {
    angular.module('app').directive('rolloutList', function () {
        return {
            restrict: 'E',
            scope: {
                title: '=',
                data: '=',
                threshold: '=?'
            },
            templateUrl: 'classification/directive/rolloutList/rolloutList.tmpl.html',
            link: function (scope, elem, attrs) {
                if (!scope.threshold) scope.threshold = 3;
                scope.thresholdInit = scope.threshold;

                scope.toggleThreshold = function () {
                    if (scope.threshold == scope.thresholdInit) {
                        scope.threshold = undefined;
                    } else {
                        scope.threshold = scope.thresholdInit;
                    }
                };
            }
        };
    });
})(angular);