angular.module("app").controller("HomeCtrl", ["$rootScope", "$scope", "$translate", "courseStatistics", "CourseStatisticsService", "SecurityService", "roles", "myClassificationOverview", function ($rootScope, $scope, $translate, courseStatistics, CourseStatisticsService, SecurityService, roles, myClassificationOverview) {
    $scope.isStudent = roles.roleCourses.student && roles.roleCourses.student.length > 0;
    $scope.isStudentAndTeacher = $scope.isStudent && roles.roleCourses.teacher && roles.roleCourses.teacher.length > 0;
    $scope.myClassificationOverview = myClassificationOverview;
    $scope.semesterCode = SecurityService.getSemester();

    $scope.courseStatistics = {};
    courseStatistics.forEach(function (singleCourseStatistics) {
        CourseStatisticsService.processSingleCourseStats(singleCourseStatistics, $scope.courseStatistics);
    });

    $scope.refreshStatistics = function (courseCode) {
        return CourseStatisticsService.getSingleCourseStatistics(courseCode, $scope.semesterCode, true);
    };
}]);