(function (angular) {
    angular.module('app').directive('yesNo', ["$window", "$document", function ($window, $document) {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                data: '=' // { value : boolean }
            },
            templateUrl: 'classification/template/yes-no.tmpl.html',
            link: function (scope, element, attrs) {
                scope.swap = function (buttonValue) {
                    if (buttonValue !== scope.data.value) {
                        scope.data.value = !scope.data.value;
                    }
                };
                if (scope.data) {
                    if (!scope.data.value) {
                        scope.data.value = true;
                    }
                }
            }
        };
    }]);
})(angular);