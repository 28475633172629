(function (angular) {
    angular.module("app").controller("CoursesOverviewCtrl", ["$scope", "CoursesOverviewService", "courses", "restUrlPublicPrefix", "AddressBuild", "ComparatorService", function ($scope, CoursesOverviewService, courses, restUrlPublicPrefix, AddressBuild, ComparatorService) {
        $scope.courses = courses;
        $scope.legend = {
            visible: false,
            textHide: "HIDE_LEGEND",
            textShow: "SHOW_LEGEND"
        };

        function prepareUrls() {
            $scope.courses.forEach(function (course) {
                if (course.isDefined) {
                    course.url = AddressBuild.addSemester("courses/" + course.courseCode + "/classification");
                } else {
                    course.url = AddressBuild.addSemester("courses/" + course.courseCode + "/classification/definition");
                }
            });
        }

        prepareUrls();
        $scope.courses.sort(function (a, b) {
            return ComparatorService.courseCodeComparator(a.courseCode, b.courseCode);
        });
    }]);
})(angular);