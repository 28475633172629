(function (angular) {
    angular.module('app').directive('smartKeys', ["$window", "$rootScope", function ($window, $rootScope) {
        return {
            restrict: 'EAC',
            replace: false,
            link: function (scope, elem, attrs) {
                var keyCodes = {
                    left: 37,
                    up: 38,
                    right: 39,
                    down: 40,
                    enter: 13
                };

                var tableInputs = [];

                var availableKeyCodes = [];
                if (attrs.hasOwnProperty('skArrNavigation')) {
                    availableKeyCodes = availableKeyCodes.concat([keyCodes.left, keyCodes.right, keyCodes.up, keyCodes.down]);
                }

                elem.bind('keydown', function (event) {
                    if (!event.ctrlKey && availableKeyCodes.indexOf(event.keyCode) !== -1) {
                        switch (event.keyCode) {
                            // case keyCodes.left:
                            //     focusNextInput(event.target, -1, 0);
                            //     break;
                            // case keyCodes.right:
                            //     focusNextInput(event.target, 1, 0);
                            //     break;
                            case keyCodes.up:
                                event.preventDefault();
                                focusNextInput(event.target, 0, -1);
                                break;
                            case keyCodes.down:
                                event.preventDefault();
                                focusNextInput(event.target, 0, 1);
                                break;
                        }
                    }
                    // enables shift enter to add a new line in text areas
                    if (event.shiftKey && event.keyCode === keyCodes.enter) {
                        if (event.target.nodeName === 'TEXTAREA') {
                            return;
                        }
                    }
                    if (!event.ctrlKey && event.keyCode === keyCodes.enter) {
                        event.preventDefault();
                    }
                    if (event.ctrlKey && event.keyCode === keyCodes.enter) {
                        event.preventDefault();
                        switch (event.keyCode) {
                            case keyCodes.enter:
                                var elementById = document.getElementById('skSave');
                                if (elementById) {
                                    setTimeout(function () {
                                        angular.element(elementById).triggerHandler('submit');
                                    }, 200); // todo this would trigger the dialog as well ... unhack it
                                }
                                break;
                        }
                    }
                });

                function getClosest(element, tagName) {
                    if (element.parentNode) {
                        if (element.parentNode.localName == tagName) {
                            return element.parentElement;
                        } else {
                            return getClosest(element.parentNode, tagName);
                        }
                    }
                    return null;
                }

                function focusNextInput(input, x, y) {
                    var skInputs = elem[0].getElementsByClassName('sk-input');
                    var parentTableRow = getClosest(input, 'tr');
                    var rowInputs = angular.element(parentTableRow)[0].getElementsByClassName('sk-input');
                    var rowInputsLength = rowInputs.length;
                    var skInputsArray = Object.keys(skInputs).map(function (key) {
                        return skInputs[key];
                    });
                    var i = skInputsArray.indexOf(input);
                    var newInputIndex = i + x + y * rowInputsLength;
                    if (newInputIndex < 0) {
                        newInputIndex += skInputs.length;
                    } else if (newInputIndex >= skInputs.length) {
                        newInputIndex -= skInputs.length;
                    }
                    skInputsArray[newInputIndex].focus();
                    skInputsArray[newInputIndex].select();
                }
            }
        };
    }]);
})(angular);