(function (angular) {
    angular.module('app').directive('notificationDialog', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                show: '='
            },
            templateUrl: 'classification/directive/notificationDialog/notificationDialog.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.title = attrs.title;

                if (!scope.preventClose) {
                    document.addEventListener('keydown', function (event) {
                        if (event.keyCode === 27) {
                            scope.shown = false;
                            scope.$apply();
                        }
                    });
                }

                scope.close = function () {
                    if (scope.preventClose) {
                        return;
                    }
                    scope.show = false;
                };
            }
        };
    });
})(angular);