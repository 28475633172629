(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/settings/classification-editors', {
            templateUrl: 'classification/pages/advancedTools/ClassificationEditorsSettings/ClassificationEditorsSettings.tmpl.html',
            controller: 'ClassificationEditorsSettingsCtrl',
            resolve: {
                roleCourses: ["SecurityService", function (SecurityService) {
                    return SecurityService.getRoleCourses(['guarantor', 'headOfDepartment', "departmentScheduler"]).then(function (response) {
                        return response;
                    });
                }]
            }
        });
    }]);
})(angular);