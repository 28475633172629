(function (angular) {
    angular.module("app").controller("FakeLoginCtrl", ["$rootScope", "$location", "$scope", "FakeLoginService", "AutocompleteService", "DialogService", "SecurityService", "NotificationService", function ($rootScope, $location, $scope, FakeLoginService, AutocompleteService, DialogService, SecurityService, NotificationService) {

        var lock = false;

        $scope.login = function () {
            if (lock) return;
            lock = true;
            var username = $scope.userInput.hasOwnProperty("username") ? $scope.userInput.username : $scope.userInput;
            if (username !== null) {
                FakeLoginService.logInAsUser(username).then(function () {
                    SecurityService.reloadSecurity(function () {
                        $location.path('home');
                        NotificationService.invalidateNotifications();
                    });
                }, function () {
                    DialogService.sendNegativeNotification("INPUT_INVALID_USER");
                });
            }
        };

        $scope.getUsers = function (query) {
            return AutocompleteService.autocompleteUsers(query).then(function (response) {
                return response.data;
            });
        };
    }]);
})(angular);