/* Flexible directive for displaying student's final score
 * If convertToMark is set to true, req is 50, max is 100 then score is convertable to A,B,..,F
 * Displays two formats:
 *  1) "SCORE /MAX"   .. eg. "55 /100"
 *  2) "MARK (SCORE)" .. eg. "A (97)"
 * */

(function (angular) {
    angular.module('app').directive('score', ["MarkService", "EvolutionTeamService", function (MarkService, EvolutionTeamService) {
        return {
            restrict: 'A',
            replace: false,
            transclude: true,
            scope: {
                score: '=',
                max: '=?',
                req: '=?', //Minimum required value
                min: '=?', //Minimum value
                disableColouring: '=?',
                fontSize: '=?', //default = inherit
                margin: '=?',
                convertToMark: '=?', //Converts score to A,B,C,D,E,F
                hideSecondary: '=?', //Always hide secondaryValue
                hideSecondaryIfUnconvertable: '=?' //Hide secondaryValue only if score can't be converted. Useful for hiding of unnecessary max value in smart-table
            },
            template: '<span class="score-wrapper" ng-style="{\'font-size\': \'{{fontSize}}px\', margin: \'{{margin}}\'}">' + '<span class="score-primary-value">{{primaryValue}}</span>' + '<span class="score-secondary-value-max" ng-if="max && !hideSecondary && !convertToMark">/{{secondaryValue}}</span>' + '<span class="score-secondary-value-score" ng-if="max && !hideSecondary && convertToMark && !(!convertable && hideSecondaryIfUnconvertable)" >' + '<span>({{secondaryValue}})</span>' + '</span>' + '</span>',
            link: function (scope, element, attrs) {
                var scoreObject = angular.element(element[0].getElementsByClassName('score-primary-value'))[0];
                var scoreCeil;
                scope.$watch('score', function () {
                    if (EvolutionTeamService.isNumber(scope.score)) {
                        scoreCeil = Math.ceil(scope.score * 1000) / 1000;
                        if (scope.convertToMark && scope.max === 100 && scope.req === 50) {
                            scope.convertable = true;
                            if (scoreCeil >= 90) {
                                scope.primaryValue = "A";
                            } else if (scoreCeil >= 80) {
                                scope.primaryValue = "B";
                            } else if (scoreCeil >= 70) {
                                scope.primaryValue = "C";
                            } else if (scoreCeil >= 60) {
                                scope.primaryValue = "D";
                            } else if (scoreCeil >= 50) {
                                scope.primaryValue = "E";
                            } else {
                                scope.primaryValue = "F";
                            }
                            scope.secondaryValue = scoreCeil;
                        } else {
                            scope.convertable = false;
                            scope.primaryValue = scoreCeil;
                            scope.secondaryValue = scope.max;
                        }
                    } else {
                        //STRING
                        scope.primaryValue = scope.score;
                    }
                    if (!scope.disableColouring) {
                        scoreObject.style.color = MarkService.hsla(scope.score, scope.min, scope.req, scope.max);
                    }
                });
            }
        };
    }]);
})(angular);