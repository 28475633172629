(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/__template__', {
            templateUrl: 'classification/pages/__path__/__Template__/__Template__.tmpl.html',
            controller: '__Template__Ctrl',
            resolve: {
                // __template__Forms: function (__Template__Service, BakerService) {
                //     return BakerService.bakeLangFunction(__Template__Service.getSomething);
                // }
                // what should be resolved before passing to controller
            }
        });
    }]);
})(angular);