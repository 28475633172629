(function (angular) {
    angular.module("app").service("AutocompleteService", ["restUrlPrivatePrefix", "$http", "AddressBuild", function (restUrlPrivatePrefix, $http, AddressBuild) {
        return {
            autocompleteUsers: function (query) {
                return $http({
                    method: 'GET',
                    url: restUrlPrivatePrefix + 'autocomplete/persons' + AddressBuild.parameters([['query', query]])
                });
            },
            autocompleteStudentsInCourse: function (query, courseCode, semesterCode) {
                return $http({
                    method: 'GET',
                    url: restUrlPrivatePrefix + 'autocomplete/courses/' + courseCode + '/students' + AddressBuild.parameters([['semester', semesterCode], ['query', query]])
                });
            },
            autocompleteSemesterCode: function (query) {
                return $http({
                    method: 'GET',
                    url: restUrlPrivatePrefix + 'autocomplete/semesters' + AddressBuild.parameters([['query', query]])
                });
            },
            autocompleteCourses: function (query) {
                return $http({
                    method: 'GET',
                    url: restUrlPrivatePrefix + 'autocomplete/courses' + AddressBuild.parameters([['query', query]])
                });
            }
        };
    }]);
})(angular);