(function (angular) {
    angular.module("app").service("ClassificationService", ["restUrlPublicPrefix", "$http", "AddressBuild", "EvolutionTeamService", function (restUrlPublicPrefix, $http, AddressBuild, EvolutionTeamService) {
        var recommendedValuesOfClassificationType = {
            //============= SEMESTER BASIC =============
            // ACTIVITY: { },
            // HOMEWORK: { },
            SEMESTRAL_TEST: {
                hidden: false
            },
            // SEMESTRAL_WORK: { },
            CLASSIFIED_ASSESMENT: {
                hidden: false
            },
            ASSESSMENT: {
                valueType: "BOOLEAN",
                hidden: false
            },
            //============= SEMESTR EXTENDED =============
            // QUIZ: { },
            // CORRECTION_TEST: { },
            // TUTORIAL_TASK: { },
            // BONUS_POINTS: { },
            // TUTORIAL_ATTENDANCE: { },
            // TUTORIAL_ABSENCE: { },
            // CONTEST_HOMEWORK: { },
            // LECTURE_TASK: { },
            // CODE_REVIEW: { },
            //============= EXAMS =============
            PRE_EXAM_TEST: {
                hidden: false
            },
            EXAM_TEST: {
                hidden: false
            },
            ORAL_EXAM: {
                hidden: false
            },
            POINTS_TOTAL: {
                valueType: "NUMBER",
                hidden: false
            },
            FINAL_SCORE: {
                hidden: false
                //// OTHER
                // OTHER: { }
            } };

        return {
            saveClassificationColumnList: function (columns, courseCode) {
                return $http({
                    method: 'POST',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/definitions/',
                    data: columns
                });
            },
            deleteClassificationColumn: function (column, preserveChildren) {
                return $http({
                    method: 'DELETE',
                    url: restUrlPublicPrefix + 'courses/' + column.courseCode + '/definitions/' + column.identifier + AddressBuild.parameters([['semester', column.semesterCode], ['preserve-children', preserveChildren]])
                });
            },
            saveClassificationColumnOrder: function (columnOrder, semesterCode, courseCode) {
                return $http({
                    method: 'PUT',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/definitions/order' + AddressBuild.parameters([['semester', semesterCode]]),
                    data: columnOrder
                });
            },
            loadClassificationColumns: function (semesterCode, courseCode, lang) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/definitions' + AddressBuild.parameters([['semester', semesterCode], ['lang', lang]])
                });
            },

            getRecommendedValuesOfClassificationType: function (nameKey) {
                return recommendedValuesOfClassificationType[nameKey];
            },

            getClassificationTypes: function () {
                return {
                    //============= SEMESTER BASIC =============
                    ACTIVITY: {
                        nameKey: "ACTIVITY",
                        identifier: "activity",
                        styleClass: "activity",
                        type: "SEMESTER",
                        group: "basic",
                        cs: "Aktivita",
                        en: "Activity"
                    },
                    HOMEWORK: {
                        nameKey: "HOMEWORK",
                        identifier: "homework",
                        styleClass: "homework",
                        type: "SEMESTER",
                        group: "basic",
                        cs: "Domácí úkol",
                        en: "Homework"
                    },
                    SEMESTRAL_TEST: {
                        nameKey: "SEMESTRAL_TEST",
                        identifier: "semestral_test",
                        styleClass: "semestral-test",
                        type: "SEMESTER",
                        group: "basic",
                        cs: "Semestrální test",
                        en: "Semestral test"
                    },
                    SEMESTRAL_WORK: {
                        nameKey: "SEMESTRAL_WORK",
                        identifier: "semestral_work",
                        styleClass: "semestral-work",
                        type: "SEMESTER",
                        group: "basic",
                        cs: "Semestrální práce",
                        en: "Semestral work"
                    },
                    ASSESSMENT: {
                        nameKey: "ASSESSMENT",
                        identifier: "assessment",
                        styleClass: "assessment",
                        type: "SEMESTER",
                        group: "basic",
                        cs: "Zápočet",
                        en: "Assessment"
                    },
                    OTHER_SEMESTER: {
                        nameKey: "OTHER_SEMESTER",
                        identifier: "other_semester",
                        styleClass: "other-semester",
                        type: "SEMESTER",
                        group: "basic",
                        cs: "Ostatní",
                        en: "Other"
                    },
                    //============= SEMESTR EXTENDED =============
                    QUIZ: {
                        nameKey: "QUIZ",
                        identifier: "quiz",
                        styleClass: "quiz",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Kvíz",
                        en: "Quiz"
                    },
                    CORRECTION_TEST: {
                        nameKey: "CORRECTION_TEST",
                        identifier: "correction_test",
                        styleClass: "correction-test",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Opravný test",
                        en: "Correction test"
                    },
                    TUTORIAL_TASK: {
                        nameKey: "TUTORIAL_TASK",
                        identifier: "tutorial_task",
                        styleClass: "tutorial-task",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Práce na cvičení",
                        en: "Tutorial task"
                    },
                    BONUS_POINTS: {
                        nameKey: "BONUS_POINTS",
                        identifier: "bonus_points",
                        styleClass: "bonus-points",
                        type: "ALL",
                        group: "extended",
                        cs: "Bonusové body",
                        en: "Bonus points"
                    },
                    TUTORIAL_ATTENDANCE: {
                        nameKey: "TUTORIAL_ATTENDANCE",
                        identifier: "tutorial_attendance",
                        styleClass: "tutorial-attendance",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Docházka na cvičení",
                        en: "Tutorial attendance"
                    },
                    TUTORIAL_ABSENCE: {
                        nameKey: "TUTORIAL_ABSENCE",
                        identifier: "tutorial_absence",
                        styleClass: "tutorial-absence",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Absence na cvičení",
                        en: "Tutorial absence"
                    },
                    CONTEST_HOMEWORK: {
                        nameKey: "CONTEST_HOMEWORK",
                        identifier: "contest_homework",
                        styleClass: "contest-homework",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Domácí úloha soutěžní",
                        en: "Contest homework"
                    },
                    LECTURE_TASK: {
                        nameKey: "LECTURE_TASK",
                        identifier: "lecture_task",
                        styleClass: "lecture_task",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Úloha na přednášce",
                        en: "Lecture task"
                    },
                    CODE_REVIEW: {
                        nameKey: "CODE_REVIEW",
                        identifier: "code_review",
                        styleClass: "code-review",
                        type: "SEMESTER",
                        group: "extended",
                        cs: "Code review",
                        en: "Code review"
                    },
                    //============= EXAMS =============
                    PRE_EXAM_TEST: {
                        nameKey: "PRE_EXAM_TEST",
                        identifier: "pre_exam_test",
                        styleClass: "exam-test",
                        type: "EXAMS",
                        group: "exams",
                        cs: "Rozstřel",
                        en: "Pre-exam test"
                    },
                    EXAM_TEST: {
                        nameKey: "EXAM_TEST",
                        identifier: "exam_test",
                        styleClass: "exam-test",
                        type: "EXAMS",
                        group: "exams",
                        cs: "Zkouškový test",
                        en: "Exam test"
                    },
                    ORAL_EXAM: {
                        nameKey: "ORAL_EXAM",
                        identifier: "oral_exam",
                        styleClass: "exam-oral",
                        type: "EXAMS",
                        group: "exams",
                        cs: "Ústní zkouška",
                        en: "Oral exam"
                    },
                    OTHER_EXAMS: {
                        nameKey: "OTHER_EXAMS",
                        identifier: "other_exams",
                        styleClass: "other-exams",
                        type: "EXAM",
                        group: "exams",
                        cs: "Ostatní",
                        en: "Other"
                    },
                    //OTHER
                    POINTS_TOTAL: {
                        nameKey: "POINTS_TOTAL",
                        identifier: "points_total",
                        styleClass: "points-total",
                        type: "ALL",
                        cs: "Celkový počet bodů",
                        en: "Total number of points"
                    },
                    FINAL_SCORE: {
                        nameKey: "FINAL_SCORE",
                        identifier: "mark",
                        styleClass: "final-score",
                        type: "ALL",
                        cs: "Známka",
                        en: "Mark"
                    },
                    OTHER: {
                        nameKey: "OTHER",
                        identifier: "other",
                        styleClass: "other",
                        type: "ALL",
                        cs: "Ostatní",
                        en: "Other"
                    }
                };
            },
            getInputFieldType: function (definition) {
                var inputType = null;
                switch (definition.valueType) {
                    case 'NUMBER':
                        inputType = 'number';
                        break;
                    case 'STRING':
                        var aw = definition.allowedStringValues;
                        inputType = aw === undefined || aw.length === 0 ? 'text' : 'select';
                        break;
                    case 'BOOLEAN':
                        inputType = 'checkbox';
                        break;
                }
                return inputType;
            },

            getStudentGroups: function (semesterCode, courseCode, lang, teacherUsername) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'course/' + courseCode + '/student-groups' + AddressBuild.parameters([['lang', lang], ['semester', semesterCode], ['teacherUsername', teacherUsername]])
                });
            },

            getStudentClassifications: function (semesterCode, courseCode, groupCode, query) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/group/' + groupCode + '/student-classifications' + AddressBuild.parameters([['semester', semesterCode], ['query', !query ? null : encodeURIComponent(query)]])
                });
            },

            getStudentClassification: function (semesterCode, courseCode, groupCode, classificationIdentifier) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/group/' + groupCode + '/student-classifications/' + classificationIdentifier + AddressBuild.parameters([['semester', semesterCode]])
                });
            },
            saveStudentClassifications: function (courseCode, semesterCode, studentClassifications, notify) {
                var parameters = [];
                if (semesterCode) {
                    parameters.push(['semester', semesterCode]);
                }
                if (notify === false) {
                    parameters.push(['notify', false]);
                }
                return $http({
                    method: 'PUT',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/student-classifications/' + AddressBuild.parameters(parameters),
                    data: studentClassifications
                });
            },
            importDefinitions: function (sourceCourseCode, sourceSemesterCode, targetCourseCode, targetSemesterCode, removeExisting) {
                return $http({
                    method: 'PUT',
                    url: restUrlPublicPrefix + 'courses/' + sourceCourseCode + '/definitions/clones/' + targetCourseCode + '/' + AddressBuild.parameters([['source-semester', sourceSemesterCode], ['target-semester', targetSemesterCode], ['remove-existing', removeExisting]])
                });
            },
            buildClassificationHierarchy: function (classifications) {
                var classificationHierarchy = { null: [] };
                var classification, i;
                for (i = 0; i < classifications.length; i++) {
                    classification = classifications[i];
                    classificationHierarchy[classification.id] = [];
                }
                for (i = 0; i < classifications.length; i++) {
                    classification = classifications[i];
                    if (classificationHierarchy[classification.parentId] != null) {
                        classificationHierarchy[classification.parentId].push(classification);
                    }
                }
                return classificationHierarchy;
            },
            buildPostfixClassificationDefinitions: function (classifications) {
                var classificationHierarchy = this.buildClassificationHierarchy(classifications);
                var result = [];
                var convertToPostfix = function (id) {
                    var children = classificationHierarchy[id];
                    for (var i = 0; i < children.length; i++) {
                        var child = children[i];
                        convertToPostfix(child.id);
                        result.push(child);
                    }
                };
                convertToPostfix(null);
                return result;
            },
            fileExportColumns: function () {
                return ["identifier", "parentIdentifier", "evaluationType", "minimumValue", "minimumRequiredValue", "maximumValue", "allowedStringValues", "classificationType", "valueType", "cs_name", // extracted from classificationTextDto
                "en_name", // extracted from classificationTextDto
                "hidden", "expression", "aggregatedIdentifier", "aggregationFunction", "aggregationScope"];
            },
            fileImportNotRequiredColumns: function () {
                return ["parentIdentifier", "minimumValue", "minimumRequiredValue", "maximumValue", "allowedStringValues", "expression", "aggregatedIdentifier", "aggregationFunction", "aggregationScope"];
            },
            // turns an object
            // ```
            // {
            //     languageTag1: text1,
            //     languageTag2: text2
            // }
            // ```
            // into
            // ```
            // [
            // {
            //     name: text1,
            //     identifier: languageTag1
            // },
            // {
            //     name: text2,
            //     identifier: languageTag2
            // }
            // ]
            // ```
            createLanguages: function (obj) {
                var result = [];
                Object.keys(obj).forEach(function (key) {
                    result.push({ identifier: key, name: obj[key] });
                });
                return result;
            },
            // TODO: some sort of validation or turn this into object
            createDefinitionDto: function (languageObject, evaluationType, hidden, valueType, identifier, classificationType, expression, index, courseCode, semesterCode, minimumValue, minimumRequiredValue, maximumValue, allowedStringValues) {
                var dto = {};
                dto.classificationTextDtos = this.createLanguages(languageObject);
                dto.evaluationType = evaluationType;
                dto.hidden = hidden;
                dto.valueType = valueType;
                dto.identifier = identifier;
                dto.classificationType = classificationType;
                dto.expression = expression;
                dto.index = index;
                dto.courseCode = courseCode;
                dto.semesterCode = semesterCode;
                dto.minimumValue = minimumValue;
                dto.minimumRequiredValue = minimumRequiredValue;
                dto.maximumValue = maximumValue;
                if (allowedStringValues) dto.allowedStringValues = allowedStringValues;
                return dto;
            },
            validationException: function (msg, offender, row) {
                return { msg: msg, offender: offender, row: row };
            },
            validateImportedJson: function (importedClassification) {
                if (importedClassification.length < 1) return true;

                // check if all needed columns are present
                var importedKeys = Object.keys(importedClassification[0]);
                var expectedKeys = this.fileExportColumns();
                var notRequiredKeys = this.fileImportNotRequiredColumns();
                var i, j, currentDefinition;
                for (i = 0; i < expectedKeys.length; ++i) {
                    if (!notRequiredKeys.includes(expectedKeys[i]) && !importedKeys.includes(expectedKeys[i])) {
                        throw this.validationException("MISSING_COLUMN", expectedKeys[i]);
                    }
                }

                // check for emptiness of entries
                var mandatoryNonEmpty = ["identifier", "evaluationType", "classificationType", "valueType", "hidden"];
                for (i = 0; i < importedClassification.length; ++i) {
                    currentDefinition = importedClassification[i];
                    for (j = 0; j < mandatoryNonEmpty.length; ++j) {
                        var currentKey = mandatoryNonEmpty[j];
                        if (currentDefinition[currentKey] === "") {
                            throw this.validationException("EMPTY_COLUMN", currentKey, i);
                        }
                    }
                }

                // at least one name has to be present
                for (i = 0; i < importedClassification.length; ++i) {
                    currentDefinition = importedClassification[i];
                    if (!currentDefinition.cs_name && !currentDefinition.en_name) {
                        throw this.validationException("NO_NAMES", null, i);
                    }
                }

                // check minimum value <= minimumRequiredValue <= maximum value
                var extremalValuesKeys = ["minimumValue", "minimumRequiredValue", "maximumValue"];
                for (i = 0; i < importedClassification.length; ++i) {
                    currentDefinition = importedClassification[i];

                    var arr = [];
                    for (var key in extremalValuesKeys) {
                        if (EvolutionTeamService.isNumber(currentDefinition[key])) {
                            arr.push(currentDefinition[key]);
                        }
                    }

                    var ok = true;
                    for (j = 1; j < arr.length; ++j) {
                        if (arr[j - 1] > arr[j]) {
                            ok = false;
                            break;
                        }
                    }
                    if (!ok) {
                        throw this.validationException("BAD_ORDER", currentDefinition.identifier, i);
                    }
                }

                // set appropriate fields for each case of evaluationType, because Stepan can't into inheritance
                for (i = 0; i < importedClassification.length; ++i) {
                    currentDefinition = importedClassification[i];
                    var classificationType = currentDefinition.evaluationType;
                    if (classificationType !== 'MANUAL' && currentDefinition.valueType !== 'STRING' || currentDefinition.allowedStringValues === "") {
                        delete currentDefinition.allowedStringValues;
                    }
                    switch (classificationType) {
                        case "AGGREGATION":
                            currentDefinition.expression = null;
                            break;
                        case "EXPRESSION":
                            currentDefinition.aggregatedIdentifier = null;
                            currentDefinition.aggregationFunction = null;
                            currentDefinition.aggregationScope = null;
                            break;
                        case "MANUAL":
                            currentDefinition.expression = null;
                            currentDefinition.aggregatedIdentifier = null;
                            currentDefinition.aggregationFunction = null;
                            currentDefinition.aggregationScope = null;
                            break;
                        default:
                            throw this.validationException("UNKNOWN_EVALUATION_TYPE", currentDefinition.identifier, i);
                    }
                }

                //allowed values from comma delimited string to array
                for (i = 0; i < importedClassification.length; ++i) {
                    currentDefinition = importedClassification[i];
                    if (currentDefinition.allowedStringValues) {
                        currentDefinition.allowedStringValues = currentDefinition.allowedStringValues.split(',');
                    }
                }
            }
        };
    }]);
})(angular);