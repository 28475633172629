(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/external-applications', {
            templateUrl: 'classification/pages/advancedTools/ExternalApplications/ExternalApplications.tmpl.html',
            controller: 'ExternalApplicationsCtrl',
            resolve: {
                otherApplications: ["ExternalApplicationsService", function (ExternalApplicationsService) {
                    return ExternalApplicationsService.getOtherExternalApplications().then(function (response) {
                        return response.data;
                    });
                }],
                myApplications: ["ExternalApplicationsService", function (ExternalApplicationsService) {
                    return ExternalApplicationsService.getUsersExternalApplications().then(function (response) {
                        return response.data;
                    });
                }]
            }
        });
    }]);
})(angular);