(function (angular) {
    angular.module('app').directive('confirmEvaluationDialog', ["$rootScope", "TranslateService", function ($rootScope, TranslateService) {
        return {
            restrict: 'E',
            scope: {
                data: '=', // { show : boolean, value : boolean }
                action: '=',
                notificationText: '=',
                form: '=?'
            },
            templateUrl: 'classification/template/confirm-evaluation-dialog.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.bodyText = TranslateService.translate($rootScope.isApril ? 'APRIL_WASTE_TIME' : 'SAVING_CONFIRM_BODY');
                scope.actionFunc = function () {
                    scope.action(scope.form);
                };
            }
        };
    }]);
})(angular);