(function (angular) {
    sessionInterceptor.$inject = ["$q", "SessionTimer"];
    angular.module('app').factory('sessionInterceptor', sessionInterceptor);

    angular.module('app').config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('sessionInterceptor');
    }]);

    function sessionInterceptor($q, SessionTimer) {

        var handleResponse = function (response) {
            var timeoutInterval = response.headers("seconds-to-session-expiration");
            if (timeoutInterval) {
                SessionTimer.updateTimeoutInterval(timeoutInterval);
            }
        };

        return {
            // request: function (request) {}
            // requestError: function (request) {}
            response: function (response) {
                handleResponse(response);
                return response;
            },
            responseError: function (response) {
                handleResponse(response);
                return $q.reject(response);
            }
        };
    }
})(angular);