(function (angular) {
    angular.module("app").controller("ClassificationEditorsSettingsCtrl", ["$scope", "ClassificationEditorsSettingsService", "AutocompleteService", "roleCourses", "DialogService", "ComparatorService", function ($scope, ClassificationEditorsSettingsService, AutocompleteService, roleCourses, DialogService, ComparatorService) {
        $scope.selectedCourseCode = '';
        $scope.loading = false;
        $scope.courseCodes = roleCourses.sort(function (a, b) {
            return ComparatorService.courseCodeComparator(a, b);
        });
        $scope.getCourses = function (query) {
            if (!query || query === '') {
                return $scope.courseCodes;
            }
            var result = [];
            $scope.courseCodes.forEach(function (t) {
                if (t.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(t);
                }
            });
            return result;
        };
        $scope.selectCourse = function () {
            $scope.selectedCourseCode = $scope.courseInput;
        };
        $scope.autocompleteFunc = AutocompleteService.autocompleteUsers;
        $scope.removeEditor = ClassificationEditorsSettingsService.deleteCourseEditor;
        $scope.addEditor = ClassificationEditorsSettingsService.addCourseEditor;
        $scope.getEditor = ClassificationEditorsSettingsService.getCourseEditors;
    }]);
})(angular);