(function (angular) {
    angular.module("app").service("DocumentationService", ["AddressBuild", "restUrlPublicPrefix", "$http", function (AddressBuild, restUrlPublicPrefix, $http) {
        return {
            getMarkdownDocument: function (documentAddress, lang) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'document/' + documentAddress + AddressBuild.parameters([['lang', lang]])
                });
            }
        };
    }]);
})(angular);