(function (angular) {
    angular.module('app').directive('column', function () {
        return {
            restrict: 'E',
            scope: {
                //data: '='
            },
            templateUrl: 'classification/directive/column/column.tmpl.html',
            link: function (scope, elem, attrs) {

                //-------------------------------------------------------------------------
                if (!scope.data) {
                    scope.data = {
                        names: [{
                            text: "very very_very_very long name_1",
                            active: false
                        }, {
                            text: "very very_very_very long name_2",
                            active: true
                        }],
                        identifier: "very very_very long identifier",
                        relation: "relation_to_course",
                        dataType: "very long data_type",
                        buttons: [{
                            icon: 'fa-pencil',
                            title: '',
                            color: 'positive',
                            link: console.log("edit"),
                            enabled: true
                        }, {
                            icon: 'fa-pencil',
                            title: '',
                            color: 'neutral',
                            link: function () {
                                console.log("copy");
                            },
                            enabled: true
                        }, {
                            icon: 'fa-pencil',
                            title: '',
                            color: 'primary',
                            link: console.log("addSubcolumn"),
                            enabled: true
                        }, {
                            icon: 'fa-pencil',
                            title: '',
                            color: 'negative',
                            link: console.log("delete"),
                            enabled: true
                        }],
                        icons: [{
                            icon: "fa-eye",
                            show: true,
                            valid: true
                        }, {
                            icon: "fa-pencil-square-o",
                            show: true,
                            valid: false
                        }],
                        wrapper: {
                            show: true,
                            wrapped: true
                        }
                    };
                }
                //-------------------------------------------------------------------------
            }
        };
    });
})(angular);