(function (angular) {
    angular.module("app").service("CourseExternalApplicationsService", ["restUrlPublicPrefix", "$http", "AddressBuild", "SecurityService", function (restUrlPublicPrefix, $http, AddressBuild, SecurityService) {

        var getExternalApplicationsDistinguishedByCourse = function (courseCode) {
            return $http({
                method: 'GET',
                url: restUrlPublicPrefix + 'courses/' + courseCode + '/external-applications'
            });
        };
        var deleteExternalApplicationFromCourse = function (courseCode, appId) {
            return $http({
                method: 'DELETE',
                url: restUrlPublicPrefix + 'courses/' + courseCode + '/external-applications/' + appId
            });
        };
        var addExternalApplicationToCourse = function (courseCode, appId) {
            return $http({
                method: 'PUT',
                url: restUrlPublicPrefix + 'courses/' + courseCode + '/external-applications/' + appId
            });
        };

        //todo udelat metodu na backendu pro tahani jen aplikaci v predmetu
        var getExternalApplicationsInCourse = function (courseCode) {
            return getExternalApplicationsDistinguishedByCourse(courseCode).then(function (response) {
                var allAppsDistinguishedByCourse = response.data;
                var appsInCourse = [];
                allAppsDistinguishedByCourse.forEach(function (app) {
                    if (app.inCourse) {
                        delete app.inCourse;
                        appsInCourse.push(app);
                    }
                });
                return appsInCourse;
            });
        };

        var modifyAppsPermissionToEditCoursesDefinitions = function (courseCode, appId, canEdit) {
            return $http({
                method: 'PUT',
                url: restUrlPublicPrefix + 'courses/' + courseCode + '/external-applications/' + appId + '/edit-permission' + AddressBuild.parameters([["semester", SecurityService.getSemester()], ["can-edit", canEdit]])
            });
        };

        return {
            getExternalApplicationsDistinguishedByCourse: getExternalApplicationsDistinguishedByCourse,
            getExternalApplicationsInCourse: getExternalApplicationsInCourse,
            deleteExternalApplicationFromCourse: deleteExternalApplicationFromCourse,
            addExternalApplicationToCourse: addExternalApplicationToCourse,
            modifyAppsPermissionToEditCoursesDefinitions: modifyAppsPermissionToEditCoursesDefinitions
        };
    }]);
})(angular);