(function (angular) {
    angular.module('app').directive('scoreInput', ["$translate", function ($translate) {
        return {
            restrict: 'AC',
            replace: false,
            transclude: false,
            scope: { ngModel: '=', max: '=?', req: '=?' },
            link: function (scope, element, attrs) {
                formatInput();
                element.bind('blur focus', formatInput);

                function formatInput(e) {
                    if (scope.ngModel === null) {
                        return;
                    }
                    if (scope.max !== null && scope.ngModel > scope.max && (!e || e.type == 'blur')) {
                        element[0].style.color = '#38b12b';
                        element[0].title = $translate.instant('WRITTEN_VALUE_IS_HIGHER_THAN_MAXIMUM');
                    } else if (scope.req !== null && scope.ngModel < scope.req && (!e || e.type == 'blur')) {
                        element[0].style.color = '#ff7800';
                        element[0].title = $translate.instant('WRITTEN_VALUE_IS_LOWER_THAN_MINIMUM_REQUIRED');
                    } else {
                        element[0].style.color = null;
                        element[0].title = '';
                    }
                }
            }
        };
    }]);
})(angular);