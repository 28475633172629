(function (angular) {
    angular.module("app").service("NotificationService", ["restUrlPublicPrefix", "AddressBuild", "$http", "$rootScope", "WebsocketService", function (restUrlPublicPrefix, AddressBuild, $http, $rootScope, WebsocketService) {
        // keeps information about notifications up to date
        // all notification handling should be managed indirectly through this service

        var notificationPool = {};

        var fetchedLanguage;

        function synchronize(element) {
            var present = notificationPool[element.id];
            if (present) {
                return present;
            } else {
                notificationPool[element.id] = element;
                return element;
            }
        }

        function synchronizeList(list) {
            for (var i = 0; i < list.length; ++i) {
                list[i] = synchronize(list[i]);
            }
        }

        var allNotifications = {
            list: [],
            total: 0
        };

        var unreadNotifications = {
            list: [],
            total: 0
        };

        var invalidated = true;

        var newsSize = 5;
        var newsBufferSize = 10;

        //Triggers on language change
        $rootScope.$on('$translateChangeSuccess', function (event, current) {
            getAllNotifications(20, 0, current.language);
        });

        var initialize = function () {
            allNotifications.list.length = 0;
            unreadNotifications.list.length = 0;
            invalidated = true;
            getAllNotifications(20, 0, $rootScope.currentLanguage);
            getNewNotifications($rootScope.currentLanguage);
        };
        WebsocketService.handleScopeSubscription($rootScope, '/user/queue/notification', function (newNotification) {
            pushNewNotification(newNotification);
        }, initialize);

        function pushNewNotification(newNotification) {
            newNotification = synchronize(newNotification);
            allNotifications.list.unshift(newNotification);
            allNotifications.total++;
            if (!newNotification.read) {
                unreadNotifications.list.unshift(newNotification);
                unreadNotifications.total++;
            }
        }

        var notificationPrefix = restUrlPublicPrefix + 'notifications';

        function getNewNotifications(lang) {
            if (invalidated || unreadNotifications.list.length < newsSize && unreadNotifications.total >= newsSize) {
                fetchNewNotifications(lang);
                invalidated = false;
            }
            return unreadNotifications;
        }

        function fetchNewNotifications(lang) {
            return $http({
                method: 'GET',
                url: notificationPrefix + '/' + $rootScope.username + '/new' + AddressBuild.parameters([['count', newsBufferSize], ['page', 0], ['lang', lang]])
            }).then(function (response) {
                var data = response.data;
                synchronizeList(data.notifications);
                unreadNotifications.list.length = 0;
                Array.prototype.push.apply(unreadNotifications.list, data.notifications);
                unreadNotifications.total = data.count;
            });
        }

        function invalidate() {
            notificationPool = {};
            allNotifications.list = [];
            unreadNotifications.list = [];
        }

        function getAllNotifications(count, page, lang) {
            if (fetchedLanguage !== lang) {
                fetchedLanguage = lang;
                invalidate();
            }
            $http({
                method: 'GET',
                url: notificationPrefix + '/' + $rootScope.username + '/all' + AddressBuild.parameters([['count', count], ['page', page], ['lang', lang]])
            }).then(function (response) {
                var data = response.data;
                synchronizeList(data.notifications);
                allNotifications.list.length = 0;
                Array.prototype.push.apply(allNotifications.list, data.notifications);
                allNotifications.total = data.count;
            });
            fetchNewNotifications(lang);
            return allNotifications;
        }

        function readAllNotifications() {
            $http({
                method: 'PUT',
                url: notificationPrefix + '/' + $rootScope.username + '/read'
            }).success(function () {
                allNotifications.list.forEach(function (notification) {
                    notification.read = true;
                });
                unreadNotifications.list.length = 0;
                unreadNotifications.total = 0;
            });
        }

        function unreadAllNotifications() {
            $http({
                method: 'DELETE',
                url: notificationPrefix + '/' + $rootScope.username + '/read'
            }).success(function () {
                allNotifications.list.forEach(function (notification) {
                    notification.read = false;
                });
                unreadNotifications.list.length = 0;
                Array.prototype.push.apply(unreadNotifications.list, allNotifications.list);
                unreadNotifications.total = allNotifications.total;
            });
        }

        function readNotification(notification) {
            $http({
                method: 'PUT',
                url: notificationPrefix + '/' + $rootScope.username + '/read/' + notification.id
            }).success(function () {
                var start = unreadNotifications.list.indexOf(notification);
                if (start !== -1) {
                    unreadNotifications.list.splice(start, 1);
                    unreadNotifications.total--;
                    notification.read = true;
                }
            });
        }

        function unreadNotification(notification) {
            $http({
                method: 'DELETE',
                url: notificationPrefix + '/' + $rootScope.username + '/read/' + notification.id
            }).success(function () {
                unreadNotifications.list.push(notification);
                unreadNotifications.total++;
                unreadNotifications.list.sort(function (a, b) {
                    return b.timestamp - a.timestamp;
                });
                notification.read = false;
            });
        }

        var sendNotification = function (byMail, body, semesterCode, courseCode) {
            return $http({
                method: 'POST',
                url: notificationPrefix + '/send' + AddressBuild.parameters([['byMail', byMail], ['semester', semesterCode], ['course', courseCode]]),
                data: body
            });
        };

        return {
            invalidateNotifications: initialize,
            getNewNotifications: getNewNotifications,
            getAllNotifications: getAllNotifications,
            readNotification: readNotification,
            unreadNotification: unreadNotification,
            readAllNotifications: readAllNotifications,
            unreadAllNotifications: unreadAllNotifications,
            sendNotification: sendNotification,
            pushNewNotification: pushNewNotification
        };
    }]);
})(angular);