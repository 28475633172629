angular.module("app").controller("SettingsCtrl", ["$q", "$rootScope", "$scope", "SettingsService", "settings", "DialogService", "MenuService", "SecurityService", function ($q, $rootScope, $scope, SettingsService, settings, DialogService, MenuService, SecurityService) {
    $scope.settings = settings;
    $scope.saveAll = function () {
        var courseSettingPromises = [];
        courseSettingPromises.push(SettingsService.saveUserSettings($scope.settings.userSettingsDto));
        $scope.settings.studentCourseSettings.forEach(function (setting) {
            courseSettingPromises.push(SettingsService.saveStudentCourseSettings(setting));
        });
        $scope.settings.teacherCourseSettings.forEach(function (setting) {
            courseSettingPromises.push(SettingsService.saveTeacherCourseSettings(setting));
        });
        $q.all(courseSettingPromises).then(function () {
            MenuService.reloadMenu(SecurityService.getSemester());
            DialogService.sendPositiveNotification('SETTINGS_HAS_BEEN_SUCCESSFULLY_SAVED');
        })["catch"](function () {
            DialogService.sendNegativeNotification('ERROR_SAVING_SETTINGS');
        });
    };
    $scope.multiRole = false;
    var cnt = 0;
    if ($scope.settings.studentCourseSettings) cnt++;
    if ($scope.settings.teacherCourseSettings) cnt++;
    if (cnt > 1) $scope.multiRole = true;
}]);