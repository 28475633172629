(function (angular) {
    angular.module("app").service("ExpressionService", ["restUrlPublicPrefix", "$http", function (restUrlPublicPrefix, $http) {
        return {
            getDataTypes: function () {
                return {
                    NUMBER: { nameKey: "NUMBER", identifier: "NUMBER" },
                    STRING: { nameKey: "STRING", identifier: "STRING" },
                    BOOLEAN: { nameKey: "BOOLEAN", identifier: "BOOLEAN" }
                };
            },
            getKeywords: function () {
                return ['true', 'false', 'null', 'number', 'string', 'boolean'];
            },
            tryValidity: function (expression) {
                return $http({
                    method: 'POST',
                    url: restUrlPublicPrefix + 'expressions/analysis',
                    data: expression
                });
            },
            tryValidityAll: function (expressions) {
                return $http({
                    method: 'POST',
                    url: restUrlPublicPrefix + 'expressions/analyses',
                    data: expressions
                });
            },
            getFunctions: function () {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'expressions/functions',
                    cache: true
                });
            },
            getAggregationFunctions: function () {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'expressions/aggregation-functions',
                    cache: true
                });
            }
        };
    }]);
})(angular);