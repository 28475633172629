(function (angular) {
    angular.module('app').service('WizardService', ["EvolutionTeamService", function (EvolutionTeamService) {
        return {
            wizardInputType: function (inputType) {
                switch (inputType) {
                    case 'TEXT':
                        return 'TEXT';
                    case 'NUMBER':
                        return 'NUMBER';
                    case 'BLANK':
                        return 'BLANK';
                    default:
                        throw 'Invalid wizard input type!';
                }
            },
            createWizardObject: function (wizardPageArray, submitFunction) {
                return {
                    pages: wizardPageArray,
                    submitFunction: submitFunction
                };
            },
            // title is a languageTag -> label object
            // it is displayed on top of the form
            // put the default panel as the first entry in the array
            //
            // special pages with reserved identifier:
            // - summary
            createWizardPage: function (wizardPanelArray, identifier, title) {
                switch (identifier) {
                    case 'summary':
                        console.error('Wizard page with name \'' + identifier + '\' is reserved!');
                        return;
                    default:
                }
                return {
                    panels: wizardPanelArray,
                    identifier: identifier, // unique page identifier
                    title: title
                };
            },
            // TODO: panel title and description
            createWizardPanel: function (wizardInputArray, radioLabel) {
                return {
                    inputs: wizardInputArray,
                    radioLabel: radioLabel ? radioLabel : ''
                };
            },
            // label is a languageTag -> label object
            // for inputConstraints see createInputConstraints
            createWizardInput: function (identifier, label, inputType, inputConstraints) {
                var validatedInputType = this.wizardInputType(inputType);

                return {
                    identifier: identifier, // identifier only needs to be unique on the page
                    label: label,
                    inputType: validatedInputType, // see wizard.tmpl.html
                    value: inputConstraints !== undefined ? inputConstraints.defaultValue : undefined,
                    constraints: inputConstraints
                    // value: defaultValue ? defaultValue : undefined
                    // TODO: optional something, that does input validation
                };
            },
            // don't use this anymore and add them ad hoc
            // but document them here!
            //
            // - defaultValue: default value for the input
            // - required: whether the input is required or not
            // - min: minimal value of the input, usually used for numbers
            // - max: maximal value of the input, usually used for numbers
            // - step: allowed step of the input, usually 1 in conjunction with min to enforce ints
            createInputConstraints: function (value, required, validationFunction) {
                console.error('Don\'t use this anymore, see documentation');
                var defaultRequired = false;
                var defaultValidationFunction = function () {
                    return true;
                };

                return {
                    defaultValue: value, // undefined default
                    required: required === undefined ? defaultRequired : required,
                    validationFunction: validationFunction === undefined ? validationFunction : defaultValidationFunction
                };
            },
            // TODO: better documentation, purpose of most fields is mystic
            retrieveWizardObjectData: function (wizardObject, activePanel) {
                var toReturn = {};
                var blankInputType = this.wizardInputType('BLANK');
                wizardObject.pages.forEach(function (page, pageIndex) {
                    toReturn[page.identifier] = {};
                    var currentPage = toReturn[page.identifier];
                    currentPage.inputs = {};
                    var toInsertInputs = currentPage.inputs;
                    page.panels[activePanel[pageIndex]].inputs.forEach(function (input) {
                        if (input.inputType === blankInputType) {
                            return;
                        }
                        toInsertInputs[input.identifier] = input.value;
                    });
                    currentPage.title = page.title;
                    currentPage.activePanel = activePanel[pageIndex];
                });
                return toReturn;
            }
        };
    }]);
})(angular);