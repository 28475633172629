(function (angular) {
    angular.module('app').directive('icon', function () {
        return {
            scope: {
                type: '=', // can be 'help', 'warn', 'error'
                text: '=',
                link: '=?',
                blank: '=?'
            },
            restrict: 'E',
            templateUrl: 'classification/directive/icon/icon.tmpl.html'
        };
    });
})(angular);