(function (angular) {
    angular.module('app').directive('headerPanel', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                show: '='
            },
            templateUrl: 'classification/directive/headerPanel/headerPanel.tmpl.html',
            link: function (scope, elem, attrs) {

                scope.$watch('show', function () {
                    if (scope.show === true) {
                        elem.addClass("show");
                    } else {
                        elem.removeClass("show");
                    }
                });

                scope.close = function () {
                    scope.show = false;
                };
            }
        };
    });
})(angular);