/*
* Checkbox with three states: true,false,null.
* Received values are turned into icons.
* Can be toggled into read-only state.
* Styling: tristateCheckbox.less
* */
(function (angular) {
    angular.module('app').directive('tristateCheckbox', function () {
        return {
            require: '?ngModel',
            restrict: 'E',
            replace: false,
            scope: {
                readOnly: '=' //read-only or writeable
            },
            templateUrl: 'classification/directive/tristateCheckbox/tristateCheckbox.tmpl.html',
            link: function (scope, element, attrs, ngModel) {

                var states = [true, false, null];
                var classNames = ["true", "false", "null"];

                scope.click = function () {
                    scope.model = true;
                    states.map(function (val, i) {
                        if (ngModel.$modelValue === val) {
                            scope.model = states[(i + 1) % 3];
                        }
                    });

                    ngModel.$setViewValue(scope.model);
                };

                ngModel.$render = function () {
                    scope.model = ngModel.$modelValue;
                };

                scope.getClass = function () {
                    var className = 'null';
                    states.map(function (val, i) {
                        if (ngModel.$modelValue === val) {
                            className = classNames[i];
                        }
                    });
                    return className;
                };
            }
        };
    });
})(angular);