(function (angular) {
    angular.module("app").controller("CourseClassificationDefinitionCtrl", ["$rootScope", "$scope", "$route", "$translate", "courseRoles", "SecurityService", "DialogService", "TranslateService", "ExpressionService", "ClassificationService", "classificationsDefinitions", "EvolutionTeamService", "AutocompleteService", "CsvService", "DownloadUploadService", "courseInformation", "CourseService", "WizardService", "AddressBuild", function ($rootScope, $scope, $route, $translate, courseRoles, SecurityService, DialogService, TranslateService, ExpressionService, ClassificationService, classificationsDefinitions, EvolutionTeamService, AutocompleteService, CsvService, DownloadUploadService, courseInformation, CourseService, WizardService, AddressBuild) {
        $scope.courseCode = $route.current.params.courseCode;
        $scope.semesterCode = SecurityService.getSemester();
        $scope.canEditCourse = courseRoles.canEditCourse();
        $scope.courseName = courseInformation.courseName;
        $scope.classesType = courseInformation.classesType;
        var requireAssessment = CourseService.courseRequiresAssessment(courseInformation);
        var requireClassifiedCredit = CourseService.courseRequiresClassifiedCredit(courseInformation);
        var requireFinalScore = CourseService.courseRequiresFinalScore(courseInformation);
        var requireExam = CourseService.courseRequiresExam(courseInformation);

        $scope.viewAsStudentLink = 'courses/' + $scope.courseCode + '/students/teststudent1' + AddressBuild.parameters([['view', 'definition'], ['semester', $scope.semesterCode]]);
        $scope.availableVariables = {};
        $scope.columns = classificationsDefinitions;

        // if invalid is true, then the corresponding warning will be displayed
        function initWarning(text) {
            return {
                invalid: false,
                warningText: text
            };
        }

        function shouldWarn(warn) {
            return warn.invalid === true;
        }

        function getWarningText(warn) {
            return warn.warningText;
        }

        var warningFlags = {
            missingDefinitions: {
                assessment: initWarning("MISSING_ASSESSMENT_DEFINITION"),
                finalScore: initWarning("MISSING_FINAL_SCORE_DEFINITION")
            },
            booleanNotUsedInAssessment: initWarning("BOOLEAN_NOT_USED_IN_ASSESSMENT"),
            markNotUsedInFinalScore: initWarning("MARK_NOT_USED_IN_FINAL_SCORE"),
            multipleAssessments: initWarning("MULTIPLE_ASSESSMENTS"),
            multipleFinalScores: initWarning("MULTIPLE_FINAL_SCORES"),
            redundantAssessment: initWarning("REDUNDANT_ASSESSMENT"),
            emptyExpression: initWarning("EMPTY_EXPRESSION"),
            hiddenAssessment: initWarning("ASSESSMENT_SHOULD_BE_VISIBLE"),
            hiddenFinalScore: initWarning("FINAL_SCORE_SHOULD_BE_VISIBLE")
        };
        $scope.warningsExist = false;
        checkForWarnings();
        $scope.warningTexts = [];

        $scope.columnHierarchy = ClassificationService.buildClassificationHierarchy($scope.columns);
        $scope.dragAndDropEnabled = false;
        $scope.import = { deleteExisting: false };
        refreshColumns();
        validateAllExpressions();

        $scope.availableLanguages = TranslateService.getLanguages();
        $scope.lang = TranslateService.getLanguage();
        $scope.availableDataTypes = ExpressionService.getDataTypes();
        $scope.availableClassificationTypes = ClassificationService.getClassificationTypes();
        $scope.exportDialogOutput = {
            fileName: "data",
            delimiter: ","
        };

        $scope.identifierRegex = '^[_a-zA-Z][_a-zA-Z0-9]*$';
        $scope.matchAnyRegex = '[\\s\\S]*';

        var exportColumns = ClassificationService.fileExportColumns();

        generateNewColumns();

        $scope.dialogs = {
            columnEditor: false,
            template: false,
            import: false,
            export: false,
            wizard: false
        };

        var closeAllDialogs = function () {
            $scope.dialogs.columnEditor = false;
            $scope.dialogs.template = false;
            $scope.dialogs.import = false;
            $scope.dialogs.export = false;
            $scope.dialogs.wizard = false;
        };

        $scope.defineClassificationInfo = {
            text: "DEFINE_CLASSIFICATION",
            title: "CREATE_CLASSIFICATION_DEFINITION",
            icon: "fa-plus"
        };

        $scope.defineClassificationData = [{
            text: "ADD_COLUMN",
            title: "ADD_COLUMN_TITLE",
            link: function () {
                closeAllDialogs();
                $scope.dialogs.columnEditor = true;
            }
        }, {
            text: "USE_TEMPLATE",
            title: "USE_TEMPLATE_TITLE",
            link: function () {
                closeAllDialogs();
                $scope.dialogs.template = true;
            }
        }, {
            text: "IMPORT",
            title: "IMPORT_TITLE",
            link: function () {
                closeAllDialogs();
                $scope.dialogs.import = true;
            }
        }, {
            text: "CLASSIFICATION_WIZARD",
            title: "CLASSIFICATION_WIZARD_TITLE",
            link: function () {
                closeAllDialogs();
                $scope.dialogs.wizard = true;
            }
        }];

        $scope.openExportDialog = function () {
            closeAllDialogs();
            $scope.dialogs.export = true;
        };

        $scope.closeColumnEditor = function () {
            $scope.dialogs.columnEditor = false;
        };

        $scope.getSemesters = function (query) {
            return AutocompleteService.autocompleteSemesterCode(query).then(function (response) {
                return response.data;
            });
        };
        $scope.importDefinitions = function () {
            switch ($scope.importSelectModel) {
                case 'fileImport':
                    importFile();
                    resetFile();
                    checkForWarnings();
                    break;
                case 'otherImport':
                    ClassificationService.importDefinitions($scope.import.sourceCourseCode, $scope.import.sourceSemesterCode, $scope.courseCode, $scope.semesterCode, $scope.import.deleteExisting).then(function (response) {
                        $route.reload();
                        checkForWarnings();
                        closeAllDialogs();
                    });
                    break;
            }
        };

        $scope.exportSelectModel = 'csv';
        $scope.exportDefinitions = function () {
            switch ($scope.exportSelectModel) {
                case 'csv':
                    exportToCsv();
                    closeAllDialogs();
                    break;
            }
        };

        $scope.cancelAddingColumn = function () {
            $scope.columnEditor = false;
        };

        $scope.cancelAddingSubcolumn = function (column) {
            column.addingSubcolumn = false;
        };

        //Rids columns of properties left from copying
        function cleanupAfterCopying(columns) {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].hasOwnProperty("isCopy")) {
                    delete columns[i].isCopy;
                }
            }
        }

        $scope.addColumns = function (columns, parent, clearForm) {
            cleanupAfterCopying(columns);
            doSaveColumns(columns, false);
            if (clearForm) {
                generateNewColumn(parent);
            }
        };

        $scope.deleteColumn = function (column) {
            $scope.columnToDelete = column;
            var translateDynamic = TranslateService.translateDynamic(column.classificationTextDtos);
            if ($scope.columnHierarchy[column.id].length === 0) {
                DialogService.createConfirmDialog('DELETE_COLUMN', $translate.instant('DO_YOU_REALLY_WANT_TO_DELETE_COLUMN') + " " + (translateDynamic ? translateDynamic.name : '---') + "?", function () {
                    doDeleteColumn(column, false);
                }, 'DELETE', 'CANCEL', 'negative', 'neutral');
            } else {
                DialogService.createDecisionDialog('DELETE_COLUMN', $translate.instant('DO_YOU_REALLY_WANT_TO_DELETE_COLUMN') + " " + (translateDynamic ? translateDynamic.name : '---') + "?", function () {
                    doDeleteColumn(column, true);
                }, function () {
                    doDeleteColumn(column, false);
                }, 'DELETE', 'DELETE_WITH_SUBCOLUMNS', 'negative', 'negative');
            }
        };

        function findColumnById(id) {
            for (var i = 0; i < $scope.columns.length; i++) {
                if ($scope.columns[i].id === id) {
                    return $scope.columns[i];
                }
            }
            return null;
        }

        $scope.copyColumn = function (column) {
            var parent = findColumnById(column.parentId);
            var columnCopy = angular.copy(column);
            columnCopy.isCopy = true;
            if (parent) {
                parent.newColumn = columnCopy;
                parent.addingSubcolumn = true;
            } else {
                $scope.newColumn = columnCopy;
                $scope.dialogs.columnEditor = true;
                $scope.columnEditor = true;
            }

            delete columnCopy.id;
            delete columnCopy.newColumn;
        };

        function columnHierarchyToIdHierarchy() {
            var columnOrder = [];
            for (var columnId in $scope.columnHierarchy) {
                var childIds = [];
                if ($scope.columnHierarchy.hasOwnProperty(columnId)) {
                    var list = $scope.columnHierarchy[columnId];
                    for (var i = 0; i < list.length; i++) {
                        childIds.push(list[i].id);
                    }
                    columnOrder.push({ parentId: columnId, childrenIds: childIds });
                }
            }
            return columnOrder;
        }

        $scope.columnHasChildren = function (column) {
            return column.id != null && $scope.columnHierarchy[column.id].length > 0;
        };

        $scope.treeOptions = {
            beforeDrop: function (e) {
                function findParentId(locationObject) {
                    var parentColumn = locationObject.nodesScope.$parent.$modelValue;
                    return parentColumn == null ? null : parentColumn.id;
                }
                var itemId = e.source.nodeScope.$modelValue.id;
                var sourceParentId = findParentId(e.source);
                var destParentId = findParentId(e.dest);

                var columnOrder = columnHierarchyToIdHierarchy();

                //delete from source position
                columnOrder.find(function (item) {
                    return String(sourceParentId) === item.parentId;
                }).childrenIds.splice(e.source.index, 1);

                //add to destination position
                columnOrder.find(function (item) {
                    return String(destParentId) === item.parentId;
                }).childrenIds.splice(e.dest.index, 0, itemId);
                var item = findColumnById(itemId);
                item.parentId = destParentId;

                //TODO spinner

                function columnOrderToIdentifierHierarchy(columnOrder) {
                    var idToIdentifier = {};
                    $scope.columns.forEach(function (column) {
                        idToIdentifier[column.id] = column.identifier;
                    });

                    var result = {};
                    columnOrder.forEach(function (column) {
                        var parentIdentifier = column.parentId == null || column.parentId === "null" ? '' : idToIdentifier[column.parentId];
                        result[parentIdentifier] = column.childrenIds.map(function (id) {
                            return idToIdentifier[id];
                        });
                    });

                    return result;
                }

                var identifierHierarchy = columnOrderToIdentifierHierarchy(columnOrder);
                return ClassificationService.saveClassificationColumnOrder(identifierHierarchy, $scope.semesterCode, $scope.courseCode).then(function (allowDrop) {
                    return allowDrop;
                })["finally"](function () {
                    //TODO cancel spinner
                });
            },

            dropped: function () {}
        };

        $scope.switchToEditMode = function (column) {
            column.originalColumn = EvolutionTeamService.copyObject(column);
            column.expanded = false;
            column.editMode = true;
        };

        $scope.editColumn = function (columns) {
            doSaveColumns(columns, true);
            checkForWarnings();
        };

        $scope.discardEditColumn = function (column) {
            angular.copy(column.originalColumn, column);
        };

        $scope.toggleEditMode = function (column) {
            if (column.editMode) {
                DialogService.createConfirmDialog('CANCEL_EDITATION', 'DO_YOU_REALLY_WANT_TO_DISCARD_CHANGES', function () {
                    $scope.discardEditColumn(column);
                }, 'YES_DISCARD_CHANGES', 'CANCEL', 'negative', 'neutral');
            } else {
                $scope.switchToEditMode(column);
            }
        };

        $scope.toggleAddingSubcolumn = function (column) {
            column.addingSubcolumn = !column.addingSubcolumn;
        };

        function validateAllExpressions() {
            var expressions = {};
            $scope.columns.forEach(function (column) {
                if (column.evaluationType === "EXPRESSION") {
                    column.expression = !column.expression ? "" : column.expression;
                    expressions[column.identifier] = column.expression;
                }
            });
            var query = {
                expressions: expressions,
                variableValueTypes: $scope.availableVariables
            };
            ExpressionService.tryValidityAll(query).then(function (response) {
                var validationResponse = response.data;
                $scope.columns.forEach(function (column) {
                    if (validationResponse.hasOwnProperty(column.identifier)) {
                        column.validationResult = validationResponse[column.identifier];
                    }
                });
            });
        }

        function refreshColumns() {
            $scope.availableVariables = {
                "$COURSE_CODE": "STRING",
                "$SEMESTER_CODE": "STRING",
                "$USERNAME": "STRING",
                "$FIRSTNAME": "STRING",
                "$LASTNAME": "STRING",
                "$FULLNAME": "STRING",
                "$PERSONAL_NUMBER": "STRING",
                "$THIS.IDENTIFIER": "STRING",
                "$THIS.HIDDEN": "BOOLEAN",
                "$THIS.MIN": "NUMBER",
                "$THIS.MIN_REQUIRED": "NUMBER",
                "$THIS.MAX": "NUMBER"
            };
            angular.forEach($scope.classesType, function (parallelType) {
                $scope.availableVariables["$" + parallelType + "_PARALLEL_NUMBER"] = "STRING";
                $scope.availableVariables["$" + parallelType + "_PARALLEL_CODE"] = "STRING";
            });
            angular.forEach($scope.columns, function (column) {
                $scope.availableVariables[column.identifier] = column.valueType;
                $scope.availableVariables[column.identifier + ".IDENTIFIER"] = "STRING";
                $scope.availableVariables[column.identifier + ".HIDDEN"] = "BOOLEAN";
                if (column.valueType === "NUMBER") {
                    $scope.availableVariables[column.identifier + ".MIN"] = "NUMBER";
                    $scope.availableVariables[column.identifier + ".MIN_REQUIRED"] = "NUMBER";
                    $scope.availableVariables[column.identifier + ".MAX"] = "NUMBER";
                }
            });
        }

        function generateNewColumns() {
            generateNewColumn();
            angular.forEach($scope.columns, function (parent) {
                generateNewColumn(parent);
            });
        }

        function generateNewColumn(parent) {
            var parentId;
            if (!parent) {
                parent = $scope;
                parentId = null;
            } else {
                parentId = parent.id;
            }

            parent.newColumn = {
                classificationTextDtos: [],
                skillDtos: [],
                evaluationType: 'MANUAL',
                hidden: false,
                valueType: $scope.availableDataTypes.NUMBER.identifier,
                expression: "",
                parentId: parentId,
                aggregatedIdentifier: null
            };
        }

        function doSaveColumns(columns, edit) {
            var loadingDialog = DialogService.createLoadingDialog('SAVING');
            for (var i = 0; i < columns.length; i++) {
                var column = columns[i];
                column.courseCode = $scope.courseCode;
                column.semesterCode = $scope.semesterCode;
            }
            return ClassificationService.saveClassificationColumnList(columns, $scope.courseCode).then(function (response) {
                var length = response.data.length;
                if (!edit) {
                    for (var i = 0; i < length; i++) {
                        var column = columns[i];
                        column.id = response.data[i];
                        if (column.parentId === undefined) column.parentId = null;
                        generateNewColumn(column);
                        $scope.columns.push(column);
                        $scope.columnHierarchy[column.id] = [];
                        $scope.columnHierarchy[column.parentId].push(column);
                    }
                }
                refreshColumns();
                validateAllExpressions();
                DialogService.hideDialog(loadingDialog);
                var notification = edit ? 'COLUMN_HAS_BEEN_SUCCESSFULLY_EDITED' : length === 1 ? 'COLUMN_HAS_BEEN_SUCCESSFULLY_CREATED' : 'COLUMNS_HAS_BEEN_SUCCESSFULLY_CREATED';
                DialogService.sendPositiveNotification(notification);
                checkForWarnings();
            }, function (err) {
                DialogService.hideDialog(loadingDialog);
            });
        }

        function doDeleteColumn(column, preserveChildren) {
            var loadingDialog = DialogService.createLoadingDialog('SAVING');
            ClassificationService.deleteClassificationColumn(column, preserveChildren).then(function (response) {
                var parentChildren = $scope.columnHierarchy[column.parentId];
                var index = parentChildren.indexOf(column);
                parentChildren.splice(index, 1);
                var deleteFunction = function (column) {
                    $scope.columns.splice($scope.columns.indexOf(column), 1);
                    var children = $scope.columnHierarchy[column.id];
                    var parentChildren = $scope.columnHierarchy[column.parentId];
                    for (var i = 0; i < children.length; i++) {
                        var child = children[i];
                        if (preserveChildren) {
                            child.parentId = column.parentId;
                            parentChildren.splice(index + i, 0, child);
                        } else {
                            deleteFunction(child);
                        }
                    }
                    delete $scope.columnHierarchy[column.id];
                    delete $scope.availableVariables[column.identifier];
                };
                deleteFunction(column);
                refreshColumns();
                validateAllExpressions();
                DialogService.sendPositiveNotification('COLUMN_HAS_BEEN_SUCCESSFULLY_DELETED');
                loadingDialog.shown = false;
                checkForWarnings();
            })["catch"](function (error) {
                loadingDialog.shown = false;
            });
        }

        var saveColumnsWithChildren = function (columns) {
            var childrenLists = [];
            var addColumns = [];
            var addColumn = function (column) {
                var find = $scope.columns.find(function (item) {
                    return column.identifier == item.identifier;
                });
                if (find == undefined) {
                    addColumns.push(column);
                    childrenLists.push(column.children ? column.children : []);
                    delete column.children;
                } else if (column.children) {
                    column.children.forEach(function (child) {
                        child.parentId = find.id;
                        addColumn(child);
                    });
                }
            };
            columns.forEach(addColumn);
            doSaveColumns(addColumns).then(function () {
                var nextLevel = [];
                for (var i = 0; i < addColumns.length; i++) {
                    var column = addColumns[i];
                    var children = childrenLists[i];
                    for (var j = 0; j < children.length; j++) {
                        var child = children[j];
                        child.parentId = column.id;
                        nextLevel.push(child);
                    }
                }
                if (nextLevel.length !== 0) {
                    saveColumnsWithChildren(nextLevel);
                }
            });
        };

        $scope.generateColumns = function (input, func) {
            var newColumns = func(input);
            saveColumnsWithChildren(newColumns);
        };

        function applySuffixToLocale(arr, suffix) {
            var res = [];
            for (var i = 0; i < arr.length; ++i) {
                res.push(Object.assign({}, arr[i]));
                res[i].name += suffix;
            }
            return res;
        }

        var exportToCsv = function () {
            var exportingDialog = DialogService.createLoadingDialog('EXPORTING');
            var delimiter = $scope.exportDialogOutput.delimiter ? $scope.exportDialogOutput.delimiter : ',';
            var toExport = EvolutionTeamService.copyObject($scope.columns);

            var searchParentById = function (id) {
                for (var i = 0; i < toExport.length; ++i) {
                    var current = toExport[i];
                    if (current.id === id) {
                        return current.identifier;
                    }
                }
                return null;
            };

            toExport.forEach(function (classificationDefinition) {
                classificationDefinition.classificationTextDtos.forEach(function (classificationTextDto) {
                    var lang = classificationTextDto.identifier;
                    classificationDefinition[lang + "_name"] = classificationTextDto.name;
                });
                classificationDefinition.parentIdentifier = searchParentById(classificationDefinition.parentId);
            });

            var csv = CsvService.jsonToCsv(exportColumns, angular.toJson(toExport), delimiter);
            var name = $scope.exportDialogOutput.fileName ? $scope.exportDialogOutput.fileName : "data.csv";
            DownloadUploadService.download(csv, name + '.csv', 'csv');
            DialogService.hideDialog(exportingDialog);

            DialogService.sendPositiveNotification('CLASSIFICATION_DEFINITION_SUCCESSFULLY_EXPORTED');
        };

        var file = null;
        $scope.setFile = function (evt) {
            file = evt;
        };

        var resetFile = function () {
            $scope.importFileModel = null;
            file = null;
        };

        var importFile = function () {
            if (file == null) {
                return;
            }
            var reverseParentsToChildList = function (importedClassification) {
                var topLevelList = [];
                importedClassification.forEach(function (c) {
                    c.children = [];
                });

                var searchParentByIdentifier = function (ident) {
                    var i, current;
                    for (i = 0; i < importedClassification.length; ++i) {
                        current = importedClassification[i];
                        if (current.identifier === ident) {
                            return current;
                        }
                    }

                    for (i = 0; i < $scope.columns.length; ++i) {
                        current = $scope.columns[i];
                        if (current.identifier === ident) {
                            var toReturn = EvolutionTeamService.copyObject(current);
                            toReturn.children = [];
                            topLevelList.push(toReturn);
                            return toReturn;
                        }
                    }
                    throw ClassificationService.validationException("MISSING_PARENT", ident);
                };

                importedClassification.forEach(function (c) {
                    if (c.parentIdentifier !== null && c.parentIdentifier !== undefined && c.parentIdentifier !== "") {
                        var parent = searchParentByIdentifier(c.parentIdentifier);
                        parent.children.push(c);
                    } else {
                        topLevelList.push(c);
                    }
                });
                return topLevelList;
            };

            DownloadUploadService.upload(file, function (fileContent) {
                var importedData = CsvService.csvToJson(exportColumns, fileContent);
                var message;
                try {
                    ClassificationService.validateImportedJson(importedData);
                } catch (err) {
                    message = TranslateService.translate(err.msg);
                    if (err.offender !== null) {
                        message += ": '" + err.offender + "'";
                    }
                    if (err.row !== null) {
                        message += " " + TranslateService.translate("ON_ROW") + " " + err.row;
                    }
                    DialogService.sendNegativeNotification(message, 30000);
                    $rootScope.$apply();
                    return;
                }

                var addTextDtos = function (classificationDefinition) {
                    classificationDefinition.classificationTextDtos = [];

                    if (classificationDefinition.cs_name) {
                        classificationDefinition.classificationTextDtos.push({ identifier: "cs", name: classificationDefinition.cs_name });
                        delete classificationDefinition.cs_name;
                    }

                    if (classificationDefinition.en_name) {
                        classificationDefinition.classificationTextDtos.push({ identifier: "en", name: classificationDefinition.en_name });
                        delete classificationDefinition.en_name;
                    }
                };

                importedData.forEach(function (classificationDefinition) {
                    addTextDtos(classificationDefinition);
                    classificationDefinition.newColumn = null;
                    classificationDefinition.courseCode = $scope.courseCode;
                    classificationDefinition.semesterCode = $scope.semesterCode;
                    if (classificationDefinition.evaluationType !== "EXPRESSION") {
                        classificationDefinition.expression = null;
                    }
                    if (classificationDefinition.valueType === "NUMBER") {
                        if (!EvolutionTeamService.isNumber(classificationDefinition.minimumValue)) {
                            classificationDefinition.minimumValue = null;
                        }
                        if (!EvolutionTeamService.isNumber(classificationDefinition.minimumRequiredValue)) {
                            classificationDefinition.minimumRequiredValue = null;
                        }
                        if (!EvolutionTeamService.isNumber(classificationDefinition.maximumValue)) {
                            classificationDefinition.maximumValue = null;
                        }
                    }
                });

                var topLevel;
                try {
                    topLevel = reverseParentsToChildList(importedData);
                } catch (err) {
                    message = TranslateService.translate(err.msg) + ": '" + err.offender + "'";
                    DialogService.sendNegativeNotification(message, 30000);
                    $rootScope.$apply();
                    return;
                }
                saveColumnsWithChildren(topLevel);
            });
            checkForWarnings();
        };

        function generateRows(start, end, obj, identifier, name) {
            var children = [];
            var i;
            if (end < 10) {
                for (i = start; i <= end; ++i) {
                    obj.identifier = identifier + i;
                    obj.classificationTextDtos = applySuffixToLocale(name, ' ' + i);
                    children.push(Object.assign({}, obj));
                }
            } else {
                for (i = start; i <= end; ++i) {
                    if (i < 10) {
                        obj.identifier = identifier + "0" + i;
                    } else {
                        obj.identifier = identifier + i;
                    }
                    obj.classificationTextDtos = applySuffixToLocale(name, ' ' + i);
                    children.push(Object.assign({}, obj));
                }
            }
            return children;
        }

        function objIsWarning(obj) {
            var keys = Object.keys(obj);
            return keys.includes("invalid") && keys.includes("warningText");
        }

        // obj will most likely be `warningFlags`
        function fillWarningTextsRec(obj, warningTexts) {
            if (objIsWarning(obj)) {
                if (obj.invalid) {
                    warningTexts.push(getWarningText(obj));
                }
            } else {
                Object.keys(obj).forEach(function (key) {
                    fillWarningTextsRec(obj[key], warningTexts);
                });
            }
        }

        function fillWarningTexts() {
            $scope.warningTexts = [];
            fillWarningTextsRec(warningFlags, $scope.warningTexts);
        }

        // obj will most likely be `warningFlags`
        function warningsExistRec(obj) {
            var toReturn = false;
            if (objIsWarning(obj)) {
                return shouldWarn(obj);
            } else {
                Object.keys(obj).forEach(function (key) {
                    toReturn = toReturn || warningsExistRec(obj[key]);
                    if (toReturn) {
                        return true;
                    }
                });
            }
            return toReturn;
        }

        function warningsExist() {
            $scope.warningsExist = warningsExistRec(warningFlags);
            return $scope.warningsExist;
        }

        function resetWarningFlagsRec(obj) {
            if (objIsWarning(obj)) {
                obj.invalid = false;
            } else {
                Object.keys(obj).forEach(function (key) {
                    resetWarningFlagsRec(obj[key]);
                });
            }
        }

        function resetWarningFlags() {
            resetWarningFlagsRec(warningFlags);
        }

        // adds warning text to a column in `$scope.columns`
        function columnAddWarningText(column, text) {
            var translatedText = TranslateService.translate(text);
            if (column.warningText === undefined) {
                column.warningText = translatedText + ".\n";
            } else {
                column.warningText += translatedText + ".\n";
            }
        }

        // detects whether any warnings are valid, warning texts are set at the end of the function
        // see `warningFlags` before modyfing
        function checkForWarnings() {
            var markRegex = /mark\s*\(/i;
            var emptyStringRegex = /\S/;
            var assessmentCounter = 0;
            var finalScoreCounter = 0;
            resetWarningFlags();

            if (requireAssessment) {
                warningFlags.missingDefinitions.assessment.invalid = true;
            }
            if (requireFinalScore) {
                warningFlags.missingDefinitions.finalScore.invalid = true;
            }

            // these are only used iff there exists only one assessment/final score column
            var assessmentColumnRef = null;
            var finalScoreColumnRef = null;
            $scope.columns.forEach(function (definition) {
                definition.warningText = undefined;
                if (definition.evaluationType === "EXPRESSION") {
                    if (!definition.expression) {
                        definition.expression = "";
                    }
                    if (definition.expression.search(emptyStringRegex) === -1) {
                        warningFlags.emptyExpression.invalid = true;
                        if (definition.warningText === undefined) {
                            definition.warningText = "";
                        }
                        columnAddWarning(definition, "THIS_COLUMN_EMPTY_EXPRESSION", "expression", "EXPRESSION_SHOULD_NOT_BE_EMPTY", emptyStringRegex);
                    }
                }
                if (definition.classificationType === "ASSESSMENT") {
                    ++assessmentCounter;
                    warningFlags.missingDefinitions.assessment.invalid = false;
                    assessmentColumnRef = definition;
                    if (definition.hidden) {
                        warningFlags.hiddenAssessment.invalid = true;
                        columnAddWarning(definition, "THIS_COLUMN_SHOULD_BE_VISIBLE", "hidden", "THIS_COLUMN_SHOULD_BE_VISIBLE", false);
                    }
                    if (definition.valueType !== "BOOLEAN") {
                        warningFlags.booleanNotUsedInAssessment.invalid = true;
                    }
                }
                if (definition.classificationType === "FINAL_SCORE") {
                    warningFlags.missingDefinitions.finalScore.invalid = false;
                    ++finalScoreCounter;
                    finalScoreColumnRef = definition;
                    if (definition.hidden) {
                        warningFlags.hiddenFinalScore.invalid = true;
                        columnAddWarning(definition, "THIS_COLUMN_SHOULD_BE_VISIBLE", "hidden", "THIS_COLUMN_SHOULD_BE_VISIBLE", false);
                    }
                    if (definition.expression !== null && definition.expression !== undefined && definition.valueType === "STRING" && definition.expression.search(markRegex) === -1) {
                        warningFlags.markNotUsedInFinalScore.invalid = true;
                    }
                }
            });

            if (assessmentCounter > 1) {
                warningFlags.multipleAssessments.invalid = true;
            }
            if (finalScoreCounter > 1) {
                warningFlags.multipleFinalScores.invalid = true;
            }
            if (assessmentCounter > 0 && !requireAssessment) {
                warningFlags.redundantAssessment.invalid = true;
            }

            if (assessmentCounter === 1 && shouldWarn(warningFlags.booleanNotUsedInAssessment)) {
                columnAddWarning(assessmentColumnRef, "THIS_COLUMN_BOOLEAN_NOT_USED_IN_ASSESSMENT", "valueType", "RECOMMENDED_TYPE_IS_BOOLEAN", "BOOLEAN");
            }
            if (finalScoreCounter === 1 && shouldWarn(warningFlags.markNotUsedInFinalScore)) {
                columnAddWarning(finalScoreColumnRef, "THIS_COLUMN_MARK_NOT_USED_IN_FINAL_SCORE", "expression", "RECOMMENDED_IS_EXPRESSION_WITH_MARK_FUNCTION", markRegex);
            }

            warningsExist();
            fillWarningTexts();
        }

        fillWarningTexts();

        function columnAddWarning(column, warningText, field, recommendedFieldText, recommendedFieldValue) {
            /* prepared calls for columns
               == should be calculated ==
                columnAddWarning(definition, "THIS_COLUMN_SHOULD_BE_CALCULATED", "calculated",
                "RECOMMENDED_INPUT_IS_CALCULATED", true);
            */
            columnAddWarningText(column, warningText);
            if (column.warning === undefined) {
                column.warning = {};
            }
            column.warning[field] = {};
            column.warning[field].text = recommendedFieldText;
            if (recommendedFieldValue !== null || recommendedFieldValue !== undefined) {
                column.warning[field].value = recommendedFieldValue;
            }
        }
        var DEFAULT_COLUMN_LIMIT_PER_REQUEST = 100;
        var EXAMS_COLUMN_LIMIT_PER_REQUEST = 65;
        var TRIES_COLUMN_LIMIT_PER_REQUEST = 35;
        $scope.templates = [{
            fields: ['count', 'max', 'totalMax'],
            inputDefault: {
                count: 1,
                max: undefined,
                totalMax: undefined
            },
            constraints: {
                count: {
                    type: 'NUMBER',
                    min: 1,
                    max: DEFAULT_COLUMN_LIMIT_PER_REQUEST,
                    required: true
                },
                max: {
                    type: 'NUMBER',
                    min: 1,
                    required: true
                },
                totalMax: {
                    type: 'NUMBER',
                    min: 1,
                    required: true
                }
            },
            input: null,
            translation: {
                cs: {
                    name: 'Aktivita',
                    description: 'Přidá sloupce "Aktivita1, Aktivita2, ..." a jejich součet "Aktivita".',
                    count: 'Počet cvičení / úloh',
                    max: 'Maximum bodů za úlohu',
                    totalMax: 'Celkové maximum za aktivitu'
                },
                en: {
                    name: 'Activity',
                    description: 'Adds columns "Activity1, Acitivity2, ..." and their sum "Activity".',
                    count: 'Number of tutorials / tasks',
                    max: 'Maximum points per task',
                    totalMax: 'Total maximum for activity'
                }
            },
            prepareTemplate: function (input) {
                var identifier = 'activity';
                var name = [{
                    name: 'Aktivita',
                    identifier: 'cs'
                }, {
                    name: 'Activity',
                    identifier: 'en'
                }];
                var act = {
                    classificationTextDtos: name,
                    evaluationType: 'MANUAL',
                    hidden: false,
                    valueType: 'NUMBER',
                    identifier: identifier,
                    classificationType: 'ACTIVITY',
                    expression: null,
                    index: 0,
                    courseCode: $scope.courseCode,
                    semesterCode: $scope.semesterCode,
                    maximumValue: input.max,
                    minimumValue: 0,
                    minimumRequiredValue: 0
                };
                var rows = [];

                act.children = generateRows(1, input.count, act, identifier, name);
                act.identifier = identifier + "_sum";
                act.evaluationType = 'EXPRESSION';
                act.expression = 'SUM(`' + identifier + '\\d+`)';
                act.maximumValue = input.totalMax;
                act.classificationTextDtos = name;

                rows.push(act);
                return rows;
            }
        }, {
            fields: ['count', 'requiredMinimum', 'maximum', 'requiredTotal'],
            input: {
                count: 1,
                requiredMinimum: undefined,
                maximum: undefined,
                requiredTotal: undefined
            },
            constraints: {
                count: {
                    type: 'NUMBER',
                    min: 1,
                    max: DEFAULT_COLUMN_LIMIT_PER_REQUEST,
                    required: true
                },
                requiredMinimum: {
                    type: 'NUMBER',
                    required: true
                },
                maximum: {
                    type: 'NUMBER',
                    required: true
                },
                requiredTotal: {
                    type: 'NUMBER',
                    required: true
                }
            },
            translation: {
                cs: {
                    name: 'Semestrální testy',
                    description: 'Přidá sloupce "Semestrální test 1, Semestrální test 2, ..." a jejich součet "Semestrální testy".',
                    count: 'Počet semestrálních testů',
                    requiredMinimum: 'Požadované minimum bodů z jednotlivých testů',
                    maximum: 'Maximální počet bodů jednotlivých testů',
                    requiredTotal: 'Požadovaný počet bodů ze součtu všech semestrálních testů'
                },
                en: {
                    name: 'Semestral tests',
                    description: 'Adds columns "Semestral test 1, Semestral test 2, ..." and their sum "Semestral tests".',
                    count: 'Number of semestral tests',
                    requiredMinimum: 'Required minimal amount of points from each semestral test',
                    maximum: 'Maximal amount of points of each semestral test',
                    requiredTotal: 'Required minimal amount of points of the sum of semestral tests'
                }
            },
            prepareTemplate: function (input) {
                var identifier = 'sem_test';
                var name = [{
                    name: 'Semestrální test',
                    identifier: 'cs'
                }, {
                    name: 'Semestral test',
                    identifier: 'en'
                }];
                var dto = {
                    classificationTextDtos: name,
                    evaluationType: 'MANUAL',
                    hidden: false,
                    valueType: 'NUMBER',
                    identifier: identifier,
                    classificationType: 'SEMESTRAL_TEST',
                    expression: null,
                    index: 0,
                    courseCode: $scope.courseCode,
                    semesterCode: $scope.semesterCode,
                    minimumValue: 0,
                    minimumRequiredValue: input.requiredMinimum,
                    maximumValue: input.maximum
                };
                var rows = [];

                dto.children = generateRows(1, input.count, dto, identifier, name);
                dto.classificationTextDtos = name;
                name[0].name = "Semestrální testy";
                name[1].name = "Semestral tests";
                dto.evaluationType = 'EXPRESSION';
                dto.expression = 'SUM(`' + identifier + '\\d+`)';

                dto.identifier = identifier + "_sum";
                dto.minimumRequiredValue = input.requiredTotal;
                dto.maximumValue = input.maximum !== undefined ? input.count * input.maximum : undefined;

                rows.push(dto);
                return rows;
            }
        }, {
            fields: ['examNameCs', 'examNameEn', 'examDateNameCs', 'examDateNameEn', 'examId', 'exams', 'examMin', 'examRequiredMin', 'examMax', 'attemptNameCs', 'attemptNameEn', 'attemptId', 'tries'],
            inputDefault: {
                examNameCs: 'Zkouška',
                examNameEn: 'Exam',
                examDateNameCs: 'Zkouškový termín',
                examDateNameEn: 'Exam date',
                examId: 'exam',
                examMin: 0,
                examRequiredMin: 20,
                examMax: 40,
                attemptNameCs: 'Pokus',
                attemptNameEn: 'Attempt',
                attemptId: 'attempt',
                exams: 5,
                tries: 3
            },
            input: null,
            validate: {
                examId: $scope.identifierRegex,
                attemptId: $scope.identifierRegex
            },
            constraints: {
                examNameCs: {
                    type: 'TEXT',
                    required: true
                },
                examNameEn: {
                    type: 'TEXT',
                    required: true
                },
                examDateNameCs: {
                    type: 'TEXT',
                    required: true
                },
                examDateNameEn: {
                    type: 'TEXT',
                    required: true
                },
                examId: {
                    type: 'TEXT',
                    required: true
                },
                examMin: {
                    type: 'NUMBER',
                    required: true
                },
                examRequiredMin: {
                    type: 'NUMBER',
                    required: true
                },
                examMax: {
                    type: 'NUMBER',
                    required: true
                },
                attemptNameCs: {
                    type: 'TEXT',
                    required: true
                },
                attemptNameEn: {
                    type: 'TEXT',
                    required: true
                },
                attemptId: {
                    type: 'TEXT',
                    required: true
                },
                exams: {
                    type: 'NUMBER',
                    min: 0,
                    max: EXAMS_COLUMN_LIMIT_PER_REQUEST,
                    required: true
                },
                tries: {
                    type: 'NUMBER',
                    min: 0,
                    max: TRIES_COLUMN_LIMIT_PER_REQUEST,
                    required: true
                }
            },
            translation: {
                cs: {
                    name: 'Zkouška',
                    description: 'Vytvoří sloupce pro zkouškové termíny, které student nevidí, a vytvoří studentovy pokusy, které student vidí. První (druhý, ...) pokus bude obsahovat první (druhou, ...) studentovu vyplněnou zkoušku',
                    examNameCs: 'Název česky',
                    examNameEn: 'Název anglicky',
                    examDateNameCs: 'Český název termínů',
                    examDateNameEn: 'Anglický název termínů',
                    examId: 'Identifikátor zkoušky',
                    examMin: 'Minimum bodů',
                    examRequiredMin: 'Nutné minimum bodů pro úspěch',
                    examMax: 'Maximum bodů za zkoušku',
                    attemptNameCs: 'Pokus česky',
                    attemptNameEn: 'Pokus anglicky',
                    attemptId: 'Identifikátor pokusu',
                    exams: 'Počet zkouškových termínů',
                    tries: 'Počet studentových pokusů'
                },
                en: {
                    name: 'Exam',
                    description: 'Creates columns for an exam date invisible for students. It also creates student tries which are visible for students. First (second, ...) attempt will contain',
                    examNameCs: 'Exam in czech',
                    examNameEn: 'Exam in english',
                    examDateNameCs: 'Czech exam date name',
                    examDateNameEn: 'English exam date name',
                    examId: 'Exam identifier',
                    examMin: 'Minimum of points',
                    examRequiredMin: 'Necessary minimum of points for success',
                    examMax: 'Maximum for an exam test',
                    attemptNameCs: 'Attempt in czech',
                    attemptNameEn: 'Attempt in english',
                    attemptId: 'Attempt identifier',
                    exams: 'Number of exams',
                    tries: 'Number of student tries'
                }
            },
            prepareTemplate: function (input) {
                var examId = input.examId;
                var examName = [{
                    name: input.examDateNameCs,
                    identifier: 'cs'
                }, {
                    name: input.examDateNameEn,
                    identifier: 'en'
                }];
                var exam = {
                    classificationTextDtos: examName,
                    evaluationType: 'MANUAL',
                    hidden: true,
                    valueType: 'NUMBER',
                    identifier: examId,
                    classificationType: 'EXAM_TEST',
                    expression: null,
                    index: 0,
                    courseCode: $scope.courseCode,
                    semesterCode: $scope.semesterCode,
                    minimumValue: input.examMin,
                    minimumRequiredValue: input.examRequiredMin,
                    maximumValue: input.examMax
                };
                var attemptName = [{
                    name: input.attemptNameCs,
                    identifier: 'cs'
                }, {
                    name: input.attemptNameEn,
                    identifier: 'en'
                }];
                var attemptId = input.attemptId;
                var attempt = {
                    classificationTextDtos: attemptName,
                    evaluationType: 'EXPRESSION',
                    hidden: false,
                    valueType: 'NUMBER',
                    identifier: attemptId,
                    classificationType: 'EXAM_TEST',
                    expression: '',
                    index: 0,
                    courseCode: $scope.courseCode,
                    semesterCode: $scope.semesterCode,
                    minimumValue: input.examMin,
                    minimumRequiredValue: input.examRequiredMin,
                    maximumValue: input.examMax
                };
                var buildExp = function (i) {
                    return 'ITH(' + i + ', `' + examId + '\\d+`)';
                };
                var rows = [];

                var exams = generateRows(1, input.exams, exam, examId, examName);
                var attempts = generateRows(1, input.tries, attempt, attemptId, attemptName);
                for (var i = 0; i < attempts.length; i++) {
                    attempts[i].expression = buildExp(i + 1);
                }
                exam.children = exams.concat(attempts);

                exam.identifier = examId + '_last';
                exam.evaluationType = "EXPRESSION";
                exam.expression = 'LAST(`' + examId + '\\d+`)';
                exam.maximumValue = input.examMax;
                exam.classificationTextDtos = [{
                    name: input.examNameCs,
                    identifier: 'cs'
                }, {
                    name: input.examNameEn,
                    identifier: 'en'
                }];
                exam.hidden = false;
                rows.push(exam);
                return rows;
            }
        }];

        $scope.refreshTemplates = function () {
            if (!$scope.selectedTemplate) {
                return;
            }
            $scope.templates.forEach(function (elem) {
                elem.input = angular.copy(elem.inputDefault);
            });
        };

        // initial data loading
        $scope.templates.forEach(function (elem) {
            elem.input = angular.copy(elem.inputDefault);
        });

        $scope.wizardIntroText = {
            cs: ['Vítejte v průvodci tvorbou klasifikace.', 'Průvodce obsahuje mnoho polí, většina z nich však není povinná. Je-li to však možné, vyplňte prosím meze hodnocení. Pomůže to Vám i studentům.', 'Lze se řídit jednoduchým pravidlem, že pokud Vás průvodce pustí na další stránku, pak je vše nejspíše v pořádku.', 'Výslednou vygenerovanou strukturu definic lze dále upravovat ručně.'],
            en: ['Welcome to the definition creation wizard.', 'The wizard contains many fields, most of them can be left empty though. But where possible please fill evaluation bounds. It will help you as well as students.', 'The rule of thumb is, that if the wizard let\'s you on the next page, then everything is probably ok.', 'The resulting structure can be further edited manually.']
        };

        // do not reorder arrays or else
        var wizardActivityPage = WizardService.createWizardPage([WizardService.createWizardPanel([], {
            cs: 'Nevytvářet definice pro aktivity na cvičení',
            en: 'Do not create a tutorial activity definition'
        }), WizardService.createWizardPanel([WizardService.createWizardInput('totalMax', {
            cs: 'Maximální celkový zisk',
            en: 'Maximal gain'
        }, WizardService.wizardInputType('NUMBER'), {
            min: 0,
            step: 1
        })], {
            cs: 'Vytvořit definici aktivity na cvičení',
            en: 'Create a tutorial activity definition'
        })], 'activity', {
            cs: 'Aktivita',
            en: 'Activity'
        });

        var wizardSemestralTestPage = WizardService.createWizardPage([WizardService.createWizardPanel([], {
            cs: 'Nevytvářet definice semestrálních testů',
            en: 'Do not create semestral test definitions'
        }), WizardService.createWizardPanel([WizardService.createWizardInput('count', {
            cs: 'Počet semestrálních testů',
            en: 'Amount of semestral tests'
        }, WizardService.wizardInputType('NUMBER'), {
            defaultValue: 1,
            required: true,
            min: 1,
            max: DEFAULT_COLUMN_LIMIT_PER_REQUEST,
            step: 1
        }), WizardService.createWizardInput('requiredMinimum', {
            cs: 'Požadované minimum z jednotlivých testů',
            en: 'Required minimum from each test'
        }, WizardService.wizardInputType('NUMBER')), WizardService.createWizardInput('requiredMinimumSum', {
            cs: 'Požadované minimum ze součtu bodů z testů',
            en: 'Required minimum from the sum of tests'
        }, WizardService.wizardInputType('NUMBER')), WizardService.createWizardInput('maximum', {
            cs: 'Maximum bodů z jednotlivých testů',
            en: 'Maximal amount of points from each test'
        }, WizardService.wizardInputType('NUMBER'))], {
            cs: 'Vytvořit definice semestrálních testů',
            en: 'Create semestral test definitions'
        })], 'semestralTests', {
            cs: 'Semestrální testy',
            en: 'Semestral tests'
        });

        var wizardAssessmentPage = WizardService.createWizardPage([WizardService.createWizardPanel([], {
            cs: 'Zadávat zápočet ručně',
            en: 'Insert assessment manually'
        }), WizardService.createWizardPanel([WizardService.createWizardInput('minimumFromSemester', {
            cs: 'Minimum ze semestru',
            en: 'Minimum from semester'
        }, WizardService.wizardInputType('NUMBER'), {
            defaultValue: requireExam ? 25 : 50,
            required: true,
            min: 1,
            step: 1
        })], {
            cs: 'Vytvořit definici pro zápočet, který se bude vypočítávat',
            en: 'Create a definition for assessment, which is going to be filled in using an expression'
        })], 'semesterSummary', {
            cs: 'Zápočet a souhrn semestru',
            en: 'Assessment and semester summary'
        });

        var wizardExamPage = WizardService.createWizardPage([WizardService.createWizardPanel([], {
            cs: 'Zadávat známku ručně',
            en: 'Insert mark manually'
        }), WizardService.createWizardPanel([WizardService.createWizardInput('amountOfTerms', {
            cs: 'Počet zkouškových termínů',
            en: 'An approximate amount of exam dates'
        }, WizardService.wizardInputType('NUMBER'), {
            defaultValue: 6,
            required: true,
            min: 1,
            max: EXAMS_COLUMN_LIMIT_PER_REQUEST,
            step: 1
        }), WizardService.createWizardInput('requiredMinimum', {
            cs: 'Požadované minimum ze zkoušky',
            en: 'Required minimum of ponits from the exam'
        }, WizardService.wizardInputType('NUMBER')), WizardService.createWizardInput('maximum', {
            cs: 'Maximální počet bodů ze zkoušky',
            en: 'Maximal amount of points from the exam'
        }, WizardService.wizardInputType('NUMBER'))], {
            cs: 'Vytvořit definice pro zkouškový test',
            en: 'Create definitions for exam test'
        })], 'exam', {
            cs: 'Zkouška',
            en: 'Exam'
        });

        var wizardPages = [];
        // WARNING: DO NOT REORDER OR ELSE!
        if (requireAssessment || requireClassifiedCredit) {
            wizardPages.push(wizardActivityPage);
            wizardPages.push(wizardSemestralTestPage);
        }
        if (requireAssessment) {
            wizardPages.push(wizardAssessmentPage);
        }
        if (requireExam) {
            wizardPages.push(wizardExamPage);
        }

        $scope.wizardObject = WizardService.createWizardObject(wizardPages, function (inputs) {
            var toSave = [];

            function accessInputsOrUndefined(obj) {
                if (obj === undefined) {
                    return undefined;
                } else {
                    return obj.inputs;
                }
            }

            function pageWorks(pageIdentifier) {
                return inputs[pageIdentifier] !== undefined && inputs[pageIdentifier].activePanel !== 0;
            }

            function nonemptyishInput(input) {
                return input || input === 0;
            }

            var pageIdToPage = {};
            var activityPageIdentifier = 'activity';
            var activityInputs = accessInputsOrUndefined(inputs[activityPageIdentifier]);
            var activityIdentifier = 'activity';

            var semestralTestsPageIdentifier = 'semestralTests';
            var semestralTestsInputs = accessInputsOrUndefined(inputs[semestralTestsPageIdentifier]);
            var semestralTestsIdentifier = 'semestral_tests';
            var singleSemestralTestIdentifier = 'semestral_test';
            var semestralTestsSumIdentifier;

            var semesterSummaryPageIdentifier = 'semesterSummary';
            var semesterSummaryInputs = accessInputsOrUndefined(inputs[semesterSummaryPageIdentifier]);
            var assessmentIdentifier = 'assessment';
            var assessmentInputType = 'MANUAL'; // markSubmit needs to see this, so it's here

            var examPageIdentifier = 'exam';
            var examInputs = accessInputsOrUndefined(inputs[examPageIdentifier]);
            var examResultIdentifier;

            var markIdentifier = 'mark';

            var pointSumIdentifier = 'points_total';
            var pointSumExprBuilder = [];

            var activitySubmit = function () {
                if (inputs[activityPageIdentifier] === undefined) {
                    return;
                }
                var activePanel = inputs[activityPageIdentifier].activePanel;
                if (activePanel === 0) {
                    return;
                }
                var languages = inputs[activityPageIdentifier].title;
                var parentExpression = null;
                var evaluationType = 'MANUAL';

                var dto = ClassificationService.createDefinitionDto(languages, evaluationType, false, 'NUMBER', activityIdentifier, 'ACTIVITY', parentExpression, 0, $scope.courseCode, $scope.semesterCode, 0, null, nonemptyishInput(activityInputs.totalMax) ? activityInputs.totalMax : null);
                toSave.push(dto);
                pointSumExprBuilder.push(activityIdentifier);
            };

            var semestralTestsSubmit = function () {
                if (inputs[semestralTestsPageIdentifier] === undefined) {
                    return;
                }

                var activePanel = inputs[semestralTestsPageIdentifier].activePanel;
                if (activePanel === 0) {
                    return;
                }

                var languages = inputs[semestralTestsPageIdentifier].title;
                var childrenLanguages = {
                    cs: 'Semestrální test',
                    en: 'Semestral test'
                };

                var minimumFromEach = null;
                if (nonemptyishInput(semestralTestsInputs.requiredMinimum)) {
                    minimumFromEach = semestralTestsInputs.requiredMinimum;
                }

                var minimumTotal = null;
                if (nonemptyishInput(semestralTestsInputs.requiredMinimumSum)) {
                    minimumTotal = semestralTestsInputs.requiredMinimumSum;
                }

                var maximumFromEach = null;
                var maximumTotal = null;
                if (nonemptyishInput(semestralTestsInputs.maximum)) {
                    maximumFromEach = semestralTestsInputs.maximum;
                    maximumTotal = semestralTestsInputs.count * semestralTestsInputs.maximum;
                }

                var parentExpression = 'SUM(`' + singleSemestralTestIdentifier + '\\d+`)';

                var childrenMinimum = semestralTestsInputs.count === 1 && !minimumFromEach ? minimumTotal : minimumFromEach;

                var childrenLanguageDto = ClassificationService.createLanguages(childrenLanguages);
                var childrenDto = ClassificationService.createDefinitionDto(childrenLanguages, 'MANUAL', false, 'NUMBER', singleSemestralTestIdentifier, 'SEMESTRAL_TEST', null, 0, $scope.courseCode, $scope.semesterCode, 0, childrenMinimum, maximumFromEach);

                if (semestralTestsInputs.count === 1) {
                    toSave.push(childrenDto);
                    semestralTestsSumIdentifier = singleSemestralTestIdentifier;
                } else {
                    var parentDto = ClassificationService.createDefinitionDto(languages, 'EXPRESSION', false, 'NUMBER', semestralTestsIdentifier, 'SEMESTRAL_TEST', parentExpression, 0, $scope.courseCode, $scope.semesterCode, 0, minimumTotal, maximumTotal);
                    parentDto.children = generateRows(1, semestralTestsInputs.count, childrenDto, singleSemestralTestIdentifier, childrenLanguageDto);
                    toSave.push(parentDto);
                    semestralTestsSumIdentifier = semestralTestsIdentifier;
                }
                pointSumExprBuilder.push(semestralTestsSumIdentifier);
            };

            var addExpression = function (expressionBuilder, expression) {
                if (expression && expression !== '') {
                    expressionBuilder.push(expression);
                }
            };

            var addSemestralTestsPassConditions = function (expressionBuilder) {
                var testsMinimumExpr = '';
                var testsTotalMinimumExpr = '';
                if (semestralTestsInputs.count > 1) {
                    if (nonemptyishInput(semestralTestsInputs.requiredMinimum)) {
                        testsMinimumExpr = 'MIN(`' + singleSemestralTestIdentifier + '\\d+`) >= ' + semestralTestsInputs.requiredMinimum;
                    }
                    if (nonemptyishInput(semestralTestsInputs.requiredMinimumSum)) {
                        testsTotalMinimumExpr = semestralTestsIdentifier + ' >= ' + semestralTestsInputs.requiredMinimumSum;
                    }
                } else {
                    var requiredMinimum = nonemptyishInput(semestralTestsInputs.requiredMinimum) ? semestralTestsInputs.requiredMinimum : semestralTestsInputs.requiredMinimumSum;
                    if (nonemptyishInput(requiredMinimum)) {
                        testsMinimumExpr = singleSemestralTestIdentifier + ' >= ' + requiredMinimum;
                    }
                }

                addExpression(expressionBuilder, testsMinimumExpr);
                addExpression(expressionBuilder, testsTotalMinimumExpr);
            };

            var assessmentSubmit = function () {
                if (inputs[semesterSummaryPageIdentifier] === undefined) {
                    return;
                }

                var languages = {
                    cs: 'Zápočet',
                    en: 'Assessment'
                };

                // teacher will insert assessment manually
                if (inputs[semesterSummaryPageIdentifier].activePanel === 0 || pointSumExprBuilder.length === 0) {
                    var manualAssessmentDto = ClassificationService.createDefinitionDto(languages, assessmentInputType, false, 'BOOLEAN', assessmentIdentifier, 'ASSESSMENT', null, 0, $scope.courseCode, $scope.semesterCode, null, null, null);
                    toSave.push(manualAssessmentDto);
                    //DialogService.sendNegativeNotification('COULDNT_FIND_CRITERIA_TO_AUTOMATICALLY_ASSESS');
                    return;
                }

                assessmentInputType = 'EXPRESSION';

                var assessmentMinimumBuilder = [];
                if (pageWorks(activityPageIdentifier)) {
                    assessmentMinimumBuilder.push(activityIdentifier);
                }
                if (pageWorks(semestralTestsPageIdentifier)) {
                    if (semestralTestsInputs.count === 1) {
                        assessmentMinimumBuilder.push(singleSemestralTestIdentifier);
                    } else {
                        assessmentMinimumBuilder.push(semestralTestsIdentifier);
                    }
                }
                var assessmentMinimumExpr = '';
                if (assessmentMinimumBuilder.length && nonemptyishInput(semesterSummaryInputs.minimumFromSemester)) {
                    assessmentMinimumExpr = 'SUM(' + assessmentMinimumBuilder.join(', ') + ') >= ' + semesterSummaryInputs.minimumFromSemester;
                }

                var expressionBuilder = [];
                addSemestralTestsPassConditions(expressionBuilder);
                addExpression(expressionBuilder, assessmentMinimumExpr);
                var expression = expressionBuilder.join(' && ');

                var dto = ClassificationService.createDefinitionDto(languages, 'EXPRESSION', false, 'BOOLEAN', assessmentIdentifier, 'ASSESSMENT', expression, 0, $scope.courseCode, $scope.semesterCode, null, null, null);
                toSave.push(dto);
            };

            var examSubmit = function () {
                if (inputs[examPageIdentifier] === undefined) {
                    return;
                }
                if (inputs[examPageIdentifier].activePanel === 0) {
                    return;
                }

                var defaultInputs = angular.copy($scope.templates[$scope.templates.length - 1].input);
                defaultInputs.examRequiredMin = nonemptyishInput(examInputs.requiredMinimum) ? examInputs.requiredMinimum : null;
                defaultInputs.examMax = nonemptyishInput(examInputs.maximum) ? examInputs.maximum : null;
                defaultInputs.exams = examInputs.amountOfTerms;
                var examDto = $scope.templates[$scope.templates.length - 1].prepareTemplate(defaultInputs)[0];
                toSave.push(examDto);
                examResultIdentifier = examDto.identifier;
                pointSumExprBuilder.push(examResultIdentifier);
            };

            var pointSumSubmit = function () {
                var languages = {
                    cs: 'Celkový počet bodů',
                    en: 'Total number of points'
                };

                if (pointSumExprBuilder.length > 0) {
                    var expression = 'SUM(' + pointSumExprBuilder.join(', ') + ')';
                    var dto = ClassificationService.createDefinitionDto(languages, 'EXPRESSION', false, 'NUMBER', pointSumIdentifier, 'POINTS_TOTAL', expression, 0, $scope.courseCode, $scope.semesterCode, 0, 50, 100);
                    toSave.push(dto);
                }
            };

            var markSubmit = function () {
                var languages = {
                    cs: 'Známka',
                    en: 'Mark'
                };

                if (!requireFinalScore) {
                    return;
                }

                if (inputs[examPageIdentifier] !== undefined && inputs[examPageIdentifier].activePanel === 0 || pointSumExprBuilder.length === 0) {
                    var manualDto = ClassificationService.createDefinitionDto(languages, 'MANUAL', false, 'STRING', markIdentifier, 'FINAL_SCORE', null, 0, $scope.courseCode, $scope.semesterCode, null, null, null, ["A", "B", "C", "D", "E", "F"]);
                    toSave.push(manualDto);
                    return;
                }

                var expression = '';
                var markPassConditionBuilder = [];
                if (pageWorks(semesterSummaryPageIdentifier)) {
                    markPassConditionBuilder.push(assessmentIdentifier);
                } else if (requireClassifiedCredit) {
                    addSemestralTestsPassConditions(markPassConditionBuilder);
                }

                if (requireExam && nonemptyishInput(examInputs.requiredMinimum)) {
                    markPassConditionBuilder.push(examResultIdentifier + '>=' + examInputs.requiredMinimum);
                }
                var markPassCondition = markPassConditionBuilder.join(' && ');

                if (markPassCondition) {
                    expression += '!(' + markPassCondition + ')' + ' ? \'F\' : ';
                }

                expression += 'MARK(' + pointSumIdentifier + ')';
                var dto = ClassificationService.createDefinitionDto(languages, 'EXPRESSION', false, 'STRING', markIdentifier, 'FINAL_SCORE', expression, 0, $scope.courseCode, $scope.semesterCode);
                toSave.push(dto);
            };

            activitySubmit();
            semestralTestsSubmit();
            assessmentSubmit();
            examSubmit();
            pointSumSubmit();
            markSubmit();

            saveColumnsWithChildren(toSave);
        });

        $scope.usingWizard = false;
        $scope.toggleWizard = function () {
            $scope.usingWizard = !$scope.usingWizard;
        };
    }]);
})(angular);