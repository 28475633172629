(function (angular) {
    angular.module('app').directive('semesterDialog', ["restUrlPublicPrefix", "$http", "$window", "$rootScope", "SecurityService", "AutocompleteService", "$location", "MenuService", "SemesterService", "$timeout", "$document", "DialogService", function (restUrlPublicPrefix, $http, $window, $rootScope, SecurityService, AutocompleteService, $location, MenuService, SemesterService, $timeout, $document, DialogService) {
        return {
            restrict: 'E',
            templateUrl: 'classification/directive/semesterDialog/semesterDialog.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.semesterCode = SecurityService.getSemester();
                scope.semesterObj = SecurityService.getRawSemester();

                scope.getSemesters = function (query) {
                    return AutocompleteService.autocompleteSemesterCode(query).then(function (response) {
                        return response.data;
                    });
                };

                scope.changeSemester = function (request) {
                    if (request) {
                        //"prev", "current", "next"
                        scope.semesterCode = request;
                    }
                    if (!scope.semesterCode) {
                        DialogService.sendNegativeNotification("ENTER_SEMESTER_CODE");
                        return;
                    }
                    SecurityService.changeSemester(scope.semesterCode, function () {
                        var semester = SecurityService.getRawSemester();
                        $location.path('home');
                        if (!semester.isCurrent()) {
                            $location.search({ semester: semester.get() });
                        } else {
                            $location.search({ semester: null });
                        }
                        MenuService.reloadMenu(SecurityService.getSemester());
                    });
                };
            }
        };
    }]);
})(angular);