(function (angular) {
    angular.module('app').directive('formatText', function () {
        return {
            restrict: 'A',
            replace: true,
            transclude: false,
            template: '<div class="structured-text"><p ng-repeat="paragraph in paragraphs track by $index">{{paragraph}}</p></div>',
            link: function (scope, elem, attrs) {
                attrs.$observe('formatText', function () {
                    scope.paragraphs = attrs.formatText.split('\n');
                });
            }
        };
    });
})(angular);