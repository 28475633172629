(function (angular) {
    angular.module("app").service("EvolutionTeamService", function () {
        return {
            //Zlobí při ng-repeat. Alternativa angular.copy(obj)
            copyObject: function (object, targetObject) {
                if (!(object instanceof Object)) {
                    return object;
                }
                if (!targetObject) {
                    if (object instanceof Array) {
                        targetObject = [];
                    } else {
                        targetObject = {};
                    }
                }
                for (var key in object) {
                    if (!object.hasOwnProperty(key) || object[key] == targetObject[key]) {
                        continue;
                    }
                    targetObject[key] = this.copyObject(object[key]);
                }
                for (key in targetObject) {
                    if (targetObject.hasOwnProperty(key) && !object.hasOwnProperty(key)) {
                        targetObject[key] = null;
                    }
                }
                return targetObject;
            },
            isNumber: function (n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },
            isArray: function (obj) {
                return Object.prototype.toString.call(obj) === '[object Array]';
            },
            // if obj is a function or null, then true!!
            isObject: function (obj) {
                return typeof val === 'object';
            },
            // creates union of arr1 and arr2
            arrayUnion: function (arr1, arr2) {
                var res = [];
                if (arr1) {
                    arr1.forEach(function (item) {
                        res.push(item);
                    });
                }
                if (arr2) {
                    arr2.forEach(function (item) {
                        if (res.indexOf(item) === -1) {
                            res.push(item);
                        }
                    });
                }
                return res;
            }
        };
    });

    angular.module('app').filter('toFixedIfNeeded', function () {
        function filter(input, precision) {
            if (precision === undefined) {
                precision = 2;
            }

            return input.toFixed(precision).replace(/\.?0*$/, '');
        }

        return filter;
    });

    angular.module('app').filter('firstLetterUppercase', function () {
        function filter(input) {
            return input.charAt(0).toUpperCase() + input.slice(1);
        }

        return filter;
    });
})(angular);