(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/courses/:courseCode/external-applications', {
            templateUrl: 'classification/pages/teacher/CourseClassification/CourseExternalApplications/CourseExternalApplications.tmpl.html',
            controller: 'CourseExternalApplicationsCtrl',
            resolve: {
                applications: ["CourseExternalApplicationsService", "$route", function (CourseExternalApplicationsService, $route) {
                    var courseCode = $route.current.params.courseCode;
                    return CourseExternalApplicationsService.getExternalApplicationsDistinguishedByCourse(courseCode).then(function (response) {
                        return response.data;
                    });
                }],
                courseRoles: ["SecurityService", "$route", function (SecurityService, $route) {
                    var courseCode = $route.current.params.courseCode;
                    return SecurityService.getCourseRoles(courseCode).then(function (response) {
                        return response;
                    });
                }]
            }
        });
    }]);
})(angular);