(function (angular) {
    angular.module("app").controller("SearchCtrl", ["$scope", "$location", "$timeout", "SearchService", function ($scope, $location, $timeout, SearchService) {
        var fetchDataTimer;

        $scope.searchType = "all";

        $scope.setSetSearchType = function (type) {
            $scope.searchType = type;
            $scope.search($scope.searchInput);
        };

        /* FIXME Commented for future use.
         * Calls backend method, which needs to be fixed first.
         * */
        // $scope.search = function (query) {
        //     return SearchService.searchForQuery(query).then(function (response) {
        //         $scope.results = response.data;
        //         return response.data;
        //     });
        // };

        $scope.redirectOnResult = function (result) {
            $location.path(result.url);
        };

        $scope.waitAndSearch = function (query) {
            $timeout.cancel(fetchDataTimer);
            if ($scope.searchInput == '') {
                return;
            }
            fetchDataTimer = $timeout(function () {
                $scope.search(query);
            }, 500);
        };
    }]);
})(angular);