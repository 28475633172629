// TODO: is a function scope guard necessary?
angular.module('app').component('courseStatistics', {
    templateUrl: 'classification/template/course-statistics.tmpl.html',
    bindings: {
        courseStats: '<',
        onRefresh: '<'
    },
    controller: ["$scope", "toFixedIfNeededFilter", "CourseStatisticsService", "addSemesterFilter", function ($scope, toFixedIfNeededFilter, CourseStatisticsService, addSemesterFilter) {
        $scope.$ctrl.colors = ['#24a524', '#9cd319', '#dae241', '#e6c20f', '#ec8803', '#bb1111', '#666666'];
        Chart.defaults.global.colors = $scope.$ctrl.colors;

        $scope.$ctrl.loading = {};
        Object.keys($scope.$ctrl.courseStats).forEach(function (courseCode) {
            $scope.$ctrl.loading[courseCode] = false;
        });

        $scope.$ctrl.refresh = function (courseCode) {
            if ($scope.$ctrl.loading[courseCode]) {
                return;
            }

            $scope.$ctrl.loading[courseCode] = true;
            $scope.$ctrl.onRefresh(courseCode).then(function (response) {
                var data = response.data;
                CourseStatisticsService.processSingleCourseStats(data, $scope.$ctrl.courseStats);
                $scope.$ctrl.loading[courseCode] = false;
            });
        };
    }]
});