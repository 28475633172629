(function (angular) {
    angular.module("app").service("SearchInTextService", function () {
        function findInText(text, pattern) {
            if (!text) return;
            if (!pattern || pattern === '') return true;
            var normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
            var normalizedPattern = pattern.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
            return normalizedText.indexOf(normalizedPattern) !== -1;
        }

        return {
            findInText: findInText,
            findInTexts: function (texts, pattern) {
                if (!pattern || pattern == '') return true;
                var patterns = pattern.split(' ');
                for (var i in patterns) {
                    var patFound = false;
                    if (!patterns.hasOwnProperty(i)) continue;
                    var pat = patterns[i];
                    for (var key in texts) {
                        if (texts.hasOwnProperty(key) && findInText(texts[key], pat)) {
                            patFound = true;
                            break;
                        }
                    }
                    if (!patFound) {
                        return false;
                    }
                }
                return true;
            }
        };
    });
})(angular);