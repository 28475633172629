(function (angular) {
    angular.module("app").service("WebsocketService", ["$window", "$rootScope", "$stomp", "websocketEndpoint", "KeepAliveService", function ($window, $rootScope, $stomp, websocketEndpoint, KeepAliveService) {

        var connect = function () {

            $stomp.setDebug(function (args) {
                //todo debug property
                // console.log(args);
            });

            var errorCallback = function (error) {
                $rootScope.$broadcast('websocket-lost');
                $rootScope.$apply();
            };

            $stomp.connect(websocketEndpoint, null, errorCallback, { transports: ['websocket', 'xhr-polling'] }).then(function () {
                $rootScope.$broadcast('websocket-connected');
                $stomp.sock.onheartbeat = function () {
                    KeepAliveService.ping();
                };
            });

            $rootScope.$broadcast('websocket-connecting');
            //todo shared websocket between browser tabs
            if (localStorage.getItem("webSocketConnected") !== "true") {
                localStorage.setItem("webSocketConnected", "true");
            }
        };

        var reconnect = function () {
            connect();
            $rootScope.$broadcast('websocket-reconnected');
        };

        var disconnect = function () {
            if (localStorage.getItem("webSocketConnected") !== "false") {
                localStorage.setItem("webSocketConnected", "false");
            }
            $stomp.disconnect();
            $rootScope.$broadcast('websocket-disconnected');
        };

        var isManuallyDisabled = function () {
            return localStorage.getItem("webSocketConnected") === "false";
        };

        $window.addEventListener('storage', function (event) {
            if (event.key === 'webSocketConnected') {
                if (event.newValue === "true") {
                    reconnect();
                } else {
                    disconnect();
                }
                if (!$rootScope.$$phase) {
                    $rootScope.$apply();
                }
            }
        });

        return {
            connect: connect,
            reconnect: reconnect,
            disconnect: disconnect,
            isManuallyDisabled: isManuallyDisabled,
            isConnected: function () {
                return $stomp.sock !== null && $stomp.sock.readyState === SockJS.OPEN;
            },
            handleScopeSubscription: function (scope, destination, callback, reconnectHandler, headers) {
                var subscription;
                var subscribe = function () {
                    subscription = $stomp.subscribe(destination, callback, headers);
                };

                scope.$on('websocket-connected', subscribe);
                if (this.isConnected()) {
                    subscribe();
                }

                scope.$on('websocket-reconnected', reconnectHandler);

                scope.$on('$destroy', function (event, args) {
                    subscription.unsubscribe();
                });
            }
        };
    }]);
})(angular);