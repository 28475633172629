(function (angular) {
    angular.module("app").controller("CourseExternalApplicationsCtrl", ["$scope", "CourseExternalApplicationsService", "applications", "SecurityService", "$route", "DialogService", "$translate", "courseRoles", function ($scope, CourseExternalApplicationsService, applications, SecurityService, $route, DialogService, $translate, courseRoles) {

        $scope.canEditExternalApplications = courseRoles.canEditExternalApplications();
        $scope.applications = applications;
        var username = SecurityService.getUsername();
        $scope.courseCode = $route.current.params.courseCode;
        $scope.appsInCourse = null;
        $scope.appsNotInCourse = null;
        $scope.userInput = {
            app: null
        };

        function init() {
            var appsInCourse = [];
            var appsNotInCourse = [];
            for (var i = 0; i < $scope.applications.length; i++) {
                var app = $scope.applications[i];
                if (app.inCourse) {
                    appsInCourse.push(app);
                } else {
                    //flags prepared for grouping in ui-select
                    if (app.externalApplicationDto.authorUsername === username) {
                        app.group = 'my';
                    } else if (app.externalApplicationDto.isOfficial) {
                        app.group = 'official';
                    } else {
                        app.group = 'other';
                    }
                    appsNotInCourse.push(app);
                }
            }

            appsInCourse.sort(function (a, b) {
                if (a.externalApplicationDto.authorUsername === username && b.externalApplicationDto.authorUsername !== username) {
                    return -1;
                } else if (a.externalApplicationDto.authorUsername !== username && b.externalApplicationDto.authorUsername === username) {
                    return 1;
                } else {
                    if (a.externalApplicationDto.isOfficial && !b.externalApplicationDto.isOfficial) {
                        return -1;
                    } else if (!a.externalApplicationDto.isOfficial && b.externalApplicationDto.isOfficial) {
                        return 1;
                    } else {
                        if (a.canModifyDefinitions && !b.canModifyDefinitions) {
                            return -1;
                        } else if (a.canModifyDefinitions && !b.canModifyDefinitions) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                }
            });
            $scope.appsInCourse = appsInCourse;
            $scope.appsNotInCourse = appsNotInCourse;
        }

        init();

        function showSpinner() {
            $scope.loading = true;
        }

        function hideSpinner() {
            $scope.loading = false;
            $scope.typeaheadLoading = false;
        }

        function isAppInAppsNotInCourse(appId) {
            for (var i = 0; i < $scope.appsNotInCourse.length; i++) {
                if ($scope.appsNotInCourse[i].externalApplicationDto.externalApplicationId === appId) {
                    return true;
                }
            }
            return false;
        }

        $scope.addAppToCourse = function () {
            showSpinner();
            var input = $scope.userInput.app;
            if (input) {
                var appId = null;
                if (input.hasOwnProperty("externalApplicationDto")) {
                    appId = input.externalApplicationDto.externalApplicationId;
                } else {
                    appId = input;
                }
                if (!appId) {
                    DialogService.sendNegativeNotification("INPUT_INVALID_APPLICATION");
                    hideSpinner();
                } else {
                    var isAppIdAvailable = isAppInAppsNotInCourse(appId);
                    if (!isAppIdAvailable) {
                        DialogService.sendNegativeNotification("INPUT_INVALID_APPLICATION");
                        hideSpinner();
                    } else {
                        CourseExternalApplicationsService.addExternalApplicationToCourse($scope.courseCode, appId).then(function (response) {
                            reloadApplications();
                            DialogService.sendPositiveNotification("APPLICATION_WAS_SUCCESSFULLY_ADDED");
                        })["catch"](function (data) {
                            hideSpinner();
                        });
                    }
                }
            }
            hideSpinner();
        };

        function reloadApplications() {
            CourseExternalApplicationsService.getExternalApplicationsDistinguishedByCourse($scope.courseCode).then(function (response) {
                $scope.applications = response.data;
                init();
                $scope.userInput.app = null;
                hideSpinner();
            });
        }

        $scope.deleteAppFromCourse = function (appDto) {
            var message = $translate.instant('DO_YOU_REALLY_WANT_TO_REMOVE_APPLICATION') + " " + appDto.name + "?";
            DialogService.createConfirmDialog('REMOVE_APPLICATION', message, function () {
                showSpinner();
                CourseExternalApplicationsService.deleteExternalApplicationFromCourse($scope.courseCode, appDto.externalApplicationId)["finally"](function () {
                    reloadApplications();
                    DialogService.sendPositiveNotification("APPLICATION_WAS_SUCCESSFULLY_REMOVED");
                });
            }, 'REMOVE', 'CANCEL', 'negative', 'neutral');
        };

        $scope.changePermissionToEdit = function (appDto, canEdit) {
            var appId = appDto.externalApplicationId;
            showSpinner();
            CourseExternalApplicationsService.modifyAppsPermissionToEditCoursesDefinitions($scope.courseCode, appId, canEdit).then(function (response) {
                reloadApplications();
                hideSpinner();
            }, function (data) {
                hideSpinner();
            });
        };
    }]);
})(angular);