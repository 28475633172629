(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/notifications', {
            templateUrl: 'classification/pages/common/Notification/Notification.tmpl.html',
            controller: 'NotificationCtrl',
            resolve: {
                allNotifications: ["NotificationService", "TranslateService", function (NotificationService, TranslateService) {
                    return NotificationService.getAllNotifications(20, 0, TranslateService.getLanguage());
                }]
            }
        });
    }]);
})(angular);