(function (angular) {
    angular.module('app').directive('tagsInputSkills', ["SearchInTextService", function (SearchInTextService) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                tags: '=?',
                lookup: '=?',
                action: '=?',
                appendToBody: '=?',
                allItems: '=?',
                loadTagFunction: '=?'
            },
            templateUrl: 'classification/template/tagsInput.tmpl.html',
            link: function (scope, elem, attrs) {
                if (attrs.showValKey) {
                    scope.showValKey = attrs.showValKey;
                }
                if (attrs.trackedBy) {
                    scope.trackedBy = attrs.trackedBy;
                }
                if (attrs.placeholder) {
                    scope.placeholder = attrs.placeholder;
                }
                if (attrs.customTags == 'true') {
                    scope.customTags = true;
                }
                scope.minLength = 0;
                if (attrs.minLength) {
                    scope.minLength = parseInt(attrs.minLength);
                }
                var input;
                angular.element(document).ready(function () {
                    input = angular.element(elem[0].getElementsByClassName('input-element'));
                    elem.bind('click', function () {
                        input[0].focus();
                    });

                    input.bind('keydown', function (e) {
                        var itemText = scope.selectedItem;
                        if (e.which === 8 && (!itemText || itemText === '')) {
                            //backspace
                            var i = scope.internalTags.length - 1;
                            if (i < 0) return;
                            scope.removeItem(scope.internalTags[i]);
                            scope.$apply('internalTags');
                        }
                        input[0].focus();
                        if (e.which === 13) {
                            //enter
                            e.preventDefault();
                            if (scope.customTags && itemText) {
                                for (var key in scope.internalTags) {
                                    if (!scope.internalTags.hasOwnProperty(key)) {
                                        continue;
                                    }
                                    var item = scope.internalTags[key];
                                    if (scope.showValKey) {
                                        if (itemText == item[scope.showValKey]) {
                                            return;
                                        }
                                    } else {
                                        if (itemText == item) {
                                            return;
                                        }
                                    }
                                }
                                if (scope.showValKey) {
                                    scope.selectedItem = {};
                                    scope.selectedItem[scope.showValKey] = itemText;
                                } else {
                                    scope.selectedItem = itemText;
                                }
                                scope.addItem();
                                scope.$apply('internalTags');
                            }
                        }
                        input[0].focus();
                    });

                    input.bind('focus', function () {
                        elem.addClass('focused');
                    });

                    input.bind('blur', function () {
                        elem.removeClass('focused');
                    });
                });

                scope.addItem = function () {
                    if (scope.internalTags.indexOf(scope.selectedItem) !== -1) return;
                    scope.internalTags.push(scope.selectedItem);
                    if (scope.trackedBy) {
                        scope.tags.push(scope.selectedItem[scope.trackedBy]);
                    }
                    if (scope.action) {
                        scope.action(scope.selectedItem);
                    }
                    scope.selectedItem = '';
                    input[0].blur();
                };

                scope.removeItem = function (item) {
                    var index = scope.internalTags.indexOf(item);
                    scope.internalTags.splice(index, 1);
                    if (scope.trackedBy) {
                        scope.tags.splice(scope.tags.indexOf(item[scope.trackedBy]), 1);
                    }
                    if (scope.action) {
                        scope.action();
                    }
                    input[0].blur();
                };
                scope.dropdownOpened = false;

                angular.element(document).ready(function () {
                    if (scope.lookup) {
                        scope.doLookup = scope.lookup;
                    } else {
                        scope.doLookup = function (input) {
                            return _.filter(scope.allItems, function (item) {
                                if (scope.internalTags.indexOf(item) != -1) return false;
                                var text = scope.showValKey ? item[scope.showValKey] : item;
                                return SearchInTextService.findInText(text, input);
                            });
                        };
                    }
                });

                function updateInternalTags() {
                    if (scope.trackedBy) {
                        if (!scope.tags || scope.tags.length == 0) scope.internalTags = [];
                        if (scope.allItems) {
                            scope.internalTags = _.filter(scope.allItems, function (tag) {
                                var id = tag[scope.trackedBy];
                                return scope.tags.indexOf(id) !== -1;
                            });
                        } else if (scope.loadTagFunction) {
                            var tmpTags = scope.internalTags;
                            scope.internalTags = [];
                            _.forEach(scope.tags, function (tagId) {
                                var maybeTag = _.filter(tmpTags, function (tmpTag) {
                                    return tmpTag[scope.trackedBy] == tagId;
                                });
                                if (maybeTag.length == 1) {
                                    scope.internalTags.push(maybeTag[0]);
                                    return;
                                }
                                var placeholderTag = {};
                                placeholderTag[scope.trackedBy] = tagId;
                                scope.internalTags.push(placeholderTag);
                                scope.loadTagFunction(tagId).then(function (response) {
                                    _.extend(placeholderTag, response.data);
                                });
                            });
                        }
                    } else {
                        scope.internalTags = scope.tags;
                    }
                }

                scope.$watch('allItems', updateInternalTags);
                scope.$watch('tags', updateInternalTags);
            }
        };
    }]);
})(angular);