angular.module('app').service('CourseStatisticsService', ["restUrlPublicPrefix", "$http", "AddressBuild", function (restUrlPublicPrefix, $http, AddressBuild) {
    function getMyCourseStatistics(semester) {
        var url = restUrlPublicPrefix + 'courses-statistics/my' + AddressBuild.parameters([['semester', semester]]);

        return $http.get(url);
    }

    function getSingleCourseStatistics(courseCode, semester, purge) {
        var url = restUrlPublicPrefix + 'courses/' + courseCode + '/statistics' + AddressBuild.parameters([['semester', semester], ['purge', purge]]);

        return $http.get(url);
    }

    function processSingleCourseStats(singleCourseStats, result) {
        var courseCode = singleCourseStats.courseCode;
        result[courseCode] = {};
        var currentCourseRes = result[courseCode];
        currentCourseRes.courseCode = courseCode;
        currentCourseRes.noErrors = true;

        // it's arrays for easier manipulation in the component
        var gradeCountPair = [];
        singleCourseStats.grades.forEach(function (grade) {
            var curValue = grade.value === null ? 'N' : grade.value;
            gradeCountPair.push([curValue, grade.count]);
        });
        gradeCountPair.sort(function (pair1, pair2) {
            return (pair1[0] > pair2[0]) - (pair1[0] < pair2[0]);
        });
        currentCourseRes.gradeLabels = [];
        currentCourseRes.gradeCounts = [];
        gradeCountPair.forEach(function (pair) {
            currentCourseRes.gradeLabels.push(pair[0]);
            currentCourseRes.gradeCounts.push(pair[1]);
        });

        currentCourseRes.stats = {};
        singleCourseStats.statisticalData.forEach(function (stats) {
            currentCourseRes.stats[stats.name] = {};
            currentCourseRes.stats[stats.name].value = stats.value;
            currentCourseRes.stats[stats.name].error = stats.error;
            if (stats.error !== null) {
                currentCourseRes.noErrors = false;
            }
        });
    }

    return {
        getMyCourseStatistics: getMyCourseStatistics,
        getSingleCourseStatistics: getSingleCourseStatistics,
        processSingleCourseStats: processSingleCourseStats
    };
}]);