(function (angular) {
    angular.module("app").controller("MyClassificationCtrl", ["$scope", "$route", "myClassification", "username", "ClassificationService", "SecurityService", "WebsocketService", "MyClassificationService", "TranslateService", "courseInformation", "$location", "MarkService", "AddressBuild", function ($scope, $route, myClassification, username, ClassificationService, SecurityService, WebsocketService, MyClassificationService, TranslateService, courseInformation, $location, MarkService, AddressBuild) {
        var loggedInUsername = SecurityService.getUserInfo().username;
        var courseCode = $route.current.params.courseCode;

        $scope.courseCode = courseCode;
        $scope.courseName = courseInformation.courseName;
        $scope.availableClassificationTypes = ClassificationService.getClassificationTypes();
        $scope.myClassification = myClassification;
        $scope.isMyClassification = loggedInUsername === username; // if logged in user is owner of viewed classification of subject
        $scope.username = username;

        $scope.classificationHierarchy = ClassificationService.buildClassificationHierarchy($scope.myClassification.studentClassificationFullDtos);

        $scope.isMark = MarkService.isMark;

        //Handles return from view as student
        var viewParam = null;
        var param = $location.search().view;
        viewParam = param === undefined ? null : param;
        $scope.showTeacherButtons = viewParam !== null;
        $scope.returnToDefinitionLink = AddressBuild.addSemester("courses/" + $scope.courseCode + "/classification/" + viewParam);
        $scope.editStudentClassificationLink = "courses/" + $scope.courseCode + "/student-classification/" + AddressBuild.parameters([['semester', SecurityService.getRawSemester().override], ['username', username]]);

        var reloadData = function () {
            var lang = TranslateService.getLanguage();
            MyClassificationService.getStudentClassification(SecurityService.getSemester(), courseCode, username, lang, false).then(function (response) {
                $scope.myClassification = response.data;
                $scope.classificationHierarchy = ClassificationService.buildClassificationHierarchy($scope.myClassification);
            });
        };

        WebsocketService.handleScopeSubscription($scope, '/user/queue/' + SecurityService.getSemester() + '/' + courseCode + '/classification', function (payload) {
            var changedClassification = $scope.myClassification.studentClassificationFullDtos.find(function (classification) {
                return classification.identifier === payload.classificationIdentifier;
            });
            changedClassification.value = payload.value;
            changedClassification.note = payload.note;
            changedClassification.warning = true;
            $scope.$apply();
        }, reloadData);

        var setShowChildren = function (classification) {
            var key = {};
            for (var i = 0; i < classification.length; i++) {
                key.identifier = classification[i].identifier;
                key.courseCode = classification[i].courseCode;
                key.semesterCode = classification[i].semesterCode;

                classification[i].showChildren = localStorage.getItem(JSON.stringify(key)) === "true";
            }
            return classification;
        };

        $scope.minMaxValues = function (classification) {
            var minMax = {
                text: "",
                tooltip: ""
            };

            var minText = TranslateService.translate('MIN_REQUIRED_VALUE') + ": " + classification.minimumRequiredValue;
            var maxText = TranslateService.translate('MAX_VALUE') + ": " + classification.maximumValue;

            if (classification.minimumRequiredValue !== null) {
                minMax.text += classification.minimumRequiredValue + " ≤ ";
                minMax.tooltip += minText;
            }
            if (classification.minimumRequiredValue !== null || classification.maximumValue !== null) {
                minMax.text += "X";
            }
            if (classification.minimumRequiredValue !== null && classification.maximumValue !== null) {
                minMax.tooltip += ", ";
            }
            if (classification.maximumValue !== null) {
                minMax.text += " ≤ " + classification.maximumValue;
                minMax.tooltip += maxText;
            }

            return minMax;
        };

        setShowChildren($scope.myClassification.studentClassificationFullDtos);

        $scope.handleShowChildren = function (classification) {
            classification.showChildren = !classification.showChildren;
            var key = {
                identifier: classification.identifier,
                courseCode: classification.courseCode,
                semesterCode: classification.semesterCode
            };
            var keyString = JSON.stringify(key);
            var valueString = classification.showChildren.toString();
            localStorage.setItem(keyString, valueString);
        };

        $scope.getClassificationExtraClass = function (classification) {
            //unused for now
            var extraClass = '';
            if (classification.value === null || classification.value === '') {
                extraClass += ' unrated';
                return extraClass;
            } else {
                extraClass += ' rated';
            }
            if (classification.valueType === 'BOOLEAN' && classification.value !== null && classification.value !== '') {
                if (classification.value) {
                    extraClass += ' positive';
                } else {
                    extraClass += ' negative';
                }
            }
            return extraClass;
        };

        $scope.columnHasChildren = function (classificationId) {
            return $scope.classificationHierarchy[classificationId].length > 0 && classificationId !== null;
        };
    }]);
})(angular);