(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        var courseInformationResolveFunction = function (CourseService, $route, TranslateService, SecurityService) {
            var courseCode = $route.current.params.courseCode;
            var semesterCode = SecurityService.getSemester();
            return CourseService.loadCourseInformation(courseCode, semesterCode, TranslateService.getLanguage()).then(function (response) {
                return response.data;
            });
        };
        courseInformationResolveFunction.$inject = ["CourseService", "$route", "TranslateService", "SecurityService"];
        $routeProvider.when('/courses/:courseCode/my-classification', {
            templateUrl: 'classification/pages/student/MyClassification/MyClassification.tmpl.html',
            controller: 'MyClassificationCtrl',
            resolve: {
                myClassification: ["MyClassificationService", "SecurityService", "$route", "TranslateService", function (MyClassificationService, SecurityService, $route, TranslateService) {
                    var username = SecurityService.getUsername();
                    var semesterCode = SecurityService.getSemester();
                    var courseCode = $route.current.params.courseCode;
                    var lang = TranslateService.getLanguage();
                    return MyClassificationService.getStudentClassification(semesterCode, courseCode, username, lang, false).then(function (response) {
                        return response.data;
                    });
                }],
                username: ["SecurityService", function (SecurityService) {
                    return SecurityService.getUsername();
                }],
                courseInformation: courseInformationResolveFunction
            }
        });
        $routeProvider.when('/courses/:courseCode/students/:studentsUsername', {
            templateUrl: 'classification/pages/student/MyClassification/MyClassification.tmpl.html',
            controller: 'MyClassificationCtrl',
            resolve: {
                myClassification: ["MyClassificationService", "SecurityService", "$route", "TranslateService", function (MyClassificationService, SecurityService, $route, TranslateService) {
                    var username = $route.current.params.studentsUsername;
                    var semesterCode = SecurityService.getSemester();
                    var courseCode = $route.current.params.courseCode;
                    var lang = TranslateService.getLanguage();
                    return MyClassificationService.getStudentClassification(semesterCode, courseCode, username, lang, false).then(function (response) {
                        return response.data;
                    });
                }],
                username: ["$route", function ($route) {
                    return $route.current.params.studentsUsername;
                }],
                courseInformation: courseInformationResolveFunction
            }
        });
        $routeProvider.when('/courses/:courseCode/my-classification/latest', {
            templateUrl: 'classification/pages/student/MyClassification/MyClassification.tmpl.html',
            controller: 'MyClassificationCtrl',
            resolve: {
                myClassification: ["MyClassificationService", "SecurityService", "$route", "TranslateService", function (MyClassificationService, SecurityService, $route, TranslateService) {
                    var username = SecurityService.getUsername();
                    var courseCode = $route.current.params.courseCode;
                    var lang = TranslateService.getLanguage();
                    return MyClassificationService.getLatestStudentClassification(courseCode, username, lang, false).then(function (response) {
                        return response.data;
                    });
                }],
                username: ["SecurityService", function (SecurityService) {
                    return SecurityService.getUsername();
                }],
                courseInformation: courseInformationResolveFunction
            }
        });
    }]);
})(angular);