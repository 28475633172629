(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/course-overview', {
            templateUrl: 'classification/pages/advancedTools/CoursesOverview/CoursesOverview.tmpl.html',
            controller: 'CoursesOverviewCtrl',
            resolve: {
                courses: ["CoursesOverviewService", function (CoursesOverviewService) {
                    return CoursesOverviewService.getCourses().then(function (value) {
                        return value.data;
                    });
                }]
            }
        });
    }]);
})(angular);