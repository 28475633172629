(function (angular) {
    angular.module("app").controller("CourseClassificationDetailCtrl", ["$rootScope", "$scope", "$route", "SecurityService", "DialogService", "TranslateService", "studentGroupDtos", "classificationsDefinitions", "ClassificationService", "CsvService", "DownloadUploadService", "$translate", "$timeout", "$location", "courseRoles", "courseInformation", "AddressBuild", "smartTableStringColumnLimit", function ($rootScope, $scope, $route, SecurityService, DialogService, TranslateService, studentGroupDtos, classificationsDefinitions, ClassificationService, CsvService, DownloadUploadService, $translate, $timeout, $location, courseRoles, courseInformation, AddressBuild, smartTableStringColumnLimit) {
        $scope.courseCode = $route.current.params.courseCode;
        $scope.courseName = courseInformation.courseName;
        $scope.canEditCourse = courseRoles.canEditCourse();
        $scope.smartTableStringColumnLimit = smartTableStringColumnLimit;

        var classificationIdentifier = $route.current.params.id;
        var semesterCode = SecurityService.getSemester();
        var group;

        $scope.classificationUrl = AddressBuild.addSemester('courses/' + $scope.courseCode + '/classification');
        $scope.classificationDefinitionUrl = AddressBuild.addSemester('courses/' + $scope.courseCode + '/classification/definition');

        $scope.labels = [{
            text: $translate.instant("FULLNAME"),
            type: 'OTHER',
            shown: true,
            key: "fullName",
            fixed: true
        }, {
            text: $translate.instant("USERNAME"),
            shown: true,
            key: "studentUsername",
            fixed: true
        }, {
            text: $translate.instant("EVALUATION"),
            shown: true,
            key: "value",
            fixed: true,
            input: undefined
        }, {
            text: $translate.instant("CLASSIFICATION_NOTE"),
            shown: true,
            key: "note",
            fixed: true,
            input: "textarea"
        }];

        for (var i = 0; i < studentGroupDtos.length; i++) {
            var studentGroupDto = studentGroupDtos[i];
            studentGroupDto.name = TranslateService.translateParts(studentGroupDto.name);
        }
        var reloadOriginalDataMap = function () {
            $scope.originalDataMap = {};
            $scope.data.forEach(function (classification) {
                $scope.originalDataMap[classification.studentUsername] = {
                    value: classification.value,
                    note: classification.note ? classification.note : ''
                };
            });
        };
        $scope.groupSelection = {
            selected: studentGroupDtos[0].studentGroupId,
            groups: studentGroupDtos
        };

        $scope.availableClassifications = [];
        classificationsDefinitions.forEach(function (definition) {
            if (definition.evaluationType == "MANUAL") {
                $scope.availableClassifications.push(definition);
            }
        });
        $scope.availableClassifications.forEach(function (classification) {
            if (classification.identifier === classificationIdentifier) {
                $scope.selectedClassification = classification;
            }
        });

        $scope.changeGroup = function () {
            group = $scope.groupSelection.selected;
            // todo send this request ASAP to speed up page load (we might know group from cookies, otherwise 'ALL')
            loadData();
        };
        $scope.changeGroup();

        $scope.selectClassification = function (classification) {
            $scope.selectedClassification = classification;
            $location.search('id', classification.identifier);
            // todo insert identifier into url
            loadData();
        };

        function loadData() {
            var classification = $scope.selectedClassification;
            if (!classification) {
                return;
            }
            $scope.data = null;

            ClassificationService.getStudentClassification(semesterCode, $scope.courseCode, group, classification.identifier).then(function (res) {
                for (i = 0; i < res.data.length; i++) {
                    res.data[i].fullName = res.data[i].lastName + " " + res.data[i].firstName;
                    res.data[i].i = i; // for keyboard cursor focus
                }
                res.data.sort(function (x, y) {
                    return x.fullName.localeCompare(y.fullName);
                });
                $scope.data = res.data;

                $scope.labels[2].input = ClassificationService.getInputFieldType($scope.selectedClassification);
                $scope.labels[2].allowedValues = $scope.selectedClassification.allowedStringValues;
                reloadOriginalDataMap();
            })["catch"](function (error) {
                DialogService.sendNegativeNotification('ERROR_WHILE_LOADING_CLASSIFICATION');
            });
        }

        var doSave = function () {
            $scope.confirmDialogData.show = false;
            var save = [];
            var loadingDialog = DialogService.createLoadingDialog('SAVING');
            $scope.data.forEach(function (studentClassification) {
                var originalData = $scope.originalDataMap[studentClassification.studentUsername];
                if ($scope.selectedClassification.valueType == 'STRING' && studentClassification.value == '') {
                    studentClassification.value = null;
                }
                if (studentClassification.note == null) {
                    studentClassification.note = '';
                }
                if (originalData.value != studentClassification.value || originalData.note != studentClassification.note) {
                    save.push({
                        studentUsername: studentClassification.studentUsername,
                        classificationIdentifier: $scope.selectedClassification.identifier,
                        value: studentClassification.value,
                        note: studentClassification.note
                    });
                }
            });
            if (save.length === 0) {
                DialogService.hideDialog(loadingDialog);
                DialogService.sendNeutralNotification("NO_CHANGES_WERE_MADE");
            } else {
                var notify = $scope.confirmDialogData.value;
                ClassificationService.saveStudentClassifications($scope.courseCode, semesterCode, save, notify).success(function (response) {
                    DialogService.hideDialog(loadingDialog);
                    reloadOriginalDataMap();
                    DialogService.sendPositiveNotification($rootScope.isApril ? "APRIL_A_TO_ALL_STUDENTS" : "CHANGES_SUCCESSFULLY_SAVED");
                })["catch"](function (error) {
                    DialogService.hideDialog(loadingDialog);
                });
            }
        };

        $scope.confirmDialogAction = function () {
            doSave();
        };

        $scope.confirmDialogData = {
            value: true,
            show: false
        };
        $scope.save = function () {
            $scope.confirmDialogData.value = true;
            $scope.confirmDialogData.show = true;
        };
    }]);
})(angular);