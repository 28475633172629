(function (angular) {
    angular.module('app').directive('columnEditor', ["$window", "$rootScope", "$translate", "DialogService", "TranslateService", "ExpressionService", "ClassificationService", "EvolutionTeamService", "CourseSkillService", function ($window, $rootScope, $translate, DialogService, TranslateService, ExpressionService, ClassificationService, EvolutionTeamService, CourseSkillService) {
        return {
            restrict: 'E',
            replace: false,
            transclude: true,
            scope: {
                column: '=',
                submitFunction: '=',
                columns: '=',
                resetFunction: '=?',
                availableVariables: '=?',
                parentColumn: '=?',
                classesType: '=?'
            },
            templateUrl: 'classification/directive/columnEditor/columnEditor.tmpl.html',
            //TODO remove available variables (use columns instead)
            link: function (scope, elem, attrs) {

                scope.formName = attrs.formName;
                scope.editMode = attrs.hasOwnProperty('editMode');

                scope.availableLanguages = TranslateService.getLanguages();
                scope.availableDataTypes = ExpressionService.getDataTypes();
                ExpressionService.getAggregationFunctions().then(function (response) {
                    scope.aggregationFunctions = response.data;
                });

                scope.availableClassificationTypes = ClassificationService.getClassificationTypes();
                scope.errorMessageOnDatatypeNumber = "";
                scope.semesterBasicTypes = [];
                scope.semesterExtendedTypes = [];
                scope.examsTypes = [];
                scope.otherTypes = [];

                scope.series = false;
                scope.seriesStart = 1;
                scope.seriesEnd = 2;
                scope.initialIdentifier = scope.column.identifier;

                scope.hasClassType = function (classTypeString) {
                    if (!scope.classesType) return false;

                    return scope.classesType.indexOf(classTypeString) !== -1;
                };

                //Runs new column through validation
                scope.$watch('column', function (newValue, oldValue) {
                    if (newValue && newValue !== oldValue) {
                        scope.validateIdentifier();
                    }
                });

                //handles form autofilling on new classificationType selection
                scope.$watch('column.classificationType', function (newType, oldType) {
                    if (!newType || newType === oldType) {
                        return;
                    }

                    //Resets warnings on type change
                    if (oldType !== undefined) {
                        //preserves warnings in copied column
                        scope.column.warning = {};
                    }

                    autofillIdentifierAndNames(newType, oldType);

                    /* Assigns recommended values such as "valueType", "hidden" etc.*/
                    if (!scope.column.hasOwnProperty("id") && !scope.column.isCopy) {
                        Object.assign(scope.column, ClassificationService.getRecommendedValuesOfClassificationType(newType));

                        scope.column.classificationType = newType;
                    }
                });

                function autofillIdentifierAndNames(newType, oldType) {
                    //================identifier section==================
                    //identifier is empty -> fill it with new value
                    if (!scope.column.identifier) {
                        scope.column.identifier = scope.availableClassificationTypes[newType].identifier;
                    } else if (oldType) {
                        //identifier is prefilled -> fill it with new value
                        if (scope.column.identifier === scope.availableClassificationTypes[oldType].identifier) {
                            scope.column.identifier = scope.availableClassificationTypes[newType].identifier;
                        } else {
                            //identifier has custom value -> dont change it
                        }
                    }
                    //new identifier must be checked for possible duplicities
                    scope.validateIdentifier();

                    //==============column names section==================
                    if (columnNamesEmpty(scope.column)) {
                        //names are empty-> fill them with new values
                        autofillNames(scope.column);
                    } else {
                        if (oldType) {
                            if (oldNamesAutofilled(oldType)) {
                                //names are autofilled -> fill them with new values
                                autofillNames(scope.column);
                            } else {
                                //names have custom values -> dont change them
                            }
                        }
                    }
                }

                //Checks whether any name matches names of the previous classificationType
                function oldNamesAutofilled(oldType) {
                    var texts = scope.column.classificationTextDtos;
                    for (var key in texts) {
                        if (texts.hasOwnProperty(key) && texts[key].identifier === "cs") {
                            if (texts[key].name === scope.availableClassificationTypes[oldType]["cs"]) {
                                return true;
                            }
                        } else if (texts.hasOwnProperty(key) && texts[key].identifier === "en") {
                            if (texts[key].name === scope.availableClassificationTypes[oldType]["en"]) {
                                return true;
                            }
                        }
                    }
                    return false;
                }

                scope.showWarning = function (columnPart, selectedValue) {
                    switch (columnPart) {
                        case "hidden": //fallthrough
                        case "calculated": //fallthrough
                        case "valueType":
                            {
                                if (scope.column.warning && scope.column.warning[columnPart]) {
                                    var recommendedValue = scope.column.warning[columnPart].value;
                                    var currentValue = scope.column[columnPart];

                                    return currentValue !== recommendedValue;
                                }
                                break;
                            }
                        case "expression":
                            {
                                if (scope.column.warning && scope.column.warning[columnPart]) {
                                    var regex = scope.column.warning[columnPart].value;
                                    var expression = scope.column[columnPart];

                                    return expression.search(regex) === -1;
                                }
                                break;
                            }
                    }
                };

                var getNameInLang = function (classType, lang) {
                    if (classType) {
                        var curr = scope.availableClassificationTypes[classType];
                        return curr[lang];
                    }
                };

                function columnNamesEmpty(column) {
                    var texts = column.classificationTextDtos;
                    for (var key in texts) {
                        if (texts.hasOwnProperty(key) && texts[key].name) {
                            return false;
                        }
                    }
                    return true;
                }

                function autofillNames(column) {
                    var texts = column.classificationTextDtos;
                    for (var key in texts) {
                        if (texts.hasOwnProperty(key) && texts[key].identifier == "cs") {
                            texts[key].name = getNameInLang(column.classificationType, "cs");
                        } else if (texts.hasOwnProperty(key) && texts[key].identifier == "en") {
                            texts[key].name = getNameInLang(column.classificationType, "en");
                        }
                    }
                }

                //divides classification types into groups
                function groupClassificationTypes() {
                    for (var x in scope.availableClassificationTypes) {
                        var current = scope.availableClassificationTypes[x];
                        if (current["group"] === "basic") {
                            scope.semesterBasicTypes.push(current);
                        } else if (current["group"] === "extended") {
                            scope.semesterExtendedTypes.push(current);
                        } else if (current["group"] === "exams") {
                            scope.examsTypes.push(current);
                        } else {
                            scope.otherTypes.push(current);
                        }
                    }
                }

                groupClassificationTypes();

                scope.aggregationFunctionFilter = function (column) {
                    var aggregatedColumn = scope.columns.find(function (o) {
                        return o.identifier === column.aggregatedIdentifier;
                    });
                    return function (item) {
                        if (!aggregatedColumn) return false;
                        return item.argumentsType === aggregatedColumn.valueType && column.valueType === item.returnType;
                    };
                };

                scope.submit = function (form, clearForm) {
                    if (!scope.validate(form)) return;

                    if (!scope.series) {
                        doSave(clearForm);
                    } else {
                        var n = scope.seriesEnd - scope.seriesStart + 1;
                        DialogService.createConfirmDialog('CREATE_SERIES_OF_COLUMNS', $translate.instant('DO_YOU_REALLY_WANT_TO_CREATE_N_COLUMNS', { n: n }), clearForm ? doSaveAndClearForm : doSaveWithoutClearForm, 'YES', 'NO');
                    }

                    form.$setUntouched();
                    form.$setPristine();
                };

                scope.validate = function (form) {
                    if (!form.$valid || scope.column.identifierInvalid) {
                        DialogService.sendNegativeNotification("YOU_HAVE_TO_FILL_VALID_VALUE_INTO_RED_BORDERED_FIELDS");
                        return;
                    } else if (!scope.column.identifierUnique) {
                        DialogService.sendNegativeNotification("IDENTIFIER_MUST_BE_UNIQUE");
                        form.$valid = false;
                    } else if (scope.series && scope.seriesEnd - scope.seriesStart > 30) {
                        DialogService.sendNegativeNotification("ONLY_N_COLUMNS_CAN_BE_CREATED_BY_SERIES");
                        form.$valid = false;
                    } else if (scope.invalidMinMax()) {
                        DialogService.sendNegativeNotification(scope.errorMessageOnDatatypeNumber);
                        form.$valid = false;
                    }
                    return form.$valid;
                };

                scope.textFilled = function () {
                    for (var key in scope.column.classificationTextDtos) {
                        if (scope.column.classificationTextDtos.hasOwnProperty(key)) {
                            if (scope.column.classificationTextDtos[key].name) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                scope.getColumnText = function (column, language) {
                    var texts = column.classificationTextDtos;
                    for (var key in texts) {
                        if (texts.hasOwnProperty(key) && texts[key].identifier == language.identifier) {
                            return texts[key];
                        }
                    }
                    return null;
                };

                scope.validateIdentifier = function () {
                    scope.column.identifierInvalid = false;
                    scope.column.identifierUnique = true;
                    scope.invalidIdentifierNote = null;
                    if (!scope.column.identifier) {
                        return;
                    }

                    var identifiers = {};
                    var keyWords = ["TRUE", "FALSE", "NULL"];
                    if (scope.series && scope.seriesStart && scope.seriesEnd) {
                        identifiers = createSeriesPreview().identifiers;
                    } else {
                        scope.seriesPreview = {};
                        identifiers[0] = scope.column.identifier;
                    }
                    for (var identifierKey in identifiers) {
                        if (!identifiers.hasOwnProperty(identifierKey)) {
                            continue;
                        }
                        var identifier = identifiers[identifierKey];
                        if (!identifier.match(/^[_a-zA-Z][_a-zA-Z0-9]*$/)) {
                            scope.column.identifierInvalid = true;
                            scope.invalidIdentifierNote = $translate.instant('INVALID_FORM_OF_IDENTIFIER');
                            return;
                        }
                        for (var variable in scope.availableVariables) {
                            if (!scope.availableVariables.hasOwnProperty(variable)) {
                                continue;
                            }
                            if ((!scope.initialIdentifier || identifier.toLowerCase() !== scope.initialIdentifier.toLowerCase()) && variable.toLowerCase() === identifier.toLowerCase()) {
                                scope.column.identifierUnique = false;
                                scope.invalidIdentifierNote = $translate.instant('COLUMN_WITH_IDENTIFIER_EXISTS', { identifier: identifier });
                                return;
                            }
                        }
                        for (var keyWordKey in keyWords) {
                            if (!keyWords.hasOwnProperty(keyWordKey)) {
                                continue;
                            }
                            if (identifier.toLowerCase() == keyWords[keyWordKey].toLowerCase()) {
                                scope.column.identifierInvalid = true;
                                scope.invalidIdentifierNote = $translate.instant('WORD_IS_RESERVED_KEYWORD', { identifier: identifier });
                                return;
                            }
                        }
                    }
                };

                scope.invalidMinMax = function () {
                    if (scope.column.minimumRequiredValue !== undefined && scope.column.minimumRequiredValue != null && scope.column.minimumRequiredValue < scope.column.minimumValue) {
                        scope.errorMessageOnDatatypeNumber = "MINIMUM_CANNOT_BE_LARGER_THAN_MINIMUM_REQUIRED";
                        return true;
                    }
                    if (scope.column.maximumValue !== undefined && scope.column.maximumValue != null && scope.column.minimumRequiredValue !== undefined && scope.column.minimumRequiredValue != null && scope.column.maximumValue < scope.column.minimumRequiredValue) {
                        scope.errorMessageOnDatatypeNumber = "MINIMUM_REQUIRED_CANNOT_BE_LARGER_THAN_MAXIMUM";
                        return true;
                    }
                    if (scope.column.maximumValue !== undefined && scope.column.maximumValue != null && scope.column.minimumValue !== undefined && scope.column.minimumValue != null && scope.column.maximumValue < scope.column.minimumValue) {
                        scope.errorMessageOnDatatypeNumber = "MINIMUM_CANNOT_BE_LARGER_THAN_MAXIMUM";
                        return true;
                    }
                    scope.errorMessageOnDatatypeNumber = "";
                    return false;
                };

                scope.getSkills = function (query) {
                    return CourseSkillService.autocompleteSkills(query).then(function (response) {
                        return response.data;
                    });
                };

                function createSeriesPreview() {
                    scope.seriesPreview = {};
                    scope.seriesPreview.identifiers = {};
                    scope.seriesPreview.identifiersString = "";

                    var i;
                    var identifier;
                    if (scope.seriesEnd < 10) {
                        for (i = scope.seriesStart; i <= scope.seriesEnd; ++i) {
                            identifier = scope.column.identifier + i;
                            scope.seriesPreview.identifiers[i] = identifier;
                            if (i < scope.seriesEnd) {
                                scope.seriesPreview.identifiersString += ', ';
                            }
                        }
                    } else {
                        for (i = scope.seriesStart; i <= scope.seriesEnd; ++i) {
                            if (i < 10) {
                                identifier = scope.column.identifier + "0" + i;
                                scope.seriesPreview.identifiers[i] = identifier;
                                if (i < scope.seriesEnd) {
                                    scope.seriesPreview.identifiersString += ', ';
                                }
                            } else {
                                identifier = scope.column.identifier + i;
                                scope.seriesPreview.identifiers[i] = identifier;
                                if (i < scope.seriesEnd) {
                                    scope.seriesPreview.identifiersString += ', ';
                                }
                            }
                        }
                    }
                    return scope.seriesPreview;
                }

                function createColumnText(column, language) {
                    var text = scope.getColumnText(column, language);
                    if (text) return text;
                    var texts = column.classificationTextDtos;
                    text = { identifier: language.identifier };
                    texts.push(text);
                    return text;
                }

                function createMissingLanguages() {
                    for (var key in scope.availableLanguages) {
                        if (scope.availableLanguages.hasOwnProperty(key)) {
                            createColumnText(scope.column, scope.availableLanguages[key]);
                        }
                    }
                }

                function doSaveAndClearForm() {
                    doSave(true);
                }

                function doSaveWithoutClearForm() {
                    doSave(false);
                }

                function doSave(clearForm) {
                    scope.column.editMode = false;
                    var column = EvolutionTeamService.copyObject(scope.column);
                    var identifier = column.identifier;
                    var columnsToSave = [];
                    var texts = column.classificationTextDtos;
                    var textsFilled = [];
                    for (var key in texts) {
                        if (texts.hasOwnProperty(key) && texts[key].name) {
                            textsFilled.push(texts[key]);
                        }
                    }
                    column.classificationTextDtos = textsFilled;

                    if (column.evaluationType != 'EXPRESSION') {
                        column.expression = null;
                    }
                    if (column.evaluationType == 'EXPRESSION' && !column.expression) {
                        column.expression = "";
                    }

                    if (column.evaluationType != 'AGGREGATION') {
                        column.aggregatedIdentifier = null;
                        column.aggregationFunction = null;
                        column.aggregationScope = null;
                    }

                    if (column.valueType !== scope.availableDataTypes.NUMBER.identifier) {
                        column.minimumRequiredValue = null;
                        column.maximumValue = null;
                    }

                    if (scope.series) {
                        column.evaluationType = 'MANUAL';
                        column.expression = null;
                        for (var i = scope.seriesStart; i <= scope.seriesEnd; i++) {
                            var columnCopy = EvolutionTeamService.copyObject(column);
                            var textCopy = EvolutionTeamService.copyObject(columnCopy.classificationTextDtos);
                            for (var j in textCopy) {
                                if (textCopy.hasOwnProperty(j)) {
                                    textCopy[j].name += ' ' + i;
                                }
                            }
                            columnCopy.identifier = scope.seriesPreview.identifiers[i];
                            columnCopy.classificationTextDtos = textCopy;
                            columnsToSave.push(columnCopy);
                        }
                    } else {
                        columnsToSave.push(column);
                    }

                    scope.column.identifierInvalid = false;
                    scope.invalidIdentifierNote = null;
                    scope.seriesPreview = {};

                    scope.submitFunction(columnsToSave, scope.parentColumn, clearForm);
                }

                createMissingLanguages();
                scope.$watch('column.classificationTextDtos', function () {
                    createMissingLanguages();
                });

                scope.$watch('series', function () {
                    scope.validateIdentifier();
                });
            }
        };
    }]);
})(angular);