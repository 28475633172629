(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        var courseInformationResolveFunction = function (CourseService, $route, TranslateService, SecurityService) {
            var courseCode = $route.current.params.courseCode;
            var semesterCode = SecurityService.getSemester();
            return CourseService.loadCourseInformation(courseCode, semesterCode, TranslateService.getLanguage()).then(function (response) {
                return response.data;
            });
        };
        courseInformationResolveFunction.$inject = ["CourseService", "$route", "TranslateService", "SecurityService"];
        var courseRolesResolveFunction = function (SecurityService, $route) {
            var courseCode = $route.current.params.courseCode;
            return SecurityService.getCourseRoles(courseCode).then(function (response) {
                return response;
            });
        };
        courseRolesResolveFunction.$inject = ["SecurityService", "$route"];
        var classificationsDefinitionsResolveFunction = function (ClassificationService, $route, TranslateService, SecurityService) {
            var courseCode = $route.current.params.courseCode;
            var semesterCode = SecurityService.getSemester();
            return ClassificationService.loadClassificationColumns(semesterCode, courseCode, TranslateService.getLanguage()).then(function (response) {
                return ClassificationService.buildPostfixClassificationDefinitions(response.data);
            });
        };
        classificationsDefinitionsResolveFunction.$inject = ["ClassificationService", "$route", "TranslateService", "SecurityService"];
        var studentGroupDtosResolveFunction = function (ClassificationService, $route, TranslateService, SecurityService) {
            var courseCode = $route.current.params.courseCode;
            var semesterCode = SecurityService.getSemester();
            var lang = TranslateService.getLanguage();
            // TODO: is getting username correct?
            return ClassificationService.getStudentGroups(semesterCode, courseCode, lang, SecurityService.getUsername()).then(function (response) {
                return response.data;
            });
        };
        studentGroupDtosResolveFunction.$inject = ["ClassificationService", "$route", "TranslateService", "SecurityService"];
        var classificationsDefinitionsBothLanguagesResolveFunction = function (ClassificationService, $rootScope, $route, SecurityService) {
            var courseCode = $route.current.params.courseCode;
            var semesterCode = SecurityService.getSemester();
            return ClassificationService.loadClassificationColumns(semesterCode, courseCode, null).then(function (response) {
                return response.data;
            });
        };
        classificationsDefinitionsBothLanguagesResolveFunction.$inject = ["ClassificationService", "$rootScope", "$route", "SecurityService"];
        $routeProvider.when('/courses/:courseCode/classification', {
            templateUrl: 'classification/pages/teacher/CourseClassification/CourseClassification.tmpl.html',
            controller: 'CourseClassificationCtrl',
            reloadOnSearch: false,
            resolve: {
                studentGroupDtos: studentGroupDtosResolveFunction,
                classificationsDefinitions: classificationsDefinitionsResolveFunction,
                courseRoles: courseRolesResolveFunction,
                courseInformation: courseInformationResolveFunction
            }
        }).when('/courses/:courseCode/classification-detail', {
            templateUrl: 'classification/pages/teacher/CourseClassification/CourseClassificationDetail.tmpl.html',
            controller: 'CourseClassificationDetailCtrl',
            resolve: {
                studentGroupDtos: studentGroupDtosResolveFunction,
                classificationsDefinitions: classificationsDefinitionsResolveFunction,
                courseRoles: courseRolesResolveFunction,
                courseInformation: courseInformationResolveFunction
            },
            reloadOnSearch: false
        }).when('/courses/:courseCode/classification/definition', {
            templateUrl: 'classification/pages/teacher/CourseClassification/CourseClassificationDefinition.tmpl.html',
            controller: 'CourseClassificationDefinitionCtrl',
            resolve: {
                classificationsDefinitions: classificationsDefinitionsBothLanguagesResolveFunction,
                courseRoles: courseRolesResolveFunction,
                courseInformation: courseInformationResolveFunction
            }
        }).when('/courses/:courseCode/student-classification', {
            templateUrl: 'classification/pages/teacher/CourseClassification/StudentClassification.tmpl.html',
            controller: 'StudentClassificationCtrl',
            reloadOnSearch: false,
            resolve: {
                classificationsDefinitions: classificationsDefinitionsResolveFunction,
                courseRoles: courseRolesResolveFunction,
                courseInformation: courseInformationResolveFunction
            }
        }).when('/courses/:courseCode/skills', {
            templateUrl: 'classification/pages/teacher/CourseSkill/CourseSkill.tmpl.html',
            controller: 'CourseSkillCtrl',
            resolve: {
                courseSkills: ["TranslateService", "CourseSkillService", "$route", function (TranslateService, CourseSkillService, $route) {
                    var courseCode = $route.current.params.courseCode;
                    return CourseSkillService.getForCourse(courseCode).then(function (response) {
                        return response.data;
                    });
                }],
                courseInformation: courseInformationResolveFunction
            }
        });
    }]);
})(angular);