(function (angular) {
    angular.module('app').directive('smartTable', ["$window", "$rootScope", "$cookies", "smartTableStringColumnLimit", "MarkService", "TranslateService", function ($window, $rootScope, $cookies, smartTableStringColumnLimit, MarkService, TranslateService) {
        return {
            restrict: 'EA',
            replace: true,
            transclude: true,
            scope: {
                labels: '=?',
                data: '=?',
                originalData: '=?',
                loadingInProgress: '=?',
                pagination: '=?',
                searchVisible: '=?',
                filteredData: '=?',
                godDammitFixThis: '=?',
                searchExpressionData: '=?',
                displayRowNumbers: '=?',
                formName: '=?'
            },
            templateUrl: 'classification/template/smart-table.tmpl.html',
            link: function (scope, elem, attrs) {
                var mainHeader;
                var initialWrapper;
                var initialTable;
                var initialHeader;
                var headerCells;
                var fixedCells;
                scope.smartTableStringColumnLimit = smartTableStringColumnLimit;
                if (scope.godDammitFixThis === undefined) {
                    scope.godDammitFixThis = false;
                }
                if (scope.filteredData === undefined) {
                    scope.filteredData = [];
                }

                if (scope.pagination) {
                    scope.currentPage = 1;
                    scope.pageNumbers = 1;
                    scope.itemsPerPage = parseInt($cookies.get("STUDENT_CLASSIFICATION_PAGE_SIZE"));
                    if (isNaN(scope.itemsPerPage) || scope.itemsPerPage <= 0) scope.itemsPerPage = 50;
                    if (scope.showAllItems === undefined) scope.showAllItems = '';
                }
                scope.keyToSort = null;
                scope.nothingToShow = false;
                scope.searchPhrase = '';

                scope.search = function (input) {
                    scope.searchPhrase = input;
                    filter();
                };

                // checks if any data-wise non-empty labels exist
                function checkAllLabels() {
                    scope.nothingToShow = scope.godDammitFixThis;
                    if (scope.labels) {
                        scope.labels.forEach(function (label) {
                            if (label.shown) {
                                scope.nothingToShow = false;
                            }
                        });
                    }
                    angular.element(document).ready(function () {
                        $rootScope.$broadcast('elementUpdated');
                    });

                    scope.nothingToShow = scope.dataSlice && scope.dataSlice.length === 0 || scope.nothingToShow;
                }

                scope.$watch('labels', function () {
                    checkAllLabels();
                }, true);

                scope.$watch('data', function () {
                    if (scope.data) {
                        filter();
                    }
                    angular.element(document).ready(function () {
                        updateTableWidth();
                        $rootScope.$broadcast('elementUpdated');
                    });
                });

                scope.showExpressionEditor = false;
                //BUG - whisperer shown
                //    - todo in 2099
                //scope.showExpressionEditor = scope.searchExpressionData.queryText && (scope.searchExpressionData.queryText != '');
                scope.toggleExpressionEditor = function () {
                    scope.showExpressionEditor = !scope.showExpressionEditor;
                };

                scope.getPageSize = function () {
                    if (scope.showAllItems) {
                        return scope.data.length;
                    } else {
                        return scope.itemsPerPage;
                    }
                };

                scope.goToPage = function (page) {
                    scope.currentPage = page;
                    scope.currentPage = Math.max(1, Math.min(scope.pageNumbers, scope.currentPage));
                    scope.dataSlice = scope.filteredData.slice(scope.getPageSize() * (scope.currentPage - 1), scope.getPageSize() * scope.currentPage);
                    var i = 0;
                    scope.dataSlice.forEach(function (item) {
                        item.i = i++;
                    });
                    recreatePages();
                };

                function filter() {
                    if (scope.searchPhrase === undefined || scope.searchPhrase === null) {
                        scope.searchPhrase = '';
                    }
                    if (scope.searchPhrase === '') {
                        scope.filteredData = scope.data;
                    } else {
                        var splitQuery = scope.searchPhrase.split(' ');
                        scope.filteredData = scope.data.filter(function (item) {
                            var matchOk = true;
                            var str = TranslateService.foldAccents(item.fullName) + '#' + TranslateService.foldAccents(item.username);
                            splitQuery.forEach(function (query) {
                                if (!matchOk) {
                                    return;
                                }
                                matchOk = str.match(new RegExp(TranslateService.foldAccents(query), "i"));
                            });
                            return matchOk;
                        });
                    }
                    sortBy();
                }

                scope.setSortKey = function (key) {
                    if (key) {
                        if (scope.keyToSort && scope.keyToSort.key === key) {
                            scope.keyToSort.sortAsc = !scope.keyToSort.sortAsc;
                        } else {
                            scope.keyToSort = { key: key, sortAsc: true };
                        }
                    }
                    sortBy();
                };

                function sortBy() {
                    if (scope.filteredData && scope.keyToSort) {
                        scope.filteredData.sort(rowComparator);
                    }
                    showLimitedItems();
                }

                scope.setPageSizeUI = false;

                scope.setPageSizeBoolean = function (b) {
                    scope.setPageSizeUI = b;
                    if (!b && scope.formName) {
                        scope.formName.$setPristine();
                    }
                };

                scope.setItemsPerPage = function (n) {
                    n = +n;
                    n = Math.floor(n);
                    if (angular.isNumber(n) && n > 0) {
                        scope.showAllItems = '';
                        scope.itemsPerPage = n;
                        $cookies.put('STUDENT_CLASSIFICATION_PAGE_SIZE', scope.itemsPerPage);
                        showLimitedItems();
                    }
                    scope.setPageSizeBoolean(false);
                };

                function pageObject(page, clickable, display) {
                    return {
                        'page': page,
                        'text': page,
                        'clickable': clickable,
                        'display': display
                    };
                }

                function displayPages() {
                    // display 1, 2
                    var briefPagingThreshold = 9;
                    var edgeRange = 0; // don't forget to change briefPagingThreshold accordingly, so it looks nice
                    var curSurroundingsRange = 2;
                    var i;
                    if (scope.pageNumbers > briefPagingThreshold) {
                        for (i = 1; i <= Math.min(1 + edgeRange, scope.pageNumbers); ++i) {
                            scope.pageArray[i].clickable = true;
                            scope.pageArray[i].display = true;
                        }
                        // display currentPage and surrounding
                        for (i = Math.max(1, scope.currentPage - curSurroundingsRange); i <= Math.min(scope.pageNumbers, scope.currentPage + curSurroundingsRange); ++i) {
                            scope.pageArray[i].clickable = true;
                            scope.pageArray[i].display = true;
                        }
                        // display last and last - 1
                        for (i = Math.max(1, scope.pageNumbers - edgeRange); i <= scope.pageNumbers; ++i) {
                            scope.pageArray[i].clickable = true;
                            scope.pageArray[i].display = true;
                        }
                        for (i = 1; i <= scope.pageNumbers; ++i) {
                            if (scope.pageArray[i - 1].clickable && scope.pageArray[i - 1].display && !scope.pageArray[i].clickable) {
                                scope.pageArray[i].text = "...";
                                scope.pageArray[i].display = true;
                            }
                        }
                    } else {
                        for (i = 1; i <= scope.pageNumbers; ++i) {
                            scope.pageArray[i].clickable = true;
                            scope.pageArray[i].display = true;
                        }
                    }
                }

                function recreatePages() {
                    scope.pageArray = [];
                    // page 0 is not used!!!
                    // because users don't number from zero like computer scientists
                    for (var i = 0; i <= scope.pageNumbers; i++) {
                        scope.pageArray.push(pageObject(i, false, false));
                    }
                    displayPages();
                }

                scope.showAllItemsFunc = function () {
                    scope.showAllItems = 'true';
                    showLimitedItems();
                    scope.setPageSizeBoolean(false);
                };

                function showLimitedItems() {
                    if (!scope.data) {
                        return;
                    }

                    if (scope.pagination) {
                        scope.pageNumbers = Math.ceil(scope.filteredData.length / scope.getPageSize());
                        recreatePages();
                        scope.goToPage(1);
                    } else {
                        scope.dataSlice = scope.filteredData;
                    }
                    checkAllLabels();
                }

                scope.pageSizeEnter = function (event, pageSize, pageSizeInputHasFocus) {
                    if (pageSizeInputHasFocus && event.keyCode === 13) {
                        //enter
                        event.preventDefault();
                        scope.setItemsPerPage(pageSize);
                    }
                };

                scope.isMark = MarkService.isMark;

                scope.canDisplayCheckbox = function (label) {
                    return label !== null && label !== '' && label.valueType === "BOOLEAN";
                };

                scope.canDisplayScore = function (row, label) {
                    return label.highlight && label.valueType === 'NUMBER' && label.type === 'FINAL_SCORE' && row[label.key] !== undefined && row[label.key] != null;
                };

                var bgColours = {
                    positive: "rgba(59, 200, 42, 0.15)",
                    negative: "rgba(245, 53, 42, 0.15)",
                    neutral: "rgba(200, 200, 200, 0.25)"
                };

                scope.getBackgroundColour = function (row, label) {
                    var backgroundOpacity = 0.35;
                    if (!label.highlight || !label.valueType) {
                        return '';
                    }
                    var value = row[label.key];
                    if (label.valueType === "BOOLEAN") {
                        if (value === true) {
                            return bgColours.positive;
                        } else if (value === false) {
                            return bgColours.negative;
                        } else {
                            return bgColours.neutral;
                        }
                    } else if (label.valueType === "NUMBER") {
                        if (value === undefined || value == null || label.maximumValue === undefined || label.maximumValue == null) {
                            return bgColours.neutral;
                        }
                        return MarkService.hsla(value, label.minimumValue, label.minimumRequiredValue, label.maximumValue, backgroundOpacity);
                    } else if (label.valueType === "STRING") {
                        if (MarkService.isMark(value)) {
                            return MarkService.hsla(value, null, null, null, backgroundOpacity);
                        }
                        return bgColours.neutral;
                    }
                };

                // highlight row which currently has cursor
                scope.focusedRow = -1;
                scope.addFocusToRow = function (i) {
                    scope.focusedRow = i;
                };
                scope.removeFocusFromRow = function (i) {
                    if (scope.focusedRow === i) {
                        scope.focusedRow = -1;
                    }
                };

                elem.ready(function () {
                    checkAllLabels();

                    mainHeader = document.getElementById('mainHeader');
                    initialWrapper = elem[0].getElementsByClassName('st-initial')[0];
                    initialTable = initialWrapper.getElementsByTagName('table')[0];
                    initialHeader = initialWrapper.getElementsByTagName('thead')[0];
                    headerCells = initialHeader.getElementsByTagName('th');
                    fixedCells = initialTable.getElementsByClassName('st-fixed');

                    angular.element($window).bind('resize', updateTableWidth);

                    angular.element(initialWrapper).bind('scroll', doScrollX);

                    angular.element($window).bind('scroll', doScrollY);

                    updateTableWidth();

                    if (attrs.sortByKey) scope.setSortKey(attrs.sortByKey);
                    filter();
                });

                /* compares two rows based on selected column stored in keyToSort
                 */
                function rowComparator(object1, object2) {
                    var sortResult = 0;
                    var keyToSort = scope.keyToSort.key;
                    var x = object1[keyToSort];
                    var y = object2[keyToSort];
                    sortResult = compare(x, y, scope.keyToSort.sortAsc);
                    return sortResult;
                }

                /* Force nulls to be last.
                 */
                function compare(x, y, ascending) {
                    var flip = ascending ? 1 : -1;
                    if (x === y) {
                        return 0;
                    } else if (x == null) {
                        return 1;
                    } else if (y == null) {
                        return -1;
                    } else if (typeof x === 'string' && typeof y === 'string') {
                        return flip * x.localeCompare(y, 'cs-CZ');
                    } else {
                        return flip * (x < y ? -1 : 1);
                    }
                }

                function doScrollX() {
                    var scrollLeft = initialWrapper.scrollLeft;
                    for (var i = 0; i < fixedCells.length; i++) {
                        var fixedCell = fixedCells[i];
                        if (fixedCell.moveX == scrollLeft) continue;
                        fixedCell.moveX = scrollLeft;
                        moveElement(fixedCell, scrollLeft, fixedCell.moveY);
                    }
                }

                function doScrollY(e) {
                    var headerHeight = mainHeader.clientHeight;
                    var scrollY = $window.scrollY;
                    if (!scrollY) scrollY = document.documentElement.scrollTop;
                    var offset = initialWrapper.offsetTop - scrollY - headerHeight;
                    if (offset > 0) {
                        offset = 0;
                    }

                    for (var i = 0; i < headerCells.length; i++) {
                        var headerCell = headerCells[i];
                        if (headerCell.moveY == -offset) continue;
                        headerCell.moveY = -offset;
                        moveElement(headerCell, headerCell.moveX, -offset);
                    }
                }

                function moveElement(element, X, Y) {
                    if (X == null) X = 0;
                    if (Y == null) Y = 0;
                    element.style.msTransform = 'translate(' + X + "px, " + Y + "px)";
                    element.style.webkitTransform = 'translate(' + X + "px, " + Y + "px)";
                    element.style.mozTransform = 'translate(' + X + "px, " + Y + "px)";
                    element.style.transform = 'translate(' + X + "px, " + Y + "px)";
                }

                function updateTableWidth() {
                    var newWidth = Math.floor($window.innerWidth - initialWrapper.getBoundingClientRect().left - 42);
                    initialWrapper.style.maxWidth = newWidth + 'px';

                    doScrollX();
                    $rootScope.$broadcast('elementUpdated');
                }
                scope.$on('windowResized', function () {
                    updateTableWidth();
                });
            }
        };
    }]);
})(angular);