(function (angular) {
    angular.module('app').config(["$translateProvider", function ($translateProvider) {

        $translateProvider.useStaticFilesLoader({
            prefix: 'lang/',
            suffix: '.json?version=1.1'
        });

        $translateProvider.preferredLanguage('cs');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escape');
    }]);
})(angular);