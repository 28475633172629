(function (angular) {
    angular.module("app").service("CourseSkillService", ["AddressBuild", "restUrlPublicPrefix", "$http", "SecurityService", function (AddressBuild, restUrlPublicPrefix, $http, SecurityService) {
        return {
            autocompleteSkills: function (query) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'skills/autocomplete/' + query
                });
            },
            getForCourse: function (courseCode) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'course/' + courseCode + '/skills'
                });
            },
            save: function (courseCode, skillId, weight) {
                return $http({
                    method: 'PUT',
                    url: restUrlPublicPrefix + 'course/' + courseCode + '/skills/' + skillId,
                    data: { weight: weight }
                });
            },
            delete: function (courseCode, skillId) {
                return $http({
                    method: 'DELETE',
                    url: restUrlPublicPrefix + 'course/' + courseCode + '/skills/' + skillId
                });
            },
            createSkill: function (skillName) {
                var currentSemester = SecurityService.getSemester();
                return $http.put(restUrlPublicPrefix + 'skills/' + skillName + AddressBuild.parameters([['semester', currentSemester]]));
            }
        };
    }]);
})(angular);