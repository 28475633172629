(function (angular) {
    angular.module("app").service("SystemActionsService", ["restUrlPrivatePrefix", "restUrlPublicPrefix", "$http", "AddressBuild", function (restUrlPrivatePrefix, restUrlPublicPrefix, $http, AddressBuild) {
        return {

            recalculateUsers: function () {
                return $http({
                    method: 'POST',
                    url: restUrlPrivatePrefix + 'admin/users'
                });
            },

            historizeUnhistorized: function () {
                return $http({
                    method: 'POST',
                    url: restUrlPrivatePrefix + 'admin/versioning'
                });
            },

            recalculateExpressions: function (semesterCode) {
                return $http({
                    method: 'POST',
                    url: restUrlPrivatePrefix + 'admin/expressions' + AddressBuild.parameters([['semester', semesterCode]])
                });
            },

            invalidateCaches: function () {
                return $http({
                    method: 'DELETE',
                    url: restUrlPrivatePrefix + 'admin/cache'
                });
            },

            sendTestNotification: function (body) {
                return $http({
                    method: 'POST',
                    url: restUrlPublicPrefix + 'notifications/send',
                    data: body
                });
            },

            testPushNotification: function (semesterCode) {
                return $http({
                    method: 'POST',
                    url: restUrlPrivatePrefix + 'admin/test-push-notification'
                });
            },

            hideCourse: function (courseCode, hide) {
                return $http({
                    method: 'PUT',
                    url: restUrlPrivatePrefix + 'admin/course/' + courseCode + '/hidden' + AddressBuild.parameters([['hidden', hide]])
                });
            },

            getHiddenCourses: function () {
                return $http({
                    method: 'GET',
                    url: restUrlPrivatePrefix + 'admin/courses/hidden'
                });
            }
        };
    }]);
})(angular);