(function (angular) {
    angular.module("app").service("ClassificationEditorsSettingsService", ["restUrlPublicPrefix", "$http", function (restUrlPublicPrefix, $http) {
        return {
            getCourseEditors: function (courseCode) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/editors'
                });
            },
            addCourseEditor: function (courseCode, username) {
                return $http({
                    method: 'PUT',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/editors/' + username
                });
            },
            deleteCourseEditor: function (courseCode, username) {
                return $http({
                    method: 'DELETE',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/editors/' + username
                });
            }
        };
    }]);
})(angular);