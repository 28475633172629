(function (angular) {
    angular.module("app").service("CsvService", function () {
        return {
            jsonToCsv: function (fields, data, delimiter) {
                var config = {
                    quotes: false,
                    quoteChar: '"',
                    delimiter: delimiter,
                    header: true,
                    newline: "\n"
                };
                var results = Papa.unparse({ fields: fields, data: data }, config);
                return results;
            },
            csvToJson: function (rawColumns, csvData) {
                var config = {
                    delimiter: "", // auto-detect
                    newline: "", // auto-detect
                    quoteChar: '"',
                    header: true,
                    dynamicTyping: true,
                    preview: 0,
                    encoding: "",
                    worker: false,
                    comments: false,
                    step: undefined,
                    complete: undefined,
                    error: undefined,
                    download: false,
                    skipEmptyLines: false,
                    chunk: undefined,
                    fastMode: undefined,
                    beforeFirstChunk: undefined,
                    withCredentials: undefined
                };
                var results = Papa.parse(csvData, config);
                return results.data;
            }
        };
    });
})(angular);