(function (angular) {
    angular.module('app').directive('customOnChange', ['$parse', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                var onChangeHandler = scope.$eval(attrs.customOnChange);
                element.bind('change', onChangeHandler);

                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            }
        };
    }]);
})(angular);