(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/settings', {
            templateUrl: 'classification/pages/common/Settings/Settings.tmpl.html',
            controller: 'SettingsCtrl',
            resolve: {
                settings: ["SettingsService", function (SettingsService) {
                    return SettingsService.getSettings().then(function (value) {
                        return value.data;
                    });
                }]
            }
        });
    }]);
})(angular);