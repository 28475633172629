(function (angular) {
    angular.module("app").controller("NotificationCtrl", ["$scope", "$rootScope", "NotificationService", "allNotifications", function ($scope, $rootScope, NotificationService, allNotifications) {
        $scope.allNotifications = allNotifications;

        $scope.readNotification = function (notification) {
            NotificationService.readNotification(notification);
        };
        $scope.unreadNotification = function (notification) {
            NotificationService.unreadNotification(notification);
        };
        $scope.readAllNotifications = function () {
            NotificationService.readAllNotifications();
        };
        $scope.unreadAllNotifications = function () {
            NotificationService.unreadAllNotifications();
        };
    }]);
})(angular);