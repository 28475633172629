(function (angular) {
    angular.module('app').directive('wizard', ["$rootScope", "WizardService", function ($rootScope, WizardService) {
        var linkFunction = function (scope, element, attrs) {
            if (!scope.show) scope.show = true;
            var form = element.find('form')[0];
            // FIXME: figure out a better way to switch languages
            // $rootScope.currentLanguage is only passed by value
            // so we need the whole object, which is passed by reference
            scope.rootScope = $rootScope;

            scope.changePage = function (pageNumber) {
                if (!form.reportValidity()) {
                    return;
                }
                scope.activePage = pageNumber;
                scope.wizardObject.pages[pageNumber].disabled = false;

                // TODO: focus input on new page
            };

            scope.activePanel = [];
            for (var i = 0; i < scope.wizardObject.pages.length; ++i) {
                scope.activePanel.push(0);
                scope.wizardObject.pages[i].disabled = true;
            }

            scope.changePage(0);

            scope.changePanel = function (pageNumber, panelNumber) {
                scope.activePanel[pageNumber] = panelNumber;
                // TODO: focus input on new panel
            };

            scope.showIntro = undefined;
            if (scope.introductionText !== undefined) {
                scope.showIntro = true;
            }

            scope.advancePage = function () {
                if (scope.showIntro) {
                    scope.showIntro = false;
                } else {
                    scope.changePage(scope.activePage + 1);
                }
            };

            scope.retirePage = function () {
                if (scope.activePage === 0) {
                    scope.showIntro = true;
                } else {
                    scope.changePage(scope.activePage - 1);
                }
            };

            scope.submit = function () {
                var inputs = WizardService.retrieveWizardObjectData(scope.wizardObject, scope.activePanel);
                scope.wizardObject.submitFunction(inputs);
            };

            scope.validateForm = function () {
                return form.reportValidity();
            };
        };
        return {
            restrict: 'E',
            scope: {
                wizardObject: '=wizardObject',
                submitFunction: '=submitFunction',
                show: '=?',
                introductionText: '=?introductionText'
            },
            templateUrl: 'classification/directive/wizard/wizard.tmpl.html',
            link: linkFunction
        };
    }]);
})(angular);