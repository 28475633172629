(function (angular) {
    angular.module('app').directive('preventLeaving', ["DialogService", "$location", function (DialogService, $location) {
        return {
            restrict: 'AC',
            replace: false,
            transclude: false,
            scope: false,
            link: function (scope, elem, attrs) {
                var preventLeaving = true;
                scope.$on("$locationChangeStart", function (event) {
                    if (preventLeaving && elem.hasClass('ng-dirty')) {
                        scope.target = $location.path();
                        event.preventDefault();
                        DialogService.createConfirmDialog("LEAVE", "DO_YOU_REALLY_WANT_TO_LEAVE", scope.redirect, "LEAVE", "STAY", "negative", "neutral");
                    }
                });

                scope.redirect = function () {
                    preventLeaving = false;
                    $location.path(scope.target);
                    window.onbeforeunload = null;
                };

                window.onbeforeunload = function (event) {
                    if (elem.hasClass('ng-dirty')) {
                        var dialogText = 'You are leaving unsaved changes.';
                        event.returnValue = dialogText;
                        return dialogText;
                    }
                };
            }
        };
    }]);
})(angular);