(function (angular) {
    angular.module('app').directive('positiveNegative', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                model: '='
            },
            templateUrl: 'classification/directive/positiveNegative/positiveNegative.tmpl.html'
        };
    });
})(angular);