(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/admin/frontend-elements/others', {
            templateUrl: 'classification/pages/admin/FrontendElements/Others/OthersFrontendElements.tmpl.html',
            controller: 'OthersFrontendElementsCtrl',
            resolve: {
                // frontendElementsForms: function (FrontendElementsService, BakerService) {
                //     return BakerService.bakeLangFunction(FrontendElementsService.getSomething);
                // }
                // what should be resolved before passing to controller
            }
        });
    }]);
})(angular);