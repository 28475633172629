(function (angular) {
    angular.module("app").service("MyClassificationService", ["restUrlPublicPrefix", "$http", "AddressBuild", function (restUrlPublicPrefix, $http, AddressBuild) {
        return {
            getStudentClassification: function (semesterCode, courseCode, username, lang, showHidden) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/student-classifications/' + username + AddressBuild.parameters([['semester', semesterCode], ['lang', lang], ['showHidden', showHidden]])
                });
            },
            getLatestStudentClassification: function (courseCode, username, lang, showHidden) {
                return $http.get(restUrlPublicPrefix + 'courses/' + courseCode + '/student-classifications/' + username + '/latest' + AddressBuild.parameters([['lang', lang], ['showHidden', showHidden]]));
            },
            getClassificationOverview: function (semesterCode, username, lang) {
                return $http.get(restUrlPublicPrefix + 'courses/classification-overview/' + username + AddressBuild.parameters([['semester', semesterCode], ['lang', lang]]));
            }
        };
    }]);
})(angular);