(function (angular) {
    angular.module("app").controller("ExternalApplicationsCtrl", ["$scope", "ExternalApplicationsService", "SecurityService", "myApplications", "otherApplications", "$translate", "DialogService", "$http", "$q", "restUrlPublicPrefix", function ($scope, ExternalApplicationsService, SecurityService, myApplications, otherApplications, $translate, DialogService, $http, $q, restUrlPublicPrefix) {

        $scope.otherApplications = otherApplications;
        $scope.myApplications = myApplications;
        $scope.newApplication = {
            clientId: null,
            name: null
        };
        $scope.clientIdExists = null;
        $scope.appNameExists = null;

        var usedClientIds = [];
        var usedNamesOfOfficialInUpperCase = [];

        function refreshUsedIdsAndNames() {
            usedClientIds = [];
            usedNamesOfOfficialInUpperCase = [];
            var allApps = angular.copy($scope.myApplications);
            allApps = allApps.concat($scope.otherApplications);
            for (var i = 0; i < allApps.length; i++) {
                var app = allApps[i];
                usedClientIds.push(app.externalApplicationId);
                usedNamesOfOfficialInUpperCase.push(app.name.toUpperCase());
            }
        }

        refreshUsedIdsAndNames();

        $scope.validateId = function () {
            $scope.clientIdExists = usedClientIds.includes($scope.newApplication.clientId);
        };

        $scope.validateName = function () {
            if ($scope.newApplication.name) {
                var name = $scope.newApplication.name.toUpperCase();
                for (var i = 0; i < usedNamesOfOfficialInUpperCase.length; i++) {
                    if (name === usedNamesOfOfficialInUpperCase[i]) {
                        $scope.appNameExists = true;
                        return;
                    }
                }
            }
            $scope.appNameExists = false;
        };

        $scope.reloadApplications = function () {
            var myApplicationsPromise = $http.get(restUrlPublicPrefix + "external-applications/my", { cache: false });
            var otherApplicationsPromise = $http.get(restUrlPublicPrefix + "external-applications/other", { cache: false });

            $q.all([myApplicationsPromise, otherApplicationsPromise]).then(function (values) {
                var myApplicationsData = values[0].data;
                var otherApplicationsData = values[1].data;
                $scope.myApplications = myApplicationsData;
                $scope.otherApplications = otherApplicationsData;
                refreshUsedIdsAndNames();
            }, function () {
                DialogService.sendNegativeNotification("ERROR_LOADING_APPLICATIONS");
            });
        };

        $scope.deleteApplication = function (appDto) {
            var message = $translate.instant('DO_YOU_REALLY_WANT_TO_DELETE_APPLICATION') + " " + appDto.name + "?";
            DialogService.createConfirmDialog('DELETE_APPLICATION', message, function () {
                ExternalApplicationsService.deleteExternalApplication(appDto.externalApplicationId).then(function (response) {
                    $scope.reloadApplications();
                    DialogService.sendPositiveNotification("APPLICATION_WAS_SUCCESSFULLY_DELETED");
                });
            }, 'DELETE', 'CANCEL', 'negative', 'neutral');
        };

        $scope.addApplication = function () {
            var username = SecurityService.getUsername();
            var appDto = {
                externalApplicationId: $scope.newApplication.clientId,
                name: $scope.newApplication.name,
                isOfficial: false,
                author: username
            };
            ExternalApplicationsService.addExternalApplication(appDto).then(function (response) {
                $scope.addingApplication = false;
                $scope.reloadApplications();
                $scope.newApplication.clientId = null;
                $scope.newApplication.name = null;
                DialogService.sendPositiveNotification("APPLICATION_WAS_SUCCESSFULLY_CREATED");
            });
        };
    }]);
})(angular);