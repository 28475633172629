(function (angular) {
    angular.module("app").service("SemesterService", ["restUrlPublicPrefix", "$http", "AddressBuild", function (restUrlPublicPrefix, $http, AddressBuild) {
        return {
            validateSemesterCode: function (semester) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'semester-code' + AddressBuild.parameters([['semester', semester]])
                });
            }
        };
    }]);
})(angular);