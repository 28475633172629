(function (angular) {
    angular.module('app').directive('scrollbar', ["$window", "$rootScope", function ($window, $rootScope) {
        return {
            scope: true,
            restrict: 'A',
            replace: false,
            link: function (scope, element, attrs) {

                var elemMargin = 10; //px
                var scrollDirection = attrs.scrollbar; //preparation for y scroll

                var mousePressed = [false, false, false, false];
                var currentMousePosition;
                var sliderPositionLeft;
                var outerElemWidth;
                var innerElemWidh;
                var sliderTrajectoryLength;

                var sliderArea = angular.element('<div class="sb-slider-area"></div>');
                var slider = angular.element('<div class="sb-slider"></div>');
                sliderArea.append(slider);
                element.append(sliderArea);
                var elem = element[0];
                elem.style.position = 'relative';
                var innerElem = element.children()[0];
                var mouseSliderPos;
                init();

                angular.element($window).bind('mousemove', function (e) {
                    currentMousePosition = [e.pageX - elem.offsetLeft, e.pageY - elem.offsetTop];
                    if (mousePressed[0]) {
                        e.preventDefault();
                        scrollByBar();
                    }
                });

                sliderArea.bind('mousedown', function (e) {
                    mousePressed[e.button] = true;
                    if (e.button == 0) {
                        scrollByBar();
                    }
                });
                angular.element($window).bind('mouseup', function (e) {
                    mousePressed[e.button] = false;
                    mouseSliderPos = null;
                });

                element.bind('resize', init);

                $rootScope.$on('elementUpdated', init);

                element.bind('wheel', function (e) {
                    if (e.shiftKey) {
                        e.preventDefault();
                        var sign = e.deltaY ? e.deltaY < 0 ? -1 : 1 : 0;
                        elem.scrollLeft += sign * 100;
                    }
                });

                angular.element($window).bind('scroll', function (e) {
                    updateBarPosition();
                });

                element.bind('scroll', function (e) {
                    updateBarPosition();
                });

                function init() {
                    angular.element(document).ready(function () {
                        outerElemWidth = elem.clientWidth;
                        innerElemWidh = innerElem.clientWidth;
                        if (elem.scrollLeft + outerElemWidth > innerElemWidh) {
                            elem.scrollLeft = innerElemWidh - outerElemWidth;
                        }
                        updateBarPosition();
                        var sliderWidth = sliderArea[0].clientWidth * outerElemWidth / innerElemWidh;
                        var sliderAreaWidth = sliderArea[0].clientWidth;
                        slider[0].style.width = sliderWidth + 'px';
                        sliderTrajectoryLength = sliderAreaWidth - sliderWidth;
                        sliderPositionLeft = 0;
                        if (sliderWidth >= sliderAreaWidth) {
                            sliderArea[0].style.visibility = 'hidden';
                        } else {
                            sliderArea[0].style.visibility = 'visible';
                        }
                    });
                }

                function scrollByBar() {
                    if (!currentMousePosition) {
                        return;
                    }
                    var sliderWidth = slider[0].clientWidth;
                    if (currentMousePosition[0] - elemMargin > slider[0].offsetLeft && currentMousePosition[0] - elemMargin < slider[0].offsetLeft + slider[0].clientWidth) {
                        if (!mouseSliderPos) {
                            mouseSliderPos = currentMousePosition[0] - elemMargin - slider[0].offsetLeft;
                        }
                    } else {
                        mouseSliderPos = sliderWidth / 2;
                    }
                    var percent = (currentMousePosition[0] - elemMargin - mouseSliderPos) / (sliderArea[0].clientWidth - sliderWidth);
                    if (percent > 1) {
                        percent = 1;
                    } else if (percent < 0) {
                        percent = 0;
                    }
                    elem.scrollLeft = percent * (innerElemWidh - outerElemWidth);
                    updateBarPosition();
                }

                function updateBarPosition() {
                    var viewportOffset = element[0].getBoundingClientRect();
                    sliderPositionLeft = sliderTrajectoryLength * elem.scrollLeft / (innerElemWidh - outerElemWidth);
                    slider[0].style.left = sliderPositionLeft + 'px';

                    sliderArea[0].style.left = viewportOffset.left + elemMargin + 'px';
                    sliderArea[0].style.right = angular.element(document.getElementsByClassName('all'))[0].clientWidth - viewportOffset.right + elemMargin + 'px';

                    var scrollY = $window.scrollY;
                    if (!scrollY) scrollY = document.documentElement.scrollTop;
                    var btnsElement = angular.element(document.getElementsByClassName('btns-bottom sticky'));
                    var btnsHeight = btnsElement && btnsElement[0] ? btnsElement[0].clientHeight : 0;
                    var xBarOffset = $window.innerHeight + scrollY - elem.offsetTop - elem.clientHeight - btnsHeight;

                    if (xBarOffset > 0) {
                        xBarOffset = 0;
                        sliderArea[0].style.display = 'none';
                    } else {
                        sliderArea[0].style.display = 'block';
                    }
                    sliderArea[0].style.bottom = btnsHeight + elemMargin + 'px';
                }
            }
        };
    }]);
})(angular);