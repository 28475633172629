(function (angular) {
        angular.module('app').directive('scoreRange', ["$translate", function ($translate) {
                function isNotNullAndUndefined(obj) {
                        return obj !== undefined && obj !== null;
                }

                return {
                        restrict: 'E',
                        replace: false,
                        scope: {
                                minimumValue: '=?',
                                minimumRequiredValue: '=?',
                                maximumValue: '=?'
                        },
                        templateUrl: 'classification/template/score-range.tmpl.html',

                        link: function (scope, element, attrs) {
                                /* undefined minimumValue is replaced with 0 for display */

                                scope.displayMinimumValue = function () {
                                        return isNotNullAndUndefined(scope.minimumValue) ? scope.minimumValue : isNotNullAndUndefined(scope.minimumRequiredValue) ? Math.min(0, scope.minimumRequiredValue) : 0;
                                };

                                scope.canDisplayMinimumValue = function () {
                                        return !scope.canReplaceWithLine();
                                };
                                scope.canDisplayMinimumRequiredValue = function () {
                                        return isNotNullAndUndefined(scope.minimumRequiredValue);
                                };
                                scope.canDisplayMaximumValue = function () {
                                        return isNotNullAndUndefined(scope.maximumValue);
                                };
                                scope.canReplaceWithLine = function () {
                                        return !isNotNullAndUndefined(scope.minimumValue) && !isNotNullAndUndefined(scope.minimumRequiredValue) && !isNotNullAndUndefined(scope.maximumValue);
                                };
                                scope.getPopoverText = function () {
                                        var text = "";
                                        if (scope.canDisplayMinimumValue()) {
                                                text += $translate.instant("MINIMUM_VALUE");
                                        }

                                        if (scope.canDisplayMinimumRequiredValue()) {
                                                if (text) {
                                                        text += " / ";
                                                }
                                                text += $translate.instant("MINIMUM_REQUIRED_VALUE");
                                        }

                                        if (scope.canDisplayMaximumValue()) {
                                                if (text) {
                                                        text += " / ";
                                                }
                                                text += $translate.instant("MAXIMUM_VALUE");
                                        }

                                        if (!text) {
                                                text += $translate.instant("UNDEFINED_RANGE");
                                        }

                                        return text;
                                };
                        }
                };
        }]);
})(angular);

/* All possible cases
0 0 0
<score-range>
</score-range>

0 0 1
<score-range
        maximum-value=100>
</score-range>

0 1 0
<score-range
        minimum-required-value=25>
</score-range>

0 1 1
<score-range
        minimum-required-value=25
        maximum-value=100>
</score-range>

0 -1 0
<score-range
        minimum-required-value=-25>
</score-range>

0 -1 1
<score-range
        minimum-required-value=-25
        maximum-value=100>
</score-range>

1 0 0
<score-range
        minimum-value=-25>
</score-range>

1 0 1
<score-range
        minimum-value=-25
        maximum-value=100>
</score-range>

1 1 0
<score-range
        minimum-value=-25
        minimum-required-value=0>
</score-range>

1 1 1
<score-range
        minimum-value=-25
        minimum-required-value=0
        maximum-value=100>
</score-range>

0 1=1
<score-range
        minimum-value=0
        minimum-required-value=100
        maximum-value=100>
</score-range>

1 1=1
<score-range
        minimum-value=-25
        minimum-required-value=100
        maximum-value=100>
</score-range>

1=1 0
<score-range
        minimum-value=-25
        minimum-required-value=-25>
</score-range>

1=1 1
<score-range
        minimum-value=-25
        minimum-required-value=-25
        maximum-value=100>
</score-range>

=1 0 1=
<score-range
        minimum-value=-25
        maximum-value=-25>
</score-range>

1=1=1
<score-range
        minimum-value=-25
        minimum-required-value=-25
        maximum-value=-25>
</score-range>
*/