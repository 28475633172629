(function (angular) {
    angular.module("app").service("SettingsService", ["restUrlPublicPrefix", "$http", "AddressBuild", function (restUrlPublicPrefix, $http, AddressBuild) {
        return {
            getSettings: function () {
                return $http({
                    method: 'GET',
                    url: AddressBuild.addSemester(restUrlPublicPrefix + 'settings/my')
                });
            },
            saveUserSettings: function (userSettings) {
                return $http({
                    method: 'PUT',
                    url: AddressBuild.addSemester(restUrlPublicPrefix + 'settings/my'),
                    data: userSettings
                });
            },
            saveStudentCourseSettings: function (studentCourseSettings) {
                return $http({
                    method: 'PUT',
                    url: AddressBuild.addSemester(restUrlPublicPrefix + 'settings/my/student/courses'),
                    data: studentCourseSettings
                });
            },
            saveTeacherCourseSettings: function (teacherCourseSettings) {
                return $http({
                    method: 'PUT',
                    url: AddressBuild.addSemester(restUrlPublicPrefix + 'settings/my/teacher/courses'),
                    data: teacherCourseSettings
                });
            }
        };
    }]);
})(angular);