(function (angular) {
    angular.module('app').directive('createReadDelete', ["DialogService", function (DialogService) {
        return {
            restrict: 'E',
            scope: {
                entityNameAdjective: '=?',
                entityNameNoun: '=',
                autocompleteFunc: '=?',
                removeFunc: '=',
                addFunc: '=',
                getFunc: '=',
                funcParam: '=?'
            },
            templateUrl: 'classification/template/create-read-delete.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.items = null;
                scope.loading = false;
                scope.typeaheadLoading = false;
                if (scope.funcParam) {
                    reloadItems();
                }

                function reloadItems() {
                    scope.getFunc(scope.funcParam).then(function (response) {
                        scope.items = response.data;
                        scope.userInput = '';
                        hideSpinner();
                    });
                }

                scope.$watch('funcParam', function (newParam, oldParam) {
                    if (newParam && newParam !== oldParam) {
                        reloadItems();
                    }
                });

                scope.getItems = function (query) {
                    if (scope.autocompleteFunc) {
                        return scope.autocompleteFunc(query).then(function (response) {
                            return response.data;
                        });
                    }
                };

                function showSpinner() {
                    scope.loading = true;
                }

                function hideSpinner() {
                    scope.loading = false;
                    scope.typeaheadLoading = false;
                }

                scope.removeItem = function (username) {
                    scope.removeFunc(scope.funcParam, username).then(function (response) {
                        reloadItems();
                    });
                };
                scope.addItem = function () {
                    showSpinner();
                    if (scope.userInput) {
                        var username = scope.userInput.hasOwnProperty("username") ? scope.userInput.username : scope.userInput;
                        if (!username) {
                            DialogService.sendNegativeNotification("INPUT_INVALID_USER");
                            hideSpinner();
                        } else {
                            scope.getItems(username).then(function (response) {
                                var user = response.find(function (item) {
                                    return item.username === username;
                                });
                                if (!user) {
                                    DialogService.sendNegativeNotification("INPUT_INVALID_USER");
                                    hideSpinner();
                                } else {
                                    scope.addFunc(scope.funcParam, username).then(function (response) {
                                        reloadItems();
                                    })["catch"](function (data) {
                                        hideSpinner();
                                    });
                                }
                            }, function (response) {
                                hideSpinner();
                            });
                        }
                    }
                };
            }
        };
    }]);
})(angular);