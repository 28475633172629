(function (angular) {
    angular.module("app").service("BreadcrumbService", ["$rootScope", "$route", "$location", function ($rootScope, $route, $location) {
        var watcher = null;
        var viewInfo = {
            link: null,
            showBackButton: false
        };

        return {
            setLink: function (link) {
                viewInfo.link = link;
                viewInfo.showBackButton = true;
                watcher = $rootScope.$watch(function () {
                    return $location.path();
                }, function (newLocation, oldLocation) {
                    if (newLocation !== oldLocation) {
                        if (oldLocation === viewInfo.link) {
                            reset();
                        }
                    }
                });
            },
            getDataObject: function () {
                return viewInfo;
            },
            reset: reset = function () {
                viewInfo.link = null;
                viewInfo.showBackButton = false;
                watcher(); //disables watcher
            }
        };
    }]);
})(angular);