(function (angular) {
    angular.module("app").controller("CourseClassificationCtrl", ["$rootScope", "$scope", "$route", "$location", "SecurityService", "DialogService", "TranslateService", "studentGroupDtos", "courseRoles", "classificationsDefinitions", "ClassificationService", "CsvService", "DownloadUploadService", "$translate", "$timeout", "$cookies", "EvolutionTeamService", "NotificationService", "courseInformation", "AddressBuild", "KosGradingService", function ($rootScope, $scope, $route, $location, SecurityService, DialogService, TranslateService, studentGroupDtos, courseRoles, classificationsDefinitions, ClassificationService, CsvService, DownloadUploadService, $translate, $timeout, $cookies, EvolutionTeamService, NotificationService, courseInformation, AddressBuild, KosGradingService) {
        $scope.data = [];
        $scope.filteredData = [];
        $scope.exportDialogOutput = {
            fileName: "data",
            delimiter: ","
        };
        $scope.classificationDefinitions = classificationsDefinitions;
        $scope.courseCode = $route.current.params.courseCode;
        $scope.courseName = courseInformation.courseName;
        $scope.classesType = courseInformation.classesType;
        $scope.canEditCourse = courseRoles.canEditCourse();
        $scope.classificationDetailUrl = AddressBuild.addSemester('courses/' + $scope.courseCode + '/classification-detail');
        $scope.classificationDefinitionUrl = AddressBuild.addSemester('courses/' + $scope.courseCode + '/classification/definition');

        $scope.queryText = $route.current.params.query;
        var previousQuery = $scope.queryText;
        $scope.availableVariables = {
            "$COURSE_CODE": "STRING",
            "$SEMESTER_CODE": "STRING",
            "$USERNAME": "STRING",
            "$FIRSTNAME": "STRING",
            "$LASTNAME": "STRING",
            "$FULLNAME": "STRING",
            "$PERSONAL_NUMBER": "STRING"
        };
        angular.forEach($scope.classesType, function (parallelType) {
            $scope.availableVariables["$" + parallelType + "_PARALLEL_NUMBER"] = "STRING";
            $scope.availableVariables["$" + parallelType + "_PARALLEL_CODE"] = "STRING";
        });
        angular.forEach($scope.classificationDefinitions, function (column) {
            $scope.availableVariables[column.identifier] = column.valueType;
            $scope.availableVariables[column.identifier + ".IDENTIFIER"] = "STRING";
            $scope.availableVariables[column.identifier + ".HIDDEN"] = "BOOLEAN";
            if (column.valueType === "NUMBER") {
                $scope.availableVariables[column.identifier + ".MIN"] = "NUMBER";
                $scope.availableVariables[column.identifier + ".MIN_REQUIRED"] = "NUMBER";
                $scope.availableVariables[column.identifier + ".MAX"] = "NUMBER";
            }
        });

        $scope.processQuery = function (query, force) {
            $scope.queryText = query;
            $location.search('query', query);

            if (query !== previousQuery || force) {
                previousQuery = query;
                loadData();
            }
        };

        $scope.searchExpressionData = {
            'availableVariables': $scope.availableVariables,
            'resultType': 'BOOLEAN',
            'successCallback': $scope.processQuery,
            'queryText': $scope.queryText,
            'toggleSubtext': true
        };

        var groupId = $cookies.get("GROUP_" + $scope.courseCode);

        for (var i = 0; i < studentGroupDtos.length; i++) {
            var studentGroupDto = studentGroupDtos[i];
            studentGroupDto.name = TranslateService.translateParts(studentGroupDto.name);
        }

        $scope.groupSelection = {
            selected: groupId ? groupId : null,
            groups: studentGroupDtos
        };

        $scope.changeGroup = function () {
            groupId = $scope.groupSelection.selected;
            $scope.data = null;
            $cookies.put("GROUP_" + $scope.courseCode, groupId);
            changeHiddenLabels();
            loadData();
        };

        function getGroupType() {
            for (var i = 0; i < studentGroupDtos.length; i++) {
                var studentGroupDto = studentGroupDtos[i];
                if (studentGroupDtos[i].studentGroupId === $scope.groupSelection.selected) {
                    return studentGroupDto.type;
                }
            }
        }

        function changeHiddenLabels() {
            var hiddenLabels = $cookies.getObject("HIDDEN_LABELS_" + $scope.courseCode + "_" + getGroupType());
            if (!hiddenLabels) {
                hiddenLabels = ['personalNumber', 'grade'];
                courseInformation.classesType.forEach(function (classType) {
                    hiddenLabels.push(classType + "_parallelCode", classType + "_parallelNumber");
                });
            }
            $scope.labels.forEach(function (label) {
                label.shown = !hiddenLabels.includes(label.key);
            });
        }

        function getValueTypeOfColumn(columnName) {
            var columns = $scope.classificationDefinitions;
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].identifier === columnName) {
                    return columns[i].valueType;
                }
            }
        }

        // doesn't handle oddities like "TRue", "   true" and so on
        function validateDataTypesAndAssignValue(columnName, student, newValue, errorLog) {
            var valueType = getValueTypeOfColumn(columnName);

            // always convert to number if possible
            if (EvolutionTeamService.isNumber(newValue)) {
                newValue = +newValue;
            }
            if (valueType === "NUMBER") {
                if (EvolutionTeamService.isNumber(newValue)) {
                    student[columnName] = newValue;
                } else {
                    //type mismatch .. expecting number
                    errorLog.expectedDatatype = "NUMBER";
                    return false;
                }
            } else if (valueType === "BOOLEAN") {
                if (typeof newValue === "boolean") {
                    student[columnName] = newValue;
                } else if (EvolutionTeamService.isNumber(newValue)) {
                    if (newValue !== 1 && newValue !== 0) {
                        // if not 1 or 0, then error
                        errorLog.expectedDatatype = "BOOLEAN";
                    } else {
                        student[columnName] = newValue === 1;
                    }
                } else {
                    //type mismatch .. expecting boolean
                    errorLog.expectedDatatype = "BOOLEAN";
                    return false;
                }
            } else {
                //valueType TEXT .. always ok
                student[columnName] = newValue;
            }
            return true;
        }

        function doSave(form, notify) {
            var save = [];
            var loadingDialog = DialogService.createLoadingDialog('SAVING');
            $scope.classificationDefinitions.forEach(function (definition) {
                $scope.data.forEach(function (studentClassification) {
                    if (definition.evaluationType == "MANUAL") {
                        var originalStudentClassification = $scope.originalDataMap[studentClassification.username];

                        if (definition.valueType === "STRING" && studentClassification[definition.identifier] !== undefined && studentClassification[definition.identifier] !== null) {
                            studentClassification[definition.identifier] = studentClassification[definition.identifier].toString();
                        }
                        if (studentClassification[definition.identifier] === '') {
                            studentClassification[definition.identifier] = null;
                        }
                        if (originalStudentClassification[definition.identifier] != studentClassification[definition.identifier]) {
                            save.push({
                                studentUsername: studentClassification.username,
                                classificationIdentifier: definition.identifier,
                                value: studentClassification[definition.identifier]
                            });
                        }
                    }
                });
            });
            if (save.length === 0) {
                DialogService.hideDialog(loadingDialog);
                DialogService.sendNeutralNotification("NO_CHANGES_WERE_MADE");
            } else {
                ClassificationService.saveStudentClassifications($scope.courseCode, SecurityService.getSemester(), save, notify).success(function (response) {
                    DialogService.hideDialog(loadingDialog);
                    DialogService.sendPositiveNotification($rootScope.isApril ? "APRIL_A_TO_ALL_STUDENTS" : "CHANGES_SUCCESSFULLY_SAVED");
                    loadData();
                    if (form) form.$setPristine();
                })["catch"](function (error) {
                    DialogService.hideDialog(loadingDialog);
                });
            }
        }

        $scope.confirmDialogAction = function (form) {
            $scope.confirmDialogData.show = false;
            var notify = $scope.confirmDialogData.value;
            doSave(form, notify);
        };

        $scope.confirmDialogData = {
            value: true,
            show: false
        };
        // show dialog for non-test student groups
        $scope.save = function (form) {
            if ($scope.groupSelection.selected !== "TEST") {
                $scope.confirmDialogData.value = true;
                $scope.confirmDialogData.show = true;
            } else {
                doSave(form, false); // save for test students without asking
            }
        };

        $scope.nonfixedLabelsFilter = function (label) {
            return !label.fixed;
        };
        $scope.labelCategories = ['COLUMNS_SHOW_ALL', 'COLUMNS_HIDE_ALL', 'COLUMNS_INVERT_SELECTION', 'COLUMNS_SEMESTER', 'COLUMNS_EXAMS', 'COLUMNS_VISIBLE_FOR_STUDENTS'];
        var classificationTypes = ClassificationService.getClassificationTypes();
        $scope.applyCategory = function (category) {
            switch (category) {
                case 'COLUMNS_SHOW_ALL':
                    $scope.labels.forEach(function (label) {
                        if (!label.fixed) label.shown = true;
                    });
                    break;
                case 'COLUMNS_HIDE_ALL':
                    $scope.labels.forEach(function (label) {
                        if (!label.fixed) label.shown = false;
                    });
                    break;
                case 'COLUMNS_INVERT_SELECTION':
                    $scope.labels.forEach(function (label) {
                        if (!label.fixed) label.shown = !label.shown;
                    });
                    break;
                case 'COLUMNS_SEMESTER':
                    $scope.labels.forEach(function (label) {
                        if (!label.fixed) {
                            var res = classificationTypes[label.type];
                            label.shown = res === undefined || res.type === 'SEMESTER' || res.type === 'ALL';
                        }
                    });
                    break;
                case 'COLUMNS_EXAMS':
                    $scope.labels.forEach(function (label) {
                        if (!label.fixed) {
                            var res = classificationTypes[label.type];
                            label.shown = res === undefined || res.type === 'EXAMS' || res.type === 'ALL';
                        }
                    });
                    break;
                case 'COLUMNS_VISIBLE_FOR_STUDENTS':
                    $scope.labels.forEach(function (label) {
                        if (!label.fixed) label.shown = !label.hiddenForStudents;
                    });
                    break;
            }
        };

        $scope.labels = [{
            text: $translate.instant("FULLNAME"),
            subtext: "$FULLNAME",
            type: 'OTHER',
            key: "fullName",
            fixed: true,
            link: true,
            linkAddress: 'address'
        }, {
            text: $translate.instant("USERNAME"),
            subtext: "$USERNAME",
            type: 'OTHER',
            key: "username",
            fixed: true
        }, {
            text: $translate.instant("PERSONAL_NUMBER"),
            subtext: "$PERSONAL_NUMBER",
            type: 'OTHER',
            key: "personalNumber",
            fixed: true
        }, {
            text: $translate.instant("GRADE_YEAR"),
            type: 'OTHER',
            key: 'grade',
            fixed: true
        }];

        courseInformation.classesType.forEach(function (curClassType) {
            $scope.labels.push({
                text: $translate.instant(curClassType + "_PARALLEL_NUMBER"),
                subtext: "$" + curClassType + "_PARALLEL_NUMBER",
                type: 'OTHER',
                key: curClassType + "_parallelNumber",
                fixed: true
            });
        });

        courseInformation.classesType.forEach(function (curClassType) {
            $scope.labels.push({
                text: $translate.instant(curClassType + "_PARALLEL_CODE"),
                subtext: "$" + curClassType + "_PARALLEL_CODE",
                type: 'OTHER',
                key: curClassType + "_parallelCode",
                fixed: true
            });
        });

        var labelsTemp = [];

        /* Decides whether column should be highlighted in the smart-table */
        function canBeHighlighted(definition) {
            var identifiersToHighlight = ["ASSESSMENT", "FINAL_SCORE"];

            /* Identifier matches one of the elements in the array */
            if (identifiersToHighlight.indexOf(definition.classificationType) > -1) {
                return true;
            }

            /* Identifier ends with "sum" and value is calculated */
            // if(definition.identifier.search(/.*sum"/i) && definition.calculated) { return true; }

            return false;
        }

        $scope.classificationDefinitions.forEach(function (definition) {
            var inputType = null;
            var step = null;
            if (definition.evaluationType == "MANUAL") {
                inputType = ClassificationService.getInputFieldType(definition);
            }
            var currObj = {
                text: definition.classificationTextDtos[0].name,
                subtext: definition.identifier,
                type: definition.classificationType,
                key: definition.identifier,
                input: inputType,
                allowedValues: definition.allowedStringValues,
                step: step,
                index: definition.index,
                valueType: definition.valueType,
                hiddenForStudents: definition.hidden,
                highlight: canBeHighlighted(definition)
            };
            if (definition.valueType === "NUMBER") {
                currObj.minimumRequiredValue = definition.minimumRequiredValue;
                currObj.maximumValue = definition.maximumValue;
            }
            labelsTemp.push(currObj);
        });
        $scope.labels = $scope.labels.concat(labelsTemp);

        function loadData() {
            ClassificationService.getStudentClassifications(SecurityService.getSemester(), $scope.courseCode, groupId, $scope.queryText).then(function (res) {
                for (var i = 0; i < res.data.length; i++) {
                    res.data[i].fullName = res.data[i].lastName + " " + res.data[i].firstName;
                    for (var parallelType in res.data[i].parallelCodes) {
                        if (res.data[i].parallelCodes.hasOwnProperty(parallelType) && courseInformation.classesType.indexOf(parallelType) >= 0) {
                            res.data[i][parallelType + "_parallelCode"] = res.data[i].parallelCodes[parallelType];
                        }
                    }

                    for (parallelType in res.data[i].parallelNumbers) {
                        if (!res.data[i].parallelNumbers.hasOwnProperty(parallelType) && courseInformation.classesType.indexOf(parallelType) >= 0) continue;
                        res.data[i][parallelType + "_parallelNumber"] = res.data[i].parallelNumbers[parallelType];
                    }
                    res.data[i].evaluationAuthorMap = {};
                    for (var j in res.data[i].classificationMap) {
                        if (res.data[i].classificationMap.hasOwnProperty(j)) {
                            res.data[i][j] = res.data[i].classificationMap[j].value;
                            res.data[i].evaluationAuthorMap[j] = res.data[i].classificationMap[j].authorUsername;
                        }
                    }
                    res.data[i].address = 'courses/' + $scope.courseCode + '/students/' + res.data[i].username + AddressBuild.parameters([['semester', SecurityService.getRawSemester().override], ['view', '']]);
                    // todo make so that semester and parameter can be added 'independently'
                    delete res.data[i].classificationMap;
                }
                $scope.data = res.data;
                var originalData = [];
                $scope.data.forEach(function (student) {
                    originalData.push(EvolutionTeamService.copyObject(student));
                });
                $scope.originalDataMap = {};
                originalData.forEach(function (student) {
                    $scope.originalDataMap[student.username] = student;
                });
            })["catch"](function (error) {
                $scope.data = [];
                if (response.headers("message-type") !== "classification-exception") {
                    DialogService.sendNegativeNotification('ERROR_WHILE_LOADING_CLASSIFICATION');
                }
            });
        }

        if ($scope.groupSelection.selected) {
            $scope.changeGroup();
        }

        $scope.$watch('labels', function () {
            if ($scope.groupSelection.selected) {
                var labelsToHide = [];
                $scope.labels.forEach(function (label) {
                    if (!label.shown) {
                        labelsToHide.push(label.key);
                    }
                });
                $cookies.putObject("HIDDEN_LABELS_" + $scope.courseCode + "_" + getGroupType(), labelsToHide);
            }
        }, true);

        $scope.openSendNotificationDialog = function () {
            $scope.notificationDescription = $translate.instant('SEND_NOTIFICATION_EXPLANATION', { number: $scope.filteredData.length });
            $scope.sendingNotification = true;
        };

        $scope.notificationSend = {
            byMail: false,
            title: '',
            text: ''
        };

        $scope.sendNotification = function () {
            NotificationService.sendNotification($scope.notificationSend.byMail, {
                usernames: $scope.filteredData.map(function (user) {
                    return user.username;
                }),
                title: $scope.notificationSend.title,
                text: $scope.notificationSend.text
            }, SecurityService.getSemester(), $scope.courseCode);
            $scope.sendingNotification = false;
        };

        var file = null;
        $scope.setFile = function (evt) {
            file = evt;
        };

        var resetFile = function () {
            $scope.importFileModel = null;
            file = null;
        };

        var importCsv = function () {
            if (!file) return;

            DownloadUploadService.upload(file, function (fileContent) {
                var importedData = CsvService.csvToJson($scope.labels, fileContent);
                var tmpData = [];
                var timeout = 13000;
                var errorLog = { //keeps track of current position in csv table
                    rowNumber: 2,
                    columnNumber: 1,
                    wasSent: false,
                    expectedDatatype: null
                };

                importedData.forEach(function (student) {
                    errorLog.columnNumber = 1;
                    var originalStudent = EvolutionTeamService.copyObject($scope.originalDataMap[student.username]);
                    if (originalStudent) {
                        for (var j in student) {
                            if (student.hasOwnProperty(j)) {
                                if (student[j] === "") // emptying a non empty field will break not null constraints
                                    continue;
                                if (j === "username" || j === "fullName") {
                                    originalStudent[j] = student[j];
                                } else if (!validateDataTypesAndAssignValue(j, originalStudent, student[j], errorLog)) {
                                    //datatypes dont match
                                    if (!errorLog.wasSent) {
                                        //makes sure only one notification is sent, so they don't overwrite each other
                                        errorLog.wasSent = true;
                                        DialogService.sendNegativeNotification($translate.instant("ROW_N_COLUMN_M_EXPECTS_DATATYPE_X", { n: errorLog.rowNumber, m: errorLog.columnNumber, x: $translate.instant(errorLog.expectedDatatype) }), timeout);
                                    }
                                }
                            }
                            errorLog.columnNumber++;
                        }
                        tmpData.push(originalStudent);
                    }
                    errorLog.rowNumber++;
                });
                $scope.data = tmpData;
                $scope.$apply();
                DialogService.sendPositiveNotification('CLASSIFICATION_SUCCESSFULLY_IMPORTED');
            });
        };

        function exportCsv() {
            var exportingDialog = DialogService.createLoadingDialog('EXPORTING');
            var delimiter = $scope.exportDialogOutput.delimiter ? $scope.exportDialogOutput.delimiter : ',';
            var fields = [];

            $scope.labels.filter(function (label) {
                return label.shown;
            }).forEach(function (label) {
                fields.push(label.key);
            });
            var csv = CsvService.jsonToCsv(fields, angular.toJson($scope.data), delimiter);
            var name = $scope.exportDialogOutput.fileName ? $scope.exportDialogOutput.fileName : "data.csv";
            DownloadUploadService.download(csv, name + '.csv', 'csv');
            DialogService.hideDialog(exportingDialog);
            DialogService.sendPositiveNotification('CLASSIFICATION_SUCCESFULLY_EXPORTED');
        }

        $scope.jsExport = {
            classificationType: 'ASSESSMENT',
            column: '',
            filterByAuthor: false
        };
        $scope.setDefaultJsExportColumn = function () {
            var type = $scope.jsExport.classificationType;
            // TODO: create a separate map to fetch these if more classification types are added.
            // This is done because classified assessment behaves exactly the same as final score, the
            // only change occurs in kos javascript.
            if (type === 'CLASSIFIED_ASSESSMENT') {
                type = 'FINAL_SCORE';
            }
            var definitions = $scope.classificationDefinitions.filter(function (definition) {
                return definition.classificationType === type;
            });
            if (definitions.length !== 1) {
                return;
            }
            var soughtDefinition = definitions[0];
            if (type === 'ASSESSMENT' && soughtDefinition.valueType === 'BOOLEAN') {
                $scope.jsExport.column = definitions[0].identifier;
            } else if (type === 'FINAL_SCORE' && soughtDefinition.valueType === 'STRING') {
                $scope.jsExport.column = definitions[0].identifier;
            }
        };
        $scope.setDefaultJsExportColumn();
        // TODO: maybe move preprocessing into the service
        function exportJs() {
            // some basic preprocessing
            if (!$scope.jsExport.column || $scope.jsExport.column.length === 0) {
                DialogService.sendNegativeNotification(TranslateService.translate('CHOOSE_A_COLUMN'));
                return;
            }

            var soughtColumn = $scope.classificationDefinitions.find(function (def) {
                return def.identifier === $scope.jsExport.column;
            });
            // shouldn't ever happen
            if (soughtColumn === undefined) {
                DialogService.sendNegativeNotification(TranslateService.translate('UNKNOWN_ERROR_CHOOSING_A_COLUMN'));
            }

            var classificationTypeForChecks = $scope.jsExport.classificationType;
            var valueType = soughtColumn.valueType;
            if (classificationTypeForChecks === 'CLASSIFIED_ASSESSMENT') {
                classificationTypeForChecks = 'FINAL_SCORE';
            }
            if (classificationTypeForChecks === 'ASSESSMENT' && valueType !== 'BOOLEAN') {
                DialogService.sendNegativeNotification(TranslateService.translate('ASSESSMENT_MUST_BE_BOOLEAN'));
                return;
            } else if (classificationTypeForChecks === 'FINAL_SCORE' && valueType !== 'STRING') {
                DialogService.sendNegativeNotification(TranslateService.translate('FINAL_SCORE_MUST_BE_STRING'));
                return;
            }

            // FIXME: so radio stays checked, but this shouldn't be here in the first place
            var oldColumn = $scope.jsExport.classificationType;
            var convertClassificationType = {
                'ASSESSMENT': KosGradingService.EXPORT_ASSESSMENT,
                'FINAL_SCORE': KosGradingService.EXPORT_MARK,
                'CLASSIFIED_ASSESSMENT': KosGradingService.EXPORT_CLASSIFIED_ASSESSMENT
            };
            $scope.jsExport.classificationType = convertClassificationType[$scope.jsExport.classificationType];
            KosGradingService.generateScript($scope.jsExport, $scope.data);
            $scope.jsExport.classificationType = oldColumn;
        }

        $scope.exportSelectModel = 'exportCsv';
        $scope.exportClassification = function () {
            switch ($scope.exportSelectModel) {
                case 'exportCsv':
                    exportCsv();
                    break;
                case 'exportJs':
                    exportJs();
                    break;
                default:
            }
        };

        $scope.importSelectModel = 'importCsv';
        $scope.importClassification = function () {
            switch ($scope.importSelectModel) {
                case 'importCsv':
                    importCsv();
                    resetFile();
                    break;
                default:
            }
        };

        var closeAllDialogs = function () {
            $scope.importDialog = false;
            $scope.exportDialog = false;
        };

        $scope.importExportInfo = {
            text: "IMPORT_EXPORT",
            icon: "fa-exchange"
        };

        $scope.importExportData = [{
            text: "IMPORT",
            link: function () {
                closeAllDialogs();
                $scope.importDialog = true;
            }
        }, {
            text: "EXPORT",
            link: function () {
                closeAllDialogs();
                $scope.exportDialog = true;
            }
        }];

        $scope.displayRowNumbers = true;
        $scope.toggleRowNumbers = function () {
            $scope.displayRowNumbers = !$scope.displayRowNumbers;
        };
    }]);
})(angular);