(function (angular) {
    angular.module('app').directive('modalDialog', ["$window", "$rootScope", "DialogService", function ($window, $rootScope, DialogService) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                show: '=',
                action: '=?',
                negativeAction: '=?',
                btns: '=?',
                outputObject: '=?',
                defaultEnter: '=?'
            },
            templateUrl: 'classification/directive/modalDialog/modalDialog.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.dialogTexts = {};
                scope.positiveBtn = {};
                scope.negativeBtn = {};
                scope.dialogTexts.title = attrs.title;
                scope.dialogTexts.content = attrs.content;
                if (scope.defaultEnter == undefined) {
                    scope.defaultEnter = true;
                }

                if (scope.outputObject === null) {
                    scope.outputObject = {};
                }

                scope.isApril = $rootScope.isApril;

                if (attrs.spinner == 'true') {
                    scope.spinner = true;
                    scope.preventClose = true;
                }

                if (!scope.btns && attrs.positiveLabel) {
                    scope.positiveBtn = {
                        label: attrs.positiveLabel,
                        class: attrs.positiveClass ? attrs.positiveClass : "positive"
                    };
                    if (attrs.negativeLabel) {
                        scope.negativeBtn = {
                            label: attrs.negativeLabel,
                            class: attrs.negativeClass ? attrs.negativeClass : "neutral"
                        };
                        scope.btns = [scope.negativeBtn, scope.positiveBtn];
                    } else {
                        scope.btns = [scope.positiveBtn];
                    }
                }

                scope.$watch('show', function () {
                    if (scope.show === true) {
                        elem.addClass("show");
                    } else {
                        elem.removeClass("show");
                    }
                });

                angular.element($window).bind('keydown', function (event) {
                    if (!scope.show) {
                        return;
                    }
                    if (event.keyCode === 27) {
                        event.preventDefault();
                        scope.close();
                        scope.$apply();
                    } else if (scope.defaultEnter && event.keyCode === 13) {
                        event.preventDefault();
                        scope.positiveBtn.action();
                        scope.$apply();
                    }
                });

                scope.close = function () {
                    if (scope.preventClose) {
                        return;
                    }
                    scope.show = false;
                    DialogService.closeLastDialog();
                };

                scope.$watch('action', function () {
                    var positiveBtn = scope.positiveBtn;
                    if (!positiveBtn) {
                        return;
                    }
                    positiveBtn.action = function () {
                        if (scope.action) {
                            scope.action();
                        }

                        DialogService.closeLastDialog();
                    };
                });

                scope.$watch('negativeAction', function () {
                    var negativeBtn = scope.negativeBtn;
                    if (!negativeBtn) {
                        return;
                    }
                    negativeBtn.action = function () {
                        if (scope.negativeAction) {
                            scope.negativeAction();
                        }
                        DialogService.closeLastDialog();
                    };
                });

                attrs.$observe('title', function () {
                    scope.dialogTexts.title = attrs.title;
                });
                attrs.$observe('content', function () {
                    scope.dialogTexts.content = attrs.content;
                });
                attrs.$observe('positiveLabel', function () {
                    scope.positiveBtn.label = attrs.positiveLabel;
                });
                attrs.$observe('negativeLabel', function () {
                    scope.negativeBtn.label = attrs.negativeLabel;
                });
            }
        };
    }]);
})(angular);