(function (angular) {
    angular.module("app").service("DepartmentSchedulersManagementService", ["restUrlPublicPrefix", "$http", function (restUrlPublicPrefix, $http) {
        return {
            getDepartmentSchedulers: function (departmentCode) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'departments/' + departmentCode + "/representatives"
                });
            },
            addDepartmentScheduler: function (departmentCode, username) {
                return $http({
                    method: 'PUT',
                    url: restUrlPublicPrefix + 'departments/' + departmentCode + "/representatives/" + username
                });
            },
            deleteDepartmentScheduler: function (departmentCode, username) {
                return $http({
                    method: 'DELETE',
                    url: restUrlPublicPrefix + 'departments/' + departmentCode + "/representatives/" + username
                });
            }
        };
    }]);
})(angular);