(function (angular) {
    angular.module('app').directive('backButton', ['$window', 'BreadcrumbService', function ($window, BreadcrumbService) {
        return {
            scope: {
                data: '=?'
            },
            restrict: 'E',
            transclude: true,
            template: '<button ng-show="data.showBackButton" type="button" class="btn ico fa fa-arrow-left">' + '<ng-transclude></ng-transclude>' + '</button>',
            link: function (scope, elem, attrs) {
                if (!scope.data) {
                    scope.data = {
                        showBackButton: true
                    };
                }
                elem.bind('click', function () {
                    $window.history.back();
                });
            }
        };
    }]);
})(angular);