(function (angular) {
    angular.module("app").service("ComparatorService", function () {

        var courseCodeComparator = function (a, b) {
            var aSplit = a.split('-');
            var bSplit = b.split('-');
            if (aSplit.length != bSplit.length) {
                //AKCE < BI-PJV
                return aSplit.length < bSplit.length ? -1 : 1;
            }
            if (aSplit[0].charAt(0) != bSplit[0].charAt(0)) {
                //BI-PJV < MI-PJV
                return aSplit[0].charAt(0) < bSplit[0].charAt(0) ? -1 : 1;
            }
            for (var i = aSplit.length - 1; i >= 0; i--) {
                //compare both until BI<BIE
                if (aSplit[i] != bSplit[i]) {
                    return aSplit[i] < bSplit[i] ? -1 : 1;
                }
            }
            return 0;
        };

        return {
            courseCodeComparator: courseCodeComparator
        };
    });
})(angular);