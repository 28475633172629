(function (angular) {
    angular.module("app").service("ExternalApplicationsService", ["restUrlPublicPrefix", "$http", function (restUrlPublicPrefix, $http) {
        return {
            addExternalApplication: function (app) {
                return $http({
                    method: 'POST',
                    url: restUrlPublicPrefix + 'external-applications',
                    data: app
                });
            },
            getAllExternalApplications: function () {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'external-applications'
                });
            },
            getUsersExternalApplications: function () {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'external-applications/my'
                });
            },
            getOtherExternalApplications: function () {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'external-applications/other'
                });
            },
            deleteExternalApplication: function (appId) {
                return $http({
                    method: 'DELETE',
                    url: restUrlPublicPrefix + 'external-applications/' + appId
                });
            }
        };
    }]);
})(angular);