// Directive for dropdown menu
// -- info {icon, text}
// -- data [ {text, link}, {}, ... ]
/*
$scope.defineClassificationInfo = {
    text: "DEFINE_CLASSIFICATION",
    icon: "fa-plus"
};

$scope.defineClassificationData = [
    {
        text: "ADD_COLUMN",
        link: function () {
            closeAllDialogs();
            $scope.addingColumn = true;
        }
    }
];
*/

(function (angular) {
    angular.module('app').directive('dropdownMenu', function () {
        return {
            restrict: 'E',
            scope: {
                info: '=',
                data: '=',
                disabled: '=?'
            },
            templateUrl: 'classification/directive/dropdownMenu/dropdownMenu.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.menuVisible = false;

                scope.checkAndSwitch = function () {
                    if (scope.disabled === true) {
                        scope.menuVisible = false;
                    } else {
                        scope.menuVisible = !scope.menuVisible;
                    }
                };

                scope.callFunction = function (func) {
                    scope.menuVisible = false;
                    if (scope.disabled !== true && func) {
                        func();
                    }
                };
            }
        };
    });
})(angular);