(function (angular) {
    angular.module("app").service("DownloadUploadService", function () {
        return {
            download: function (data, name, type) {
                if (window.navigator.msSaveOrOpenBlob) {
                    var blob = new Blob([data]);
                    window.navigator.msSaveOrOpenBlob(blob, name);
                } else {
                    var a = document.createElement('a');
                    a.href = 'data:attachment/' + type + ',' + encodeURIComponent(data);
                    a.target = '_blank';
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                }
            },
            upload: function (evt, processFileContent) {
                if (!window.FileReader) return;
                var reader = new FileReader();
                reader.onload = function (evt) {
                    if (evt.target.readyState != 2) return;
                    if (evt.target.error) {
                        alert('Error while reading file');
                        return;
                    }
                    var fileContent = evt.target.result.trim();
                    processFileContent(fileContent);
                };
                reader.readAsText(evt.target.files[0]);
            }
        };
    });
})(angular);