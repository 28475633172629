(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/department-schedulers', {
            templateUrl: 'classification/pages/advancedTools/DepartmentSchedulersManagement/DepartmentSchedulersManagement.tmpl.html',
            controller: 'DepartmentSchedulersManagementCtrl',
            resolve: {
                departments: ["SecurityService", function (SecurityService) {
                    return SecurityService.getHeadOrSchedulerDepartments();
                }]
            }
        });
    }]);
})(angular);