(function (angular) {
    angular.module("app").service("MenuService", ["$location", "restUrlPrivatePrefix", "$http", "TranslateService", "AddressBuild", function ($location, restUrlPrivatePrefix, $http, TranslateService, AddressBuild) {
        var menu = {
            items: [],
            visible: true,
            isLoading: false
        };

        var menuSemesterCode;

        function getMenu() {
            return menu;
        }

        function reloadMenu(semesterCode, callback) {
            if (menu.isLoading) return;
            menu.isLoading = true;
            menu.items = null;
            menuSemesterCode = semesterCode;
            return $http({
                method: 'GET',
                url: restUrlPrivatePrefix + 'menu' + AddressBuild.parameters([['lang', TranslateService.getLanguage()], ['semesterCode', semesterCode]])
            }).then(function (response) {
                menu.items = response.data;
                prepareUrls();
                reloadMenuItems();
                if (callback) callback();
                menu.isLoading = false;
            });
        }

        function prepareUrls() {
            menu.items.forEach(function (item) {
                item.url = AddressBuild.addSemester(item.url, menuSemesterCode);
                if (item.subItems) item.subItems.forEach(function (subItem) {
                    subItem.url = AddressBuild.addSemester(subItem.url, menuSemesterCode);
                    if (subItem.subItems) subItem.subItems.forEach(function (subSubItem) {
                        subSubItem.url = AddressBuild.addSemester(subSubItem.url, menuSemesterCode);
                    });
                });
            });
        }

        function isExternalLink(url) {
            return url && url.match(/http/);
        }

        function isUrlActive(urlWithParams, currentPath) {
            if (!urlWithParams) return false;
            var url = urlWithParams.split("?")[0];
            return currentPath === url;
        }

        function reloadMenuItems() {
            var currentPath = $location.path().slice(1);
            if (!menu.items) {
                return;
            }
            menu.items.forEach(function (item) {
                item.active = isUrlActive(item.url, currentPath);
                item.external = isExternalLink(item.url);
                if (item.subItems) {
                    item.subItems.forEach(function (subItem) {
                        if (subItem.translations) {
                            var texts = TranslateService.translateDynamic(subItem.translations);
                            subItem.title = texts.title;
                            subItem.tooltip = texts.tooltip;
                        }
                        subItem.external = isExternalLink(subItem.url);
                        if (isUrlActive(subItem.url, currentPath)) {
                            subItem.active = true;
                            item.active = false;
                        } else {
                            subItem.active = false;
                        }
                        if (subItem.subItems) {
                            subItem.subItems.forEach(function (subSubItem) {
                                subSubItem.active = isUrlActive(subSubItem.url, currentPath);
                                subSubItem.external = isExternalLink(subSubItem.url);
                                if (subSubItem.active) {
                                    subItem.active = true;
                                }
                            });
                        }
                    });
                }
            });
            return menu.items;
        }

        return {
            reloadMenu: reloadMenu,
            getMenu: getMenu,
            reloadMenuItems: reloadMenuItems
        };
    }]);
})(angular);