(function (angular) {
    angular.module('app').directive('ignoreDirty', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$pristine = false;
            }
        };
    });
})(angular);