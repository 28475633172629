(function (angular) {
    angular.module("app").service("__Template__Service", ["restUrlPublicPrefix", "$http", function (restUrlPublicPrefix, $http) {
        return {
            // getSomething: function (lang) {
            //     return $http({
            //         method: 'GET',
            //         url: restUrlPublicPrefix + 'getAddress' + (lang ? '?lang=' + lang : '')
            //     });
            // },
            // sendSomething: function (data) {
            //     return $http({
            //         method: 'POST',
            //         url: restUrlPublicPrefix + 'postAddress',
            //         data: data
            //     });
            // }
        };
    }]);
})(angular);