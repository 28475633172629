(function (angular) {
    angular.module('app').service('SecurityService', ["$q", "restUrlPublicPrefix", "restUrlPrivatePrefix", "$rootScope", "$http", "$location", "DialogService", "EvolutionTeamService", function ($q, restUrlPublicPrefix, restUrlPrivatePrefix, $rootScope, $http, $location, DialogService, EvolutionTeamService) {

        // main SecurityService events are as follows:
        // format:   (event) -> (what needs to be loaded)
        // application start -> userInfo, roles, currentSemester, menu
        // changeSemester    -> roles, menu
        // getRoles          -> roles
        // fakeLogin         -> userInfo, roles, menu

        var defaultInfo = {
            username: 'loading',
            firstName: 'loading',
            lastName: 'loading',
            fullName: 'loading',
            czechSalutation: 'loading',
            admin: false
        };
        var userInfo = {};
        var adminInfo;
        var semester = {
            prev: '',
            current: '',
            next: '',
            override: undefined,
            get: function () {
                return semester.override ? semester.override : semester.current;
            },
            isCurrent: function () {
                return !semester.override;
            },
            isPrevious: function () {
                return semester.get() === semester.prev;
            },
            isNext: function () {
                return semester.get() === semester.next;
            }
        };
        var headOrSchedulerDepartments = {};

        function prepareRoles(roles) {
            var val = {
                courseRoles: {}, // has list of roles for given courses
                roleCourses: {} // has list of courses for given roles
            };
            for (var property in roles) {
                var nameSuffix = 'Courses';
                if (roles.hasOwnProperty(property) && property.indexOf(nameSuffix) !== -1) {
                    var role = property.slice(0, property.length - nameSuffix.length); // cut out the suffix
                    var roleCourses = roles[property];
                    for (var i = 0; i < roleCourses.length; ++i) {
                        var course = roleCourses[i];
                        if (!val.courseRoles[course]) val.courseRoles[course] = [];
                        val.courseRoles[course].push(role);
                        if (!val.roleCourses[role]) val.roleCourses[role] = [];
                        val.roleCourses[role].push(course);
                    }
                }
            }
            return val;
        }

        function setSemesterRoles(semesterCode, roles) {
            if (!userInfo.roles) userInfo.roles = {};
            userInfo.roles[semesterCode] = prepareRoles(roles);
            return userInfo.roles[semesterCode];
        }

        var initialize = function (newUserInfo, currentSemester, roles, newAdminInfo, semesterPrev, semesterNext) {
            userInfo.username = newUserInfo.username;
            userInfo.firstName = newUserInfo.firstName;
            userInfo.lastName = newUserInfo.lastName;
            userInfo.fullName = newUserInfo.fullName;
            userInfo.czechSalutation = newUserInfo.czechSalutation;
            userInfo.admin = newUserInfo.admin;
            if (currentSemester) semester.current = currentSemester;
            if (roles) {
                setSemesterRoles(semester.current, roles);
                headOrSchedulerDepartments = roles.headOrRepresentativeDepartmentCodeNames;
            }
            if (semesterNext) {
                semester.next = semesterNext;
            }
            if (semesterPrev) {
                semester.prev = semesterPrev;
            }

            $rootScope.username = userInfo.username;
            $rootScope.firstName = userInfo.firstName;
            $rootScope.lastName = userInfo.lastName;
            $rootScope.fullName = userInfo.fullName;
            $rootScope.czechSalutation = userInfo.czechSalutation;

            if (newAdminInfo) {
                adminInfo = newAdminInfo;
                $rootScope.adminUsername = adminInfo.username;
            }

            $rootScope.$broadcast('user-info-loaded');
        };
        var reloadSecurity = function (callback) {
            Object.assign(userInfo, defaultInfo);
            userInfo.roles = {};
            var deferred = $q.defer(); //used as return value resolve/reject

            var userInfoPromise = $http.get(restUrlPublicPrefix + "user-info", { cache: false });
            var rolesPromise = $http.get(restUrlPublicPrefix + "user-roles", { cache: false });
            $q.all([userInfoPromise, rolesPromise]).then(function (values) {
                var userInfoData = values[0].data;
                var rolesData = values[1].data;
                initialize(userInfoData, null, rolesData);
                if (userInfo.admin) {
                    $http.get(restUrlPrivatePrefix + "admin/info").success(function (adminInfoResponse) {
                        initialize(userInfoData, null, null, adminInfoResponse);
                        deferred.resolve();
                        if (callback) callback();
                    });
                } else {
                    deferred.resolve();
                    if (callback) callback();
                }
            }, function () {
                DialogService.sendNegativeNotification("ERROR_LOADING_USER_INFO");
                deferred.reject();
            });
            return deferred.promise;
        };
        $rootScope.$on('$routeChangeStart', function (e, current) {
            setSemester(current.params.semester);
        });

        function setSemester(newSemesterCode) {
            semester.override = newSemesterCode;
            if (semester.override === semester.current) semester.override = undefined;
        }

        var changeSemester = function (newSemesterCode, callback) {
            $http({
                method: 'GET',
                url: restUrlPublicPrefix + 'semester-code' + (newSemesterCode ? '?semester=' + newSemesterCode : '')
            }).then(function successCallback(response) {
                var newSemesterCode = response.data;
                if (semester.get() !== newSemesterCode) {
                    setSemester(newSemesterCode);
                    getSemesterRoles(newSemesterCode); // ping semester to fetch roles if not present
                    if (callback) callback();
                } else {
                    DialogService.sendNegativeNotification("THIS_SEMESTER_IS_ALREADY_BEING_DISPLAYED");
                }
            }, function errorCallback(response) {
                if (response.data == 'INVALID_SEMESTER') {
                    DialogService.sendNegativeNotification("INVALID_SEMESTER");
                }
            });
        };

        var getSemesterRoles = function (semesterCode) {
            var deferred = $q.defer();
            var roles = userInfo.roles[semesterCode];
            if (roles) {
                deferred.resolve(roles);
            } else {
                $http.get(restUrlPublicPrefix + "user-roles" + (semesterCode ? '?semester=' + semesterCode : '')).success(function (response, status, headers, config) {
                    var roles = setSemesterRoles(semesterCode, response);
                    deferred.resolve(roles);
                }).error(function (errResp) {
                    DialogService.sendNegativeNotification("ERROR_LOADING_ROLES");
                    deferred.reject();
                });
            }
            return deferred.promise;
        };
        return {
            initialize: initialize,
            getUserInfo: function () {
                return userInfo;
            },
            getAdminInfo: function () {
                return adminInfo;
            },
            getSemester: function () {
                return semester.get();
            },
            getRawSemester: function () {
                return semester;
            },
            getPrevSemester: function () {
                return semesterPrev;
            },
            getNextSemester: function () {
                return semesterNext;
            },
            getUsername: function () {
                return userInfo.username;
            },
            getFirstName: function () {
                return userInfo.firstName;
            },
            getLastName: function () {
                return userInfo.lastName;
            },
            getFullName: function () {
                return userInfo.fullName;
            },
            getCzechSalutation: function () {
                return userInfo.czechSalutation;
            },
            getHeadOrSchedulerDepartments: function () {
                return headOrSchedulerDepartments;
            },
            getRoles: function () {
                var deferred = $q.defer();
                getSemesterRoles(semester.get()).then(function (roles) {
                    deferred.resolve(roles);
                }, function () {
                    deferred.reject();
                });
                return deferred.promise;
            },
            // returns list of roles one has in relation to given courseCode
            getCourseRoles: function (courseCode) {
                var deferred = $q.defer();
                getSemesterRoles(semester.get()).then(function (roles) {
                    var courseRoles = roles.courseRoles[courseCode];
                    if (!courseRoles) courseRoles = [];
                    courseRoles.canEditCourse = function () {
                        return this.indexOf('guarantor') !== -1 || this.indexOf('editor') !== -1 || this.indexOf('departmentScheduler') !== -1;
                    };
                    courseRoles.canEditExternalApplications = function () {
                        return this.indexOf('guarantor') !== -1 || this.indexOf('editor') !== -1 || this.indexOf('departmentScheduler') !== -1;
                    };
                    courseRoles.isStudent = function () {
                        return this.indexOf('student') !== -1;
                    };
                    courseRoles.isTeacher = function () {
                        return this.indexOf('teacher') !== -1;
                    };
                    deferred.resolve(courseRoles);
                }, function () {
                    deferred.reject();
                });
                return deferred.promise;
            },
            // returns union of course lists of given targetRoles
            // from ['guarantor', 'teacher'] -> to ['BI-ATH', 'MI-PPA', 'BI-AG1']
            // OR from 'guarantor'           -> to ['BI-ATH', 'MI-PPA']
            getRoleCourses: function (targetRoles) {
                var deferred = $q.defer();
                getSemesterRoles(semester.get()).then(function (roles) {
                    var finalRoleCourses = [];
                    if (EvolutionTeamService.isArray(targetRoles)) {
                        targetRoles.forEach(function (item) {
                            var roleCourses = roles.roleCourses[item];
                            finalRoleCourses = EvolutionTeamService.arrayUnion(finalRoleCourses, roleCourses);
                        });
                    } else {
                        var roleCourses = roles.roleCourses[targetRoles]; // only one role
                        finalRoleCourses = EvolutionTeamService.arrayUnion(finalRoleCourses, roleCourses);
                    }
                    deferred.resolve(finalRoleCourses);
                }, function () {
                    deferred.reject();
                });
                return deferred.promise;
            },
            changeSemester: changeSemester,
            reloadSecurity: reloadSecurity
        };
    }]);
})(angular);