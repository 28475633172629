(function (angular) {
    angular.module("app").service("CoursesOverviewService", ["restUrlPublicPrefix", "$http", "AddressBuild", "SecurityService", function (restUrlPublicPrefix, $http, AddressBuild, SecurityService) {
        return {
            getCourses: function (lang) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/information' + AddressBuild.parameters([["semester", SecurityService.getSemester()], ["lang", lang]])
                });
            }
        };
    }]);
})(angular);