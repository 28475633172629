(function (angular) {
    angular.module('app').directive('sticky', ["$window", "$rootScope", function ($window, $rootScope) {
        return {
            restrict: 'EAC',
            replace: true,
            transclude: true,
            template: '<div><div class="stickyContent" ng-transclude></div></div>',
            link: function (scope, elem, attrs) {
                var stickyContent = angular.element(elem[0].getElementsByClassName('stickyContent'));
                var classList = elem[0].classList;
                for (var i = 0; i < classList.length; i++) {
                    var className = classList[i];
                    if (className != 'sticky') {
                        // elem.removeClass(className);
                        stickyContent.addClass(className);
                    }
                }
                if (attrs.hasOwnProperty('stickyBottom')) {
                    updateStickyBottomElement();
                    angular.element($window).bind('scroll resize', updateStickyBottomElement);
                    $rootScope.$on('elementUpdated', updateStickyBottomElement);
                }
                function updateStickyBottomElement() {
                    var scrollY = $window.scrollY;
                    if (!scrollY) scrollY = document.documentElement.scrollTop;
                    if (scrollY + $window.innerHeight < elem[0].offsetTop + elem[0].clientHeight) {
                        elem[0].style.height = stickyContent[0].clientHeight + 'px';
                        stickyContent[0].style.width = elem[0].clientWidth + 'px';
                        stickyContent[0].style.position = 'fixed';
                        stickyContent[0].style.bottom = 0;
                    } else {
                        stickyContent[0].style.position = 'static';
                    }
                }
            }
        };
    }]);
})(angular);