angular.module('app').directive('ignoreMouseWheel', ["$window", function ($window) {
    function linkFunction(scope, element) {
        element[0].addEventListener('focus', function () {
            element.bind('wheel', function (event) {
                event.preventDefault();
            });
        });

        element[0].addEventListener('blur', function () {
            element.unbind('wheel');
        });
    }

    return {
        restrict: 'A',
        link: linkFunction
    };
}]);