(function (angular) {
    angular.module('app').directive('note', function () {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                showNote: '=?'
            },
            transclude: true,
            template: '<i ng-class="{\'fa-comment\':!showNote, \'fa-comment-o\':showNote}" class="fa " uib-popover="{{\'TEACHER_NOTE\' | translate}}" popover-trigger="\'mouseenter\'" ' + 'popover-append-to-body="true"></i>',
            link: function (scope, element, attrs) {
                if (!scope.showNote) {
                    scope.showNote = false;
                }
                attrs.$observe('text', function () {
                    scope.text = attrs.text;
                });
            }
        };
    });
})(angular);