(function (angular) {
    angular.module("app").controller("StudentClassificationCtrl", ["$rootScope", "$scope", "$route", "AutocompleteService", "SecurityService", "DialogService", "ClassificationService", "DateTimeService", "$location", "MyClassificationService", "ExpressionService", "TranslateService", "courseInformation", "smartTableStringColumnLimit", "classificationsDefinitions", "courseRoles", "AddressBuild", function ($rootScope, $scope, $route, AutocompleteService, SecurityService, DialogService, ClassificationService, DateTimeService, $location, MyClassificationService, ExpressionService, TranslateService, courseInformation, smartTableStringColumnLimit, classificationsDefinitions, courseRoles, AddressBuild) {
        $scope.courseCode = $route.current.params.courseCode;
        $scope.courseName = courseInformation.courseName;
        $scope.availableDataTypes = ExpressionService.getDataTypes();
        $scope.smartTableStringColumnLimit = smartTableStringColumnLimit;
        $scope.classificationDefinitions = classificationsDefinitions;
        $scope.canEditCourse = courseRoles.canEditCourse();
        $scope.viewAsStudentLink = 'courses/' + $scope.courseCode + '/students/teststudent1' + AddressBuild.parameters([['view', ''], ['semester', SecurityService.getSemester()]]);
        $scope.getUsers = function (query) {
            return AutocompleteService.autocompleteStudentsInCourse(query, $scope.courseCode, SecurityService.getSemester()).then(function (response) {
                return response.data;
            });
        };

        var reloadOriginalDataMap = function () {
            $scope.originalDataMap = {};
            $scope.studentClassifications.studentClassificationFullDtos.forEach(function (classification) {
                $scope.originalDataMap[classification.identifier] = {
                    value: classification.value,
                    note: classification.note ? classification.note : ''
                };
            });
        };

        var loadClassification = function (username, semesterCode, courseCode) {
            MyClassificationService.getStudentClassification(semesterCode, courseCode, username, TranslateService.getLanguage()).then(function (response) {
                $scope.studentClassifications = response.data;
                $scope.studentClassifications.studentClassificationFullDtos = ClassificationService.buildPostfixClassificationDefinitions(response.data.studentClassificationFullDtos);
                $scope.userInput = { username: $scope.studentClassifications.username, fullName: $scope.studentClassifications.fullName };
                reloadOriginalDataMap();
                $scope.loaded = true;
            });
        };

        var selectedUsername;
        if ($route.current.params.username) {
            selectedUsername = $route.current.params.username;
            loadClassification(selectedUsername, SecurityService.getSemester(), $scope.courseCode);
        } else {
            selectedUsername = '';
        }

        $scope.selectStudent = function () {
            selectedUsername = $scope.userInput.hasOwnProperty("username") ? $scope.userInput.username : $scope.userInput;
            $scope.getUsers(selectedUsername).then(function (response) {
                var user = response.find(function (item) {
                    return item.username === selectedUsername;
                });
                if (!user) {
                    DialogService.sendNegativeNotification("INPUT_INVALID_USER");
                } else {
                    $location.search('username', selectedUsername);
                    loadClassification(selectedUsername, SecurityService.getSemester(), $scope.courseCode);
                }
            });
        };

        $scope.getAggregationHelpText = function (classification) {
            var parallelCode = $scope.studentClassifications.parallelCodes[classification.aggregationScope];
            return classification.aggregationFunction + ' of ' + classification.aggregatedIdentifier + ' over ' + classification.aggregationScope + (!parallelCode ? '' : ' with code ' + parallelCode);
        };

        function doSave(form) {
            $scope.confirmDialogData.show = false;
            var save = [];
            var loadingDialog = DialogService.createLoadingDialog('SAVING');

            $scope.studentClassifications.studentClassificationFullDtos.forEach(function (classification) {
                if (classification.evaluationType == "MANUAL") {

                    var originalData = $scope.originalDataMap[classification.identifier];
                    if (classification.valueType == 'STRING' && classification.value == '') {
                        classification.value = null;
                    }
                    if (classification.note == null) {
                        classification.note = '';
                    }

                    if (originalData.value != classification.value || originalData.note != classification.note) {
                        save.push({
                            studentUsername: selectedUsername,
                            classificationIdentifier: classification.identifier,
                            value: classification.value,
                            note: classification.note
                        });
                    }
                }
            });
            if (save.length === 0) {
                DialogService.hideDialog(loadingDialog);
                DialogService.sendNeutralNotification("NO_CHANGES_WERE_MADE");
            } else {
                var notify = $scope.confirmDialogData.value;
                ClassificationService.saveStudentClassifications($scope.courseCode, SecurityService.getSemester(), save, notify).success(function (response) {
                    DialogService.hideDialog(loadingDialog);
                    loadClassification(selectedUsername, SecurityService.getSemester(), $scope.courseCode);
                    DialogService.sendPositiveNotification($rootScope.isApril ? "APRIL_A_TO_ALL_STUDENTS" : "CHANGES_SUCCESSFULLY_SAVED");
                    if (form) form.$setPristine();
                })["catch"](function (error) {
                    DialogService.hideDialog(loadingDialog);
                });
            }
        }

        $scope.confirmDialogAction = function (form) {
            doSave(form);
        };

        $scope.confirmDialogData = {
            value: true,
            show: false
        };
        $scope.save = function () {
            $scope.confirmDialogData.value = true;
            $scope.confirmDialogData.show = true;
        };

        $scope.formatDate = function (timestamp) {
            return DateTimeService.createDateInPlainFormat(timestamp);
        };
    }]);
})(angular);