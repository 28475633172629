(function (angular) {
    angular.module("app").service("MarkService", function () {
        var stringToColour = { //precalculated values
            "A": "hsla(108, 90%, 44%", //hsla(95,50,100)
            "B": "hsla(94, 90%, 44%", //hsla(85,50,100)
            "C": "hsla(80, 90%, 44%", //hsla(75,50,100)
            "D": "hsla(66, 90%, 45%", //hsla(65,50,100)
            "E": "hsla(52, 90%, 45%", //hsla(55,50,100)
            "F": "rgba(219, 53, 42" //hsla(45,50,100)
        };

        var getMarkColour = function (value, opacity) {
            if (opacity) {
                return stringToColour[value.toUpperCase()] + ", " + opacity + ")";
            } else {
                return stringToColour[value.toUpperCase()] + ")";
            }
        };

        var isMark = function (value) {
            if (typeof value === 'string') {
                return stringToColour.hasOwnProperty(value.toUpperCase());
            }
        };

        return {
            isMark: isMark,
            hsla: function (value, minValue, reqValue, maxValue, opacity) {
                if (isMark(value)) {
                    return getMarkColour(value.toUpperCase(), opacity);
                }
                if (!maxValue) {
                    return;
                }
                if (!reqValue) {
                    reqValue = !minValue ? 0 : minValue;
                }
                if (!opacity) {
                    opacity = 1;
                }
                if (value < reqValue) {
                    return "rgba(219, 53, 42, " + opacity + ")";
                }
                // if (value >= maxValue) {
                //     return "hsla(114, 50%, 43%, " + opacity + ")";
                // }
                var startColor = [45, 90, 45];
                var endColor = [114, 90, 43];
                var percentage = (value - reqValue) / (maxValue - reqValue);
                if (percentage > 1) percentage = 1;
                var color = [0, 0, 0];
                for (var i = 0; i < 3; i++) {
                    color[i] = Math.ceil(startColor[i] + percentage * (endColor[i] - startColor[i]));
                }
                return "hsla(" + color[0] + ", " + color[1] + "%, " + color[2] + "%, " + opacity + ")";
            }
        };
    });
})(angular);