(function (angular) {
    angular.module('app').config(["$routeProvider", "$locationProvider", function ($routeProvider, $locationProvider) {
        $routeProvider.when('/home', {
            templateUrl: 'classification/pages/home/home.tmpl.html',
            controller: 'HomeCtrl',
            resolve: {
                courseStatistics: ["CourseStatisticsService", "SecurityService", function (CourseStatisticsService, SecurityService) {
                    var semesterCode = SecurityService.getSemester();
                    return CourseStatisticsService.getMyCourseStatistics(semesterCode, false).then(function (response) {
                        return response.data;
                    });
                }],
                roles: ["SecurityService", function (SecurityService) {
                    return SecurityService.getRoles().then(function (response) {
                        return response;
                    });
                }],
                myClassificationOverview: ["MyClassificationService", "TranslateService", "SecurityService", function (MyClassificationService, TranslateService, SecurityService) {
                    var semesterCode = SecurityService.getSemester();
                    var username = SecurityService.getUsername();
                    var lang = TranslateService.getLanguage();
                    return MyClassificationService.getClassificationOverview(semesterCode, username, lang).then(function (response) {
                        return response.data;
                    });
                }]
            }
        });

        $routeProvider.otherwise({
            redirectTo: '/home'
        });

        $locationProvider.html5Mode(true);
    }]);

    angular.module('app').run(["SecurityService", "WebsocketService", "$rootScope", "$route", "userInfo", "semester", "semesterPrev", "semesterNext", "roles", "adminInfo", "DateTimeService", function (SecurityService, WebsocketService, $rootScope, $route, userInfo, semester, semesterPrev, semesterNext, roles, adminInfo, DateTimeService) {
        $rootScope.angularLoaded = true;
        $rootScope.isApril = DateTimeService.isApril();
        SecurityService.initialize(userInfo, semester, roles, adminInfo, semesterPrev, semesterNext);
        if (!WebsocketService.isManuallyDisabled()) {
            WebsocketService.connect();
        }
    }]);
})(angular);