(function (angular) {
    angular.module('app').directive('userPreview', function () {
        return {
            restrict: 'A',
            replace: false,
            transclude: {
                'btnSlot': '?btns'
            },
            scope: { user: '=' },
            template: '<classification-preview headline="{{user.fullName}}" img-src="img/ico-user-xlarge.png"><btns ng-transclude="btnSlot"></btns> </classification-preview>',
            link: function (scope, elem, attrs) {}
        };
    });
})(angular);