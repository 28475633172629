(function (angular) {
    angular.module("app").controller("DepartmentSchedulersManagementCtrl", ["departments", "$rootScope", "$translate", "$scope", "AutocompleteService", "TranslateService", "DepartmentSchedulersManagementService", function (departments, $rootScope, $translate, $scope, AutocompleteService, TranslateService, DepartmentSchedulersManagementService) {

        $scope.loading = false;
        $scope.departments = departments || {};
        $scope.lang = TranslateService.getLanguage();
        $scope.numOfDepartments = Object.keys($scope.departments).length;
        for (var prop in $scope.departments) {
            $scope.selectedDepartmentCode = prop;
            break;
        }

        $rootScope.$on('$translateChangeSuccess', function (event, current, previous) {
            $scope.lang = current.language;
        });

        $scope.autocompleteFunc = AutocompleteService.autocompleteUsers;
        $scope.removeScheduler = DepartmentSchedulersManagementService.deleteDepartmentScheduler;
        $scope.addScheduler = DepartmentSchedulersManagementService.addDepartmentScheduler;
        $scope.getSchedulers = DepartmentSchedulersManagementService.getDepartmentSchedulers;
    }]);
})(angular);