(function (angular) {
    angular.module("app").service("CourseService", ["restUrlPublicPrefix", "$http", "AddressBuild", function (restUrlPublicPrefix, $http, AddressBuild) {
        return {
            loadCourseInformation: function (courseCode, semesterCode, lang) {
                return $http({
                    method: 'GET',
                    url: restUrlPublicPrefix + 'courses/' + courseCode + '/information/' + AddressBuild.parameters([['semester', semesterCode], ['lang', lang]])
                });
            },
            courseRequiresAssessment: function (courseDto) {
                return courseDto.completion === "CREDIT" || courseDto.completion === "CREDIT_EXAM";
            },
            courseRequiresFinalScore: function (courseDto) {
                return courseDto.completion === "CREDIT_EXAM" || courseDto.completion === "EXAM" || courseDto.completion === "CLFD_CREDIT";
            },
            courseRequiresExam: function (courseDto) {
                return courseDto.completion === "CREDIT_EXAM" || courseDto.completion === "EXAM";
            },
            courseRequiresClassifiedCredit: function (courseDto) {
                return courseDto.completion === "CLFD_CREDIT";
            }
        };
    }]);
})(angular);