(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/documentation/:address', {
            templateUrl: 'classification/pages/teacher/Documentation/Documentation.tmpl.html',
            controller: 'DocumentationCtrl',
            resolve: {
                markdownDocument: ["TranslateService", "DocumentationService", "$rootScope", "$route", function (TranslateService, DocumentationService, $rootScope, $route) {
                    documentAddress = $route.current.params.address;
                    lang = TranslateService.getLanguage();
                    return DocumentationService.getMarkdownDocument(documentAddress, lang).then(function (response) {
                        return response.data;
                    });
                }]
            }
        });
    }]);
})(angular);