(function (angular) {
    customInterceptor.$inject = ["$q", "$rootScope", "DialogService", "$translate"];
    angular.module('app').factory('customInterceptor', customInterceptor);

    angular.module('app').config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('customInterceptor');
    }]);

    function customInterceptor($q, $rootScope, DialogService, $translate) {
        return {
            // request: function (request) {}
            // requestError: function (request) {}
            // response: function (response) {}
            responseError: function (response) {
                try {
                    if (response.status === 403) {
                        $rootScope.forbidden = true;
                    }
                    if (response.status === 400) {
                        if (typeof response.data !== "string") {
                            DialogService.sendNegativeNotification($translate.instant("UNEXPECTED_ERROR")); // sometimes race condition coursestatistics on landing page
                        } else {
                            DialogService.sendNegativeNotification($translate.instant(response.data));
                            $rootScope.angularLoaded = true;
                            DialogService.dialogs.filter(function (d) {
                                return d.spinner === true;
                            }).forEach(function (d) {
                                d.shown = false;
                            });
                        }
                    }
                    if (response.headers('Content-Type') == 'text/plain; charset=UTF-8') {
                        DialogService.sendNegativeNotification($translate.instant(response.data));
                    }
                    if (response.status === 500) {
                        DialogService.sendNegativeNotification($translate.instant("UNEXPECTED_ERROR"));
                    }
                    return $q.reject(response);
                } catch (err) {
                    return $q.reject(response);
                }
            }
        };
    }
})(angular);