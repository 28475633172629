(function (angular) {
    angular.module('app').directive('classificationPreview', function () {
        return {
            restrict: 'EA',
            replace: false,
            transclude: {
                'subtitleSlot': '?subtitle',
                'btnSlot': '?btns',
                'keywordsSlot': '?keywords'
            },
            scope: {},
            templateUrl: 'classification/template/preview.tmpl.html',
            link: function (scope, elem, attrs) {
                scope.title = attrs.headline;
                scope.titleUrl = attrs.headlineUrl;
                scope.subtitle = attrs.subtitle;
                scope.subtitleUrl = attrs.subtitleUrl;
                scope.imgSrc = attrs.imgSrc;
                scope.altImgSrc = attrs.altImgSrc;
                scope.description = attrs.description;
            }
        };
    });
})(angular);