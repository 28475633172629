(function (angular) {
    angular.module('app').config(["$routeProvider", function ($routeProvider) {
        $routeProvider.when('/courses/:courseCode', {
            templateUrl: 'classification/pages/common/CourseDetail/CourseDetail.tmpl.html',
            controller: 'CourseDetailCtrl',
            resolve: {
                implicitRedirect: ["$location", "$route", "SecurityService", function ($location, $route, SecurityService) {
                    var courseCode = $route.current.params.courseCode;
                    return SecurityService.getCourseRoles(courseCode).then(function (courseRoles) {
                        if (courseRoles.isStudent()) {
                            $location.path('/courses/' + courseCode + '/my-classification');
                        } else if (courseRoles.isTeacher()) {
                            $location.path('/courses/' + courseCode + '/classification');
                        }
                    });
                }]
            }
        });
    }]);
})(angular);