(function (angular) {
    angular.module("app").controller("CourseSkillCtrl", ["$scope", "CourseSkillService", "courseInformation", "courseSkills", "$routeParams", "DialogService", "TranslateService", function ($scope, CourseSkillService, courseInformation, courseSkills, $routeParams, DialogService, TranslateService) {
        $scope.courseCode = $routeParams.courseCode;
        $scope.courseName = courseInformation.courseName;
        $scope.courseSkills = courseSkills;

        $scope.save = function (courseSkill, courseSkillForm) {
            courseSkill.submitting = true;
            if (isNaN(courseSkill.weight) || courseSkill.weight <= 0 || courseSkill.weight > 100) {
                DialogService.sendNegativeNotification("INVALID_WEIGHT");
                courseSkill.submitting = false;
            }

            CourseSkillService.save($scope.courseCode, courseSkill.skillDto.skillId, courseSkill.weight).then(function (response) {
                courseSkill.courseSkillId = response.data;
                courseSkill.edit = null;
            })["finally"](function () {
                courseSkill.submitting = false;
            });
        };

        $scope.startEdit = function (courseSkill) {
            courseSkill.original = angular.copy(courseSkill);
            courseSkill.edit = true;
        };
        $scope.cancelEdit = function (courseSkill) {
            if (!courseSkill.courseSkillId) {
                _.remove($scope.courseSkills, courseSkill);
                return;
            }
            angular.extend(courseSkill, courseSkill.original);
            courseSkill.edit = false;
        };

        $scope.selectAll = function () {
            for (var i = $scope.courseSkills.length - 1; i >= 0; i--) {
                $scope.courseSkills[i].selected = true;
            }
        };
        $scope.deselectAll = function () {
            for (var i = $scope.courseSkills.length - 1; i >= 0; i--) {
                $scope.courseSkills[i].selected = false;
            }
        };

        $scope.groupEdit = function () {
            for (var i = $scope.courseSkills.length - 1; i >= 0; i--) {
                $scope.courseSkills[i].edit = false;
            }
            $scope.groupEditation = true;
        };
        $scope.cancelGroupEditation = function () {
            for (var i = $scope.courseSkills.length - 1; i >= 0; i--) {
                $scope.courseSkills[i].selected = false;
            }
            $scope.groupEditation = false;
        };

        $scope.deleteSelected = function () {
            var title = TranslateService.translate('REMOVE');
            var body = TranslateService.translate('DO_YOU_WANT_TO_REMOVE_COURSE_SKILLS');
            var yes = TranslateService.translate('YES');
            var no = TranslateService.translate('NO');
            DialogService.createDecisionDialog(title, body, function () {
                for (var i = $scope.courseSkills.length - 1; i >= 0; i--) {
                    if ($scope.courseSkills[i].selected) {
                        CourseSkillService["delete"]($scope.courseCode, $scope.courseSkills[i].skillDto.skillId).then(function (value) {});
                        $scope.courseSkills.splice(i, 1);
                    }
                }
            }, function () {}, yes, no);
        };

        $scope.delete = function (courseSkill) {
            var title = TranslateService.translate('REMOVE');
            var body = TranslateService.translate('DO_YOU_WANT_TO_REMOVE_COURSE_SKILL');
            var yes = TranslateService.translate('YES');
            var no = TranslateService.translate('NO');
            DialogService.createDecisionDialog(title, body, function () {
                CourseSkillService["delete"]($scope.courseCode, courseSkill.skillDto.skillId).then();
                $scope.courseSkills.splice($scope.courseSkills.indexOf(courseSkill), 1);
            }, function () {
                courseSkill.edit = false;
            }, yes, no);
        };

        function createSkillDto(name, id, nameToDisplay) {
            return {
                name: name,
                skillId: id,
                nameToDisplay: nameToDisplay
            };
        }

        function createCourseSkill(skillDto, weight, edit, courseCode) {
            if (courseCode === null) courseCode = $scope.courseCode;
            if (edit === null) edit = false;
            if (weight === null) weight = 50;

            return {
                courseCode: courseCode,
                skillDto: skillDto,
                weight: weight,
                edit: edit
            };
        }

        $scope.addSkill = function (skillDto) {
            $scope.skillInput = '';
            if (skillDto.skillId < 0) {
                // see about 20 lines below
                CourseSkillService.createSkill(skillDto.name).then(function (response) {
                    var idOfNewSkill = response.data;
                    var newSkillDto = createSkillDto(skillDto.name, idOfNewSkill, skillDto.name);
                    $scope.addSkill(newSkillDto);
                }, function (response) {
                    DialogService.sendNegativeNotification('COULDNT_CREATE_SKILL');
                });
            } else {
                var courseSkill = createCourseSkill(skillDto, 50, true);
                $scope.courseSkills.unshift(courseSkill);
            }
        };

        $scope.getSkills = function (query) {
            return CourseSkillService.autocompleteSkills(query).then(function (response) {
                var foundSkills = response.data;
                if (!Array.isArray(foundSkills) || !foundSkills.length) {
                    // -1 will indicate that such skill doesn't exist, handle further
                    return [createSkillDto(query, -1, query + ' (' + TranslateService.translate('DOESNT_EXIST_CREATE') + ')')];
                }

                foundSkills.forEach(function (skill) {
                    skill.nameToDisplay = skill.name;
                });

                return foundSkills;
            });
        };
    }]);
})(angular);