(function (angular) {
    angular.module('app').directive('expressionEditor', ["$sanitize", "$timeout", "$window", "ExpressionService", "DialogService", "$translate", "$rootScope", function ($sanitize, $timeout, $window, ExpressionService, DialogService, $translate, $rootScope) {
        return {
            restrict: 'EA',
            replace: true,
            transclude: false,
            scope: {
                ngModel: '=',
                availableVariables: '=',
                expectedResultType: '=',
                viewMode: '=?',
                validationResult: '=?',
                rows: '=?',
                successCallback: '=?',
                ignoreDirty: '=?'
            },
            templateUrl: 'classification/template/expression-editor.tmpl.html',
            link: function (scope, elem, attrs) {
                if (!scope.rows) scope.rows = 10;

                var localDeclaredVariables = {};

                ExpressionService.getFunctions().then(function (response) {
                    scope.availableFunctions = response.data;
                });
                scope.availableDataTypes = ExpressionService.getDataTypes();

                var validationTimer;

                var popover = angular.element('<span class="ee-popover"></span>');
                elem.append(popover);

                var expressionInput;
                var expressionVisualization;

                var regexes = [];
                scope.regexMatches = [];

                scope.input = scope.ngModel;

                scope.selectSuggestion = function (i) {
                    if (!scope.suggestions || !scope.suggestions[i]) {
                        return;
                    }
                    for (var j = 0; j < scope.suggestions.length; j++) {
                        scope.suggestions[j].selected = false;
                    }
                    scope.suggestions[i].selected = true;
                    scope.selectedSuggestion = scope.suggestions[i];
                    if (!$rootScope.$$phase) {
                        scope.$apply();
                    }
                };

                scope.selectNextSuggestion = function () {
                    var i = scope.suggestions.indexOf(scope.selectedSuggestion);
                    if (i < scope.suggestions.length - 1) {
                        scope.selectSuggestion(i + 1);
                    } else {
                        scope.selectSuggestion(0);
                    }
                };

                scope.selectPrevSuggestion = function () {
                    var i = scope.suggestions.indexOf(scope.selectedSuggestion);
                    if (i > 0) {
                        scope.selectSuggestion(i - 1);
                    } else {
                        scope.selectSuggestion(scope.suggestions.length - 1);
                    }
                };

                scope.useSuggestion = function () {
                    var text = scope.selectedSuggestion.text;
                    var cursorCorrection = 0;
                    if (scope.selectedSuggestion.type == 'function') {
                        text += '()';
                        cursorCorrection = -1;
                    }
                    var newCursorPosition = scope.suggestionWordStart + text.length + cursorCorrection;

                    scope.input = insertStringBetween(scope.input, text, scope.suggestionWordStart, scope.suggestionWordEnd);

                    scope.doMagicWithInput();

                    if (!$rootScope.$$phase) {
                        scope.$apply();
                    }

                    expressionInput[0].selectionStart = newCursorPosition;
                    expressionInput[0].selectionEnd = newCursorPosition;

                    scope.removeSuggestions();
                };

                scope.removeSuggestions = function () {
                    if (!scope.suggestions && !scope.selectedSuggestion) return;
                    scope.suggestions = null;
                    scope.selectedSuggestion = null;
                    if (!$rootScope.$$phase) {
                        scope.$apply();
                    }
                };
                // states while analyzing expression & caret state
                var normalState = 0;
                var stringState = 1;
                var regexState = 2;

                scope.plainToFormattedText = function (text) {
                    if (!text) {
                        text = '';
                    }
                    var outputText = '';
                    var charCounter = 0;
                    var rowCounter = 1;
                    scope.wordBeforeCaret = scope.getWordBeforeCaret();
                    scope.caretState = 0;
                    scope.caretPosition = expressionInput[0].selectionStart;

                    var invalidPart = false;

                    outputText += '<div><span class="ee-line-number">' + rowCounter + '</span><span class="ee-input-row">';
                    if (!text[0] || isNewLine(text[0])) {
                        outputText += '&nbsp;';
                    }

                    var stringEscapeChar = '\\';
                    var stringEscapeState = 7;
                    var pairChar = [{ character: '\'', state: stringState, class: 'ee-input-string' }, { character: '`', state: regexState, class: 'ee-input-regex ' }];
                    var findByCharacter = function (char) {
                        var res;
                        pairChar.forEach(function (element) {
                            if (element.character == char) {
                                res = element;
                            }
                        });
                        return res;
                    };
                    var findByState = function (state) {
                        var res;
                        pairChar.forEach(function (element) {
                            if (element.state == state) {
                                res = element;
                            }
                        });
                        return res;
                    };
                    var state = normalState;
                    var nextChar = 0;
                    regexes = [];
                    while (text[charCounter]) {
                        var originalChar = text[charCounter];
                        nextChar = text[charCounter + 1];
                        var prevChar = text[charCounter - 1];
                        var char = originalChar;
                        if (originalChar == '<') {
                            char = '&lt;';
                        } else if (originalChar == '>') {
                            char = '&gt;';
                        } else if (originalChar == '&') {
                            char = '&amp;';
                        } else if (originalChar == ' ' && (nextChar == ' ' || !prevChar || isNewLine(prevChar))) {
                            char = '&nbsp;';
                        }
                        var pairCharacter = findByCharacter(char);

                        if (state == normalState && pairCharacter != undefined) {
                            if (pairCharacter.state == stringState) {
                                outputText += '<span class="' + pairCharacter.class + '">';
                            }
                            if (pairCharacter.state == regexState) {
                                outputText += '<span class="' + pairCharacter.class + ' ee-popover-ready" popover-eval="regexMatches[' + regexes.length + ']">';
                            }
                        }

                        if (isNewLine(char)) {
                            rowCounter++;
                            if (state != normalState) {
                                outputText += '</span>';
                            }
                            outputText += '</span></div>';
                            outputText += '<div><span class="ee-line-number">' + rowCounter + '</span><span class="ee-input-row">';
                            if (state != normalState) {
                                var enclosingChar = findByState(state);
                                if (enclosingChar != undefined) {
                                    outputText += '<span class="' + enclosingChar.class + '">';
                                }
                            }
                            if (!nextChar || isNewLine(nextChar)) {
                                outputText += '&nbsp;';
                            }
                        } else {
                            if (state == normalState && scope.wordBeforeCaret && scope.caretPosition - scope.wordBeforeCaret.length == charCounter) {
                                scope.suggestionWordStart = scope.caretPosition - scope.wordBeforeCaret.length;
                                outputText += '<span class="ee-autocomplete">';
                            }
                            if (scope.validationResult && !scope.validationResult.valid && scope.validationResult.start != scope.validationResult.end) {
                                if (charCounter == scope.validationResult.start || invalidPart && isNewLine(prevChar)) {
                                    outputText += '<span class="ee-input-invalid ee-popover-ready" popover-title="' + scope.validationResult.message + '">';
                                    invalidPart = true;
                                }
                                outputText += char;
                                if (charCounter == scope.validationResult.end - 1 || invalidPart && (isNewLine(nextChar) || !nextChar)) {
                                    outputText += '</span>';
                                    invalidPart = false;
                                }
                            } else {
                                outputText += char;
                            }
                            if (state == normalState && scope.wordBeforeCaret && scope.caretPosition - 1 == charCounter) {
                                scope.suggestionWordEnd = scope.caretPosition;
                                outputText += '</span>';
                            }
                        }

                        if (pairCharacter != undefined) {
                            // first end
                            if (state == pairCharacter.state) {
                                outputText += '</span>';
                                state = normalState;
                                pairCharacter = undefined;
                            }
                        }
                        if (state == regexState) {
                            // catch what is between
                            regexes[regexes.length - 1] += char;
                        }
                        if (pairCharacter != undefined) {
                            // then begin
                            if (state == normalState) {
                                state = pairCharacter.state;
                                if (state == regexState) {
                                    regexes.push('');
                                }
                            }
                        }
                        if (state == stringEscapeState) {
                            state = stringState;
                        }
                        if (char == stringEscapeChar && state == stringState) {
                            state = stringEscapeState;
                        }
                        charCounter++;
                        if (charCounter == scope.caretPosition) {
                            scope.caretState = state;
                        }
                    }
                    findRegexMatches();
                    if (!nextChar) {
                        outputText += '&nbsp;';
                    }
                    outputText += '</span></div>';
                    if (scope.validationResult && !scope.validationResult.valid) {
                        outputText += '<span class="ee-input-invalid ee-popover-ready exclamation-mark" popover-title="' + scope.validationResult.message + '">!</span>';
                    } else if (scope.validationResult && scope.validationResult.valid) {
                        outputText += '<span class="ee-input-valid ee-popover-ready tick" popover-title="' + $translate.instant('EXPRESSION_IS_VALID') + '"><i class="fa fa-check"/></span>';
                    }
                    return outputText;
                };

                scope.makeAutoComplete = function () {
                    if (scope.viewMode || scope.caretState != normalState) {
                        return;
                    }
                    var wordBeforeCaret = scope.wordBeforeCaret;
                    scope.removeSuggestions();
                    if (!wordBeforeCaret) {
                        return;
                    }
                    var caretElement = angular.element(elem[0].getElementsByClassName('ee-autocomplete'))[0];
                    if (caretElement) {
                        var suggestionsElement = angular.element(elem[0].getElementsByClassName('ee-suggestions'))[0];
                        suggestionsElement.style = {};
                        var left = caretElement.offsetLeft;
                        var right = left + caretElement.offsetWidth;
                        var top = caretElement.offsetTop;
                        var bottom = top + caretElement.offsetHeight;
                        suggestionsElement.style.display = 'block';
                        suggestionsElement.style.left = left + 'px';
                        suggestionsElement.style.top = bottom + 'px';
                    }

                    scope.suggestions = scope.searchVariable(wordBeforeCaret, scope.availableVariables, 'variable');
                    if (localDeclaredVariables) {
                        scope.suggestions = scope.suggestions.concat(scope.searchVariable(wordBeforeCaret, localDeclaredVariables, 'variable'));
                    }
                    scope.suggestions = scope.suggestions.concat(scope.search(wordBeforeCaret, scope.availableFunctions, 'function'));
                    scope.suggestions = scope.suggestions.concat(scope.search(wordBeforeCaret, ExpressionService.getKeywords(), 'keyword'));
                    scope.selectSuggestion(0);
                };

                scope.getWordBeforeCaret = function () {
                    var caretPosition = expressionInput[0].selectionStart;
                    if (!scope.input) {
                        return '';
                    }
                    var substr = scope.input.substring(0, caretPosition).replace(/(?:\r\n|\r|\n)/g, ' ').trim();
                    var lastCharacter = substr[caretPosition - 1];
                    var regExp = /^(.*[^\$\._a-zA-Z0-9])?([\$_a-zA-Z][\$\._a-zA-Z0-9]*)$/;
                    if (!lastCharacter || !substr.match(regExp)) {
                        return '';
                    }
                    var result = substr.replace(regExp, '$2');
                    if (!result) {
                        result = '';
                    }
                    return result;
                };

                scope.searchVariable = function (word, list, type) {
                    var results = [];
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            var valueType = list[key];
                            if (typeof key == 'string' && key.toLowerCase().indexOf(word.toLowerCase()) == 0) {
                                results.push({ text: key, type: type, selected: false, valueType: valueType });
                            }
                        }
                    }
                    return results.sort(function (a, b) {
                        if (a.text.includes(".") && !b.text.includes(".")) return 1;else if (!a.text.includes(".") && b.text.includes(".")) return -1;else return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
                    });
                };

                scope.search = function (word, list, type) {
                    var results = [];
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            var item = list[key];
                            if (typeof item == 'string' && item.toLowerCase().indexOf(word.toLowerCase()) == 0) {
                                results.push({ text: item, type: type, selected: false });
                            }
                        }
                    }
                    return results;
                };

                function findRegexMatches() {
                    scope.regexMatches = [];
                    regexes.forEach(function (regex) {
                        var newMatches = [];
                        try {
                            Object.keys(scope.availableVariables).forEach(function (variableIdentifier) {
                                if (variableIdentifier.match(new RegExp('^' + regex + '$', "i"))) {
                                    newMatches.push(variableIdentifier);
                                }
                            });
                        } catch (err) {}
                        newMatches.sort();
                        scope.regexMatches.push(newMatches);
                    });
                }

                function validateExpression() {
                    if (scope.input && expressionVisualization) {
                        var expressionParseRequest = {
                            expression: scope.input,
                            expectedResultType: scope.expectedResultType,
                            variableValueTypes: scope.availableVariables
                        };
                        ExpressionService.tryValidity(expressionParseRequest).then(function (response) {
                            scope.validationResult = response.data;
                            localDeclaredVariables = scope.validationResult.localDeclaredVariables;
                            expressionVisualization[0].innerHTML = scope.plainToFormattedText(scope.input);
                            if (scope.successCallback && scope.validationResult.valid) scope.successCallback(scope.input);
                        })["catch"](function (error) {
                            DialogService.sendNegativeNotification('internal error while validating expression');
                        });
                    }
                }

                scope.doMagicWithInput = function () {
                    scope.validationResult = null;
                    $timeout.cancel(validationTimer);
                    expressionVisualization[0].innerHTML = scope.plainToFormattedText(scope.input);
                    if (!scope.selectedSuggestion) {
                        scope.makeAutoComplete();
                    }
                    scope.ngModel = scope.input;
                    if (!scope.input) {
                        if (scope.successCallback) scope.successCallback(scope.input);
                        return;
                    }

                    validationTimer = $timeout(function () {
                        validateExpression();
                    }, 1000);
                };

                angular.element(document).ready(function () {
                    expressionVisualization = angular.element(elem[0].getElementsByClassName('ee-visualization')[0]);
                    expressionInput = angular.element(elem[0].getElementsByClassName('ee-input')[0]);
                    popover = popover[0];
                    getAssociatedLabel();
                    scope.$watch('input', function () {
                        scope.removeSuggestions();
                        scope.doMagicWithInput();
                    });
                    scope.$watch('expectedResultType', function () {
                        scope.doMagicWithInput();
                    });

                    scope.ctrlDown = false;

                    elem.bind("keyup", function (e) {
                        switch (e.keyCode) {
                            case 17:
                                //ctrl
                                scope.ctrlDown = false;
                                break;
                        }
                    });

                    elem.bind('keydown', function (e) {
                        switch (e.keyCode) {
                            case 27: //esc
                            case 37: //left
                            case 39:
                                //right
                                scope.removeSuggestions();
                                break;
                            case 38:
                                //up
                                if (scope.selectedSuggestion) {
                                    e.preventDefault();
                                    scope.selectPrevSuggestion();
                                }
                                break;
                            case 40:
                                //down
                                if (scope.selectedSuggestion) {
                                    e.preventDefault();
                                    scope.selectNextSuggestion();
                                }
                                break;
                            case 9: //tab
                            case 13:
                                //enter
                                if (scope.selectedSuggestion) {
                                    e.preventDefault();
                                    scope.useSuggestion();
                                }
                                break;
                            case 17:
                                //ctrl
                                scope.ctrlDown = true;
                                break;
                            case 32:
                                //space
                                if (scope.ctrlDown) {
                                    scope.getWordBeforeCaret(true);
                                    scope.makeAutoComplete();
                                    // scope.selectSuggestion(0);
                                }
                                break;
                        }
                        // console.log(e.keyCode)
                    });

                    angular.element($window).bind('mousedown', function (e) {
                        scope.removeSuggestions();
                    });

                    angular.element(elem[0].getElementsByClassName('ee-suggestions')).bind('mousedown', function (e) {
                        e.stopPropagation();
                        scope.useSuggestion();
                    });

                    elem.bind('mousemove', function (e) {
                        var cursorX = e.clientX;
                        var cursorY = e.clientY;
                        var elementsWithPopover = document.getElementsByClassName('ee-popover-ready');
                        popover.style = {};
                        popover.style.display = 'none';
                        angular.forEach(elementsWithPopover, function (element) {
                            element = angular.element(element)[0];
                            var viewportOffset = element.getBoundingClientRect();
                            var left = viewportOffset.left;
                            var right = viewportOffset.right;
                            var top = viewportOffset.top;
                            var bottom = viewportOffset.bottom;
                            if (cursorX >= left && cursorX <= right && cursorY >= top && cursorY <= bottom) {
                                if (element.attributes.hasOwnProperty('popover-title')) popover.innerHTML = element.attributes['popover-title'].value;
                                if (element.attributes.hasOwnProperty('popover-eval')) popover.innerHTML = scope.$eval(element.attributes['popover-eval'].value);
                                popover.style.display = 'block';
                                if (viewportOffset.left + popover.offsetWidth >= $window.innerWidth - 20) {
                                    popover.style.left = element.offsetLeft + $window.innerWidth - viewportOffset.left - popover.offsetWidth - 20 + 'px';
                                } else {
                                    popover.style.left = element.offsetLeft + 'px';
                                }
                                if (viewportOffset.bottom + popover.offsetHeight >= $window.innerHeight) {
                                    popover.style.top = element.offsetTop - popover.offsetHeight - 5 + 'px';
                                } else {
                                    popover.style.top = element.offsetTop + element.offsetHeight + 5 + 'px';
                                }
                            }
                        });
                    });

                    expressionInput.bind('scroll', function () {
                        expressionVisualization[0].scrollTop = this.scrollTop;
                    });
                });

                function isNewLine(char) {
                    return char ? char == '\n' || char == '\r' : false;
                }

                function insertStringBetween(string, stringToInsert, start, end) {
                    return [string.slice(0, start), stringToInsert, string.slice(end)].join('');
                }

                function getAssociatedLabel() {
                    var associatedLabel;

                    var labels = document.getElementsByTagName('label');
                    var elemId = attrs.id ? attrs.id : null;
                    for (var key in labels) {
                        if (!labels.hasOwnProperty(key)) {
                            continue;
                        }
                        var label = labels[key];
                        var attributes = label.attributes;
                        if (attributes) {
                            var attrFor = attributes.for;
                            if (attrFor && attrFor.value == elemId) {
                                associatedLabel = label;
                                // console.log([associatedLabel])
                                break;
                            }
                        }
                    }
                    angular.element(associatedLabel).bind('click', function () {
                        expressionInput[0].focus();
                    });
                }
            }
        };
    }]);
})(angular);